import { gql } from '@apollo/client';
import { IPaginationVars, ITalentOverviewType } from 'types';

export const qTalentOverviewsByName = gql`
  query talentOverviewByName($limit: Int!, $offset: Int!, $name: String!) {
    talentOverviewByName(limit: $limit, offset: $offset, name: $name) {
      total
      rows {
        uuid
        talent_name
        company {
          company_name
        }
      }
    }
  }
`;

export interface qTalentOverviewsByNameRes {
  talentOverviewByName?: {
    total: number;
    rows: ITalentOverviewType[];
  };
}

export interface qTalentOverviewsByNameVars extends IPaginationVars {
  name: string;
}
