import { gql } from '@apollo/client';
import {
  IProjectTypeType,
  ITalentOverviewFormType,
  ITalentOverviewType,
  IVendorTalentOverviewFormType,
} from 'types';
import * as Yup from 'yup';
import { talentOverviewString } from './getFreelancerTalentOverview.api';

export const mTalentOverview = gql`
  mutation talentOverview(
    $talentInfo: TalentOverviewDto!
    $project_type_ids: [ID!]!
  ) {
    talentOverview(
      talentInfo: $talentInfo
      project_type_ids: $project_type_ids
    ) {
      ${talentOverviewString}
    }
  }
`;

export interface mTalentOverviewRes {
  talentOverview?: ITalentOverviewType;
}

export const mTalentOverviewInitVals: ITalentOverviewFormType = {
  talentInfo: {
    talent_name: '',
    bio: '',
    vendor_budget_range_id: '',
    talent_type_id: [],
    talent_group_ids: [],
    creative_style_ids: [],
    talent_language_ids: [],
    project_type_id: '',
    talent_image: '',
    vendor_company_id: '',
    is_primary: true,
    online_reel_url: '',
  },
  project_type_ids: [],
};

export const mVendorTalentOverviewInitVals: IVendorTalentOverviewFormType = {
  talent_name: '',
  bio: '',
  vendor_budget_range_id: '',
  talent_type_id: '',
  talent_group_ids: [],
  creative_style_ids: [],
  talent_language_ids: [],
  project_type_ids: [],
  talent_image: '',
};

export const mTalentOverviewValidations = (pTypes: IProjectTypeType[]) =>
  Yup.object().shape({
    talentInfo: Yup.object().shape({
      talent_name: Yup.string().required('Talent name is required!'),
      bio: Yup.string().required('Bio is required!'),
      talent_type_id: Yup.array()
        .of(Yup.string())
        .min(1, 'Talent type is required!')
        .max(2, 'You can select up to 2 talent type only!')
        .required('Talent type is required!'),
      project_type_id: Yup.string().required('Project type is required!'),
      vendor_company_id: Yup.string(),
      talent_group_ids: Yup.array().of(Yup.string()),
      creative_style_ids: Yup.array()
        .of(Yup.string())
        .when(['project_type_id'], {
          is: (project_type_id: string) => {
            const usedPtype = pTypes.find((p) => project_type_id === p.uuid);

            if (!usedPtype?.is_more_data) return true;
            return false;
          },
          then: (sch) =>
            sch.when('project_type_id', {
              is: (project_type_id: string) =>
                pTypes.find((p) => project_type_id === p.uuid)?.unique_name ===
                'live_action_production',
              then: (schema) =>
                schema
                  .min(1, 'Creative Styles are required!')
                  .max(20, 'You can select up to 20 creative styles only!')
                  .required('Creative styles are required!'),
              otherwise: (schema) =>
                schema
                  .min(1, 'Creative Styles are required!')
                  .max(5, 'You can select up to 5 creative styles only!')
                  .required('Creative styles are required!'),
            }),
        }),
      vendor_budget_range_id: Yup.string(),
      // vendor_budget_range_id: Yup.string().required(
      //   'Budget range is required!',
      // ),
      talent_language_ids: Yup.array()
        .of(Yup.string())
        .min(1, 'Talent language is required!'),
      online_reel_url: Yup.string().matches(
        /^((http|https):\/\/)(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#-]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?\/?$/gm,
        'Invalid reel url!',
      ),
    }),
    project_type_ids: Yup.array()
      .of(Yup.string())
      .min(1, 'Talent language is required!'),
  });
