import { gql } from '@apollo/client';

export const mResendVendorMangerInvitation = gql`
  mutation resendVendorMangerInvitation($id: ID!) {
    resendVendorMangerInvitation(id: $id)
  }
`;

export interface mResendVendorMangerInvitationRes {
  resendVendorMangerInvitation?: boolean;
}
