import { gql } from '@apollo/client';

export const mCreateSubscription = gql`
  mutation createSubscription($id: ID, $payment_method: String) {
    createSubscription(id: $id, payment_method: $payment_method)
  }
`;

export interface mCreateSubscriptionRes {
  createSubscription?: boolean;
}

export interface mCreateSubscriptionVar {
  id?: string;
  payment_method?: string;
}
