import { gql } from '@apollo/client';
import { INameUuidType } from 'types';

export const qVendorBudgetRanges = gql`
  query vendorBudgetRanges($project_type_id: ID) {
    vendorBudgetRanges(project_type_id: $project_type_id) {
      uuid
      name
    }
  }
`;

export interface qVendorBudgetRangesRes {
  vendorBudgetRanges?: INameUuidType[];
}

export interface qVendorBudgetRangesVar {
  project_type_id?: string;
}
