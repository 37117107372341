import { gql } from '@apollo/client';

export const mRequestNdaByClient = gql`
  mutation requestNdaByClient($requestNdaInput: RequestNdaByClientDto!) {
    requestNdaByClient(requestNdaInput: $requestNdaInput)
  }
`;

export interface mRequestNdaByClientRes {
  requestNdaByClient?: boolean;
}

export interface mRequestNdaByClientFormVars {
  nda_id: string;
  vendor_company_ids: string[];
  message: string;
}

export interface mRequestNdaByClientVars {
  requestNdaInput: mRequestNdaByClientFormVars;
}
