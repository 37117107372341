import { gql } from '@apollo/client';

export const mActiveDeactiveUser = gql`
  mutation activeDeactiveUser($id: ID!, $status: Boolean!) {
    activeDeactiveUser(id: $id, status: $status)
  }
`;

export interface mActiveDeactiveUserVars {
  id: string;
  status: boolean;
}

export interface mActiveDeactiveUserRes {
  activeDeactiveUser?: boolean;
}
