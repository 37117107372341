import { gql } from '@apollo/client';

export const qAddBillingCard = gql`
  mutation addBillingCard($payment_method: String!, $is_default: Boolean) {
    addBillingCard(payment_method: $payment_method, is_default: $is_default)
  }
`;

export interface qAddBillingCardRes {
  addBillingCard?: boolean;
}

export interface qAddBillingCardVars {
  payment_method: string;
  is_default?: boolean;
}
