import { gql } from '@apollo/client';
import * as Yup from 'yup';
import {
  IEnterpriseClientRequestStatusTypeEnum,
  IUpdateEnterpriseClientRequestFormType,
} from 'types';

export const mUpgradeNonEnterpriseToEnterprise = gql`
  mutation upgradeNonEnterpriseToEnterprise(
    $upgradeClientRequest: AdminUpgradeEnterpriseClientRequestDto!
  ) {
    upgradeNonEnterpriseToEnterprise(
      upgradeClientRequest: $upgradeClientRequest
    )
  }
`;

export interface mUpgradeNonEnterpriseToEnterpriseVars {
  upgradeClientRequest: IUpdateEnterpriseClientRequestFormType;
}

export interface mUpgradeNonEnterpriseToEnterpriseRes {
  upgradeNonEnterpriseToEnterprise?: boolean;
}

export const mUpgradeNonEnterpriseToEnterpriseInitVals: IUpdateEnterpriseClientRequestFormType =
  {
    request_id: '',
    request_status: '',
    add_seats: '',
    base_seats: '',
    admin_remarks: '',
  };

export const mUpgradeNonEnterpriseToEnterpriseValidations = Yup.object().shape({
  request_id: Yup.string(),
  request_status: Yup.string()
    .oneOf([
      IEnterpriseClientRequestStatusTypeEnum.Approve,
      IEnterpriseClientRequestStatusTypeEnum.Reject,
    ])
    .required('Status is required!'),
  add_seats: Yup.number().when('request_status', {
    is: IEnterpriseClientRequestStatusTypeEnum.Approve,
    then: Yup.number().required('Additional Seats are required!'),
  }),
  base_seats: Yup.number().when('request_status', {
    is: IEnterpriseClientRequestStatusTypeEnum.Approve,
    then: Yup.number().required('Base Seats are required!'),
  }),
  base_monthly: Yup.number().when('request_status', {
    is: IEnterpriseClientRequestStatusTypeEnum.Approve,
    then: Yup.number().required('Base monthly is required!'),
  }),
  add_monthly: Yup.number().when('request_status', {
    is: IEnterpriseClientRequestStatusTypeEnum.Approve,
    then: Yup.number().required('Add monthly is required!'),
  }),
  base_annual: Yup.number().when('request_status', {
    is: IEnterpriseClientRequestStatusTypeEnum.Approve,
    then: Yup.number().required('Base annual is required!'),
  }),
  start_date: Yup.date().when('request_status', {
    is: IEnterpriseClientRequestStatusTypeEnum.Approve,
    then: Yup.date().required('Start date is required!'),
  }),
  renewal: Yup.date().when('request_status', {
    is: IEnterpriseClientRequestStatusTypeEnum.Approve,
    then: Yup.date().required('Renewal date is required!'),
  }),
  admin_remarks: Yup.string(),
});
