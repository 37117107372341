import { gql } from '@apollo/client';

export const mRemoveStyleServiceProfile = gql`
  mutation removeStyleServiceProfile($id: ID!) {
    removeStyleServiceProfile(id: $id)
  }
`;

export interface mRemoveStyleServiceProfileRes {
  removeStyleServiceProfile?: boolean;
}

export interface mRemoveStyleServiceProfileVars {
  id: string;
}
