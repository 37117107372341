import { gql } from '@apollo/client';
import { IClientCompanyType } from 'types';

export const qGetAdminClientById = gql`
  query getAdminClientById($id: ID!) {
    getAdminClientById(id: $id) {
      uuid
      company_name
      company_type
      is_blocked
      is_registered
      created_at
      city
      zip
      mailing_address1
      mailing_address2
      author {
        uuid
        first_name
        last_name
        email
        phone_number
      }
      members {
        uuid
        first_name
        last_name
        name
        email
        role
        phone_number
      }
      billing {
        uuid
        seats
        renewal
        autorenew
        status
        base_seats
        base_monthly
        add_seats
        add_monthly
        base_annual
        start_date
        is_auto_pay
      }
      state {
        uuid
        name
      }
      projectCount
      anonymousCount
      ndaCount
    }
  }
`;

export interface qGetAdminClientByIdRes {
  getAdminClientById?: IClientCompanyType;
}
