import { gql } from '@apollo/client';

export const mDeleteFavoriteVendorListItem = gql`
  mutation deleteFavoriteVendorListItem($id: ID!, $list_id: ID!) {
    deleteFavoriteVendorListItem(id: $id, list_id: $list_id)
  }
`;

export interface mDeleteFavoriteVendorListItemRes {
  deleteFavoriteVendorListItem?: boolean;
}

export interface mDeleteFavoriteVendorListItemVars {
  id: string;
  list_id: string;
}
