import { gql } from '@apollo/client';
import * as Yup from 'yup';
import {
  IEnterpriseClientRequestStatusTypeEnum,
  IDowngradeEnterpriseClientRequestFormType,
  IClientCompanyTypeEnum,
} from 'types';

export const mAdminDowngradeEnterpriseClientRequest = gql`
  mutation adminDowngradeEnterpriseAccount(
    $downgradeClientRequest: AdminDowngradeEnterpriseClientRequestDto!
  ) {
    adminDowngradeEnterpriseAccount(
      downgradeClientRequest: $downgradeClientRequest
    )
  }
`;

export interface mAdminDowngradeEnterpriseClientRequestVars {
  downgradeClientRequest: IDowngradeEnterpriseClientRequestFormType;
}

export interface mAdminDowngradeEnterpriseClientRequestRes {
  adminDowngradeEnterpriseAccount?: boolean;
}

export const mAdminDowngradeEnterpriseClientRequestInitVals: IDowngradeEnterpriseClientRequestFormType =
  {
    request_id: '',
    request_status: '',
    downgrade_company_type: '',
    admin_remarks: '',
  };

export const mAdminDowngradeEnterpriseClientRequestValidations =
  Yup.object().shape({
    request_id: Yup.string(),
    request_status: Yup.string()
      .oneOf([
        IEnterpriseClientRequestStatusTypeEnum.Approve,
        IEnterpriseClientRequestStatusTypeEnum.Reject,
      ])
      .required('Status is required!'),
    admin_remarks: Yup.string(),
    downgrade_company_type: Yup.string().when('request_status', {
      is: IEnterpriseClientRequestStatusTypeEnum.Approve,
      then: Yup.string()
        .oneOf([IClientCompanyTypeEnum.PAYASGO, IClientCompanyTypeEnum.ANNUAL])
        .required('Company type is required!'),
    }),
  });
