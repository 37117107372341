import { gql } from '@apollo/client';

export const qClientProjectUsersByProjectId = gql`
  query clientProjectUsersByProjectId($id: ID!) {
    clientProjectUsersByProjectId(id: $id)
  }
`;

export interface qClientProjectUsersByProjectIdRes {
  clientProjectUsersByProjectId?: string[];
}
