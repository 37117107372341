import { gql } from '@apollo/client';

export const mCancelRelationWithFreelancerByVendor = gql`
  mutation cancelRelationWithFreelancerByVendor(
    $isInvited: Boolean!
    $relation_id: ID!
  ) {
    cancelRelationWithFreelancerByVendor(
      isInvited: $isInvited
      relation_id: $relation_id
    )
  }
`;

export interface mCancelRelationWithFreelancerByVendorRes {
  cancelRelationWithFreelancerByVendor?: boolean;
}

export interface mCancelRelationWithFreelancerByVendorVars {
  isInvited: boolean;
  relation_id: string;
}
