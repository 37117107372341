import { gql } from '@apollo/client';
import * as Yup from 'yup';
import { projectErrors } from 'errors';
import {
  IUploadFileType,
  IUpdateProjectFormType,
  IProjectTypeType,
} from 'types';

export const mUpdateProject = gql`
  mutation updateProject($projectInput: UpdateProjectInput!) {
    updateProject(projectInput: $projectInput)
  }
`;

export interface mUpdateProjectRes {
  updateProject?: boolean;
}

export interface mUpdateProjectFormVar {
  project_object: IUpdateProjectFormType;
  upload_file_object_script?: IUploadFileType;
  project_id: string;
  is_default: boolean;
  payment_method?: string;
  payment_intent?: string;
  existing_card?: string;
}

export interface mUpdateProjectVar {
  projectInput: mUpdateProjectFormVar;
}

export const mUpdateProjectInitVals: mUpdateProjectFormVar = {
  project_object: {
    project_type: '',
    client_budget_range_id: '',
    talent_type: '',
    languages: [],
    talent_group: [],
    creative_styles: [],
    agency_state_id: '',
    is_include_insurance: false,
    is_include_post: false,
    is_union_talent: false,
    is_include_audio: false,
    is_include_color: false,
    is_include_logo: false,
    is_shoot_firm: false,
    is_location_firm: false,
    location: '',
    budget: '',
    agency_name: '',
    brand_name: '',
    shoot_date: '',
    end_shoot_date: '',
    shoot_day: '',
    spots_length: '',
    due_date: '',
    description: '',
    creative_url: '',
    vs_is_all: false,
    vs_is_minority: false,
    vs_is_female: false,
    vs_is_east_coast: false,
    vs_is_west_coast: false,
    vs_is_veteran: false,
    vs_is_midwest: false,
    vs_is_freelance_talent: false,
    vs_is_vendor_rep: false,
    vs_is_sales_rep: false,
    vs_states: [],
    vs_favorite_vendor_lists: [],
  },
  upload_file_object_script: { name: '', url: '' },
  project_id: '',
  is_default: false,
  payment_intent: '',
  payment_method: '',
  existing_card: '',
};

export const mUpdateProjectInitTouched = {
  project_object: {
    vs_is_all: true,
    vs_is_minority: true,
    vs_is_female: true,
    vs_is_east_coast: true,
    vs_is_west_coast: true,
    vs_is_veteran: true,
    vs_is_midwest: true,
    vs_is_freelance_talent: true,
    vs_is_vendor_rep: true,
    vs_is_sales_rep: true,
    vs_states: true,
    vs_favorite_vendor_lists: true,
  },
};

export const mUpdateProjectValidations = (pTypes: IProjectTypeType[]) =>
  Yup.object().shape({
    upload_file_object_script: Yup.object().shape({
      name: Yup.string(),
      url: Yup.string(),
    }),
    project_object: Yup.object().shape({
      location: Yup.string().when(['project_type'], {
        is: (project_type: string) => {
          const usedPtype = pTypes.filter((p) => project_type === p.uuid);

          if (
            usedPtype.find(
              (p) =>
                (!p.is_more_data && !p.is_project_changes) ||
                (!p.is_more_data && p.is_project_changes),
            )
          )
            return true;
          return false;
        },
        then: (sch) => sch.required(projectErrors.LOCATION_REQUIRED),
      }),
      is_location_firm: Yup.boolean().when(['project_type'], {
        is: (project_type: string) => {
          const usedPtype = pTypes.filter((p) => project_type === p.uuid);

          if (usedPtype.find((p) => !p.is_more_data && !p.is_project_changes))
            return true;
          return false;
        },
        then: (sch) => sch.required('Firm Location is required!'),
      }),
      agency_name: Yup.string().required(projectErrors.AGENCY_NAME_REQUIRED),
      agency_state_id: Yup.string().required(
        projectErrors.AGENCY_LOCATION_REQUIRED,
      ),
      brand_name: Yup.string().required(projectErrors.BRAND_NAME_REQUIRED),
      shoot_date: Yup.string().required(projectErrors.SHOOT_DATE_REQUIRED),
      end_shoot_date: Yup.string().required(
        projectErrors.SHOOT_END_DATE_REQUIRED,
      ),
      is_shoot_firm: Yup.boolean().when(['project_type'], {
        is: (project_type: string) => {
          const usedPtype = pTypes.filter((p) => project_type === p.uuid);

          if (usedPtype.find((p) => !p.is_more_data && !p.is_project_changes))
            return true;
          return false;
        },
        then: (sch) => sch.required('Firm shoot is required!'),
      }),
      shoot_day: Yup.string().when(['project_type'], {
        is: (project_type: string) => {
          const usedPtype = pTypes.filter((p) => project_type === p.uuid);

          if (usedPtype.find((p) => !p.is_more_data && !p.is_project_changes))
            return true;
          return false;
        },
        then: (sch) => sch.required(projectErrors.SHOOT_DAYS_REQUIRED),
      }),
      client_budget_range_id: Yup.string(),
      is_include_post: Yup.boolean().when(['project_type'], {
        is: (project_type: string) => {
          const usedPtype = pTypes.filter((p) => project_type === p.uuid);

          if (usedPtype.find((p) => !p.is_more_data && !p.is_project_changes))
            return true;
          return false;
        },
        then: (sch) => sch.required('Include post is required!'),
      }),
      is_union_talent: Yup.boolean().when(['project_type'], {
        is: (project_type: string) => {
          const usedPtype = pTypes.filter((p) => project_type === p.uuid);

          if (usedPtype.find((p) => !p.is_more_data && !p.is_project_changes))
            return true;
          return false;
        },
        then: (sch) => sch.required('Include non union is required!'),
      }),
      is_include_insurance: Yup.boolean().when(['project_type'], {
        is: (project_type: string) => {
          const usedPtype = pTypes.filter((p) => project_type === p.uuid);

          if (usedPtype.find((p) => !p.is_more_data && !p.is_project_changes))
            return true;
          return false;
        },
        then: (sch) => sch.required('Include insurance is required!'),
      }),
      is_include_audio: Yup.boolean().when(['project_type'], {
        is: (project_type: string) => {
          const usedPtype = pTypes.filter((p) => project_type === p.uuid);

          if (usedPtype.find((p) => !p.is_more_data && p.is_project_changes))
            return true;
          return false;
        },
        then: (sch) => sch.required('Include audio is required!'),
      }),
      is_include_color: Yup.boolean().when(['project_type'], {
        is: (project_type: string) => {
          const usedPtype = pTypes.filter((p) => project_type === p.uuid);

          if (usedPtype.find((p) => !p.is_more_data && p.is_project_changes))
            return true;
          return false;
        },
        then: (sch) => sch.required('Include color is required!'),
      }),
      is_include_logo: Yup.boolean().when(['project_type'], {
        is: (project_type: string) => {
          const usedPtype = pTypes.filter((p) => project_type === p.uuid);

          if (usedPtype.find((p) => !p.is_more_data && p.is_project_changes))
            return true;
          return false;
        },
        then: (sch) => sch.required('Include logo is required!'),
      }),
      creative_url: Yup.string().url(projectErrors.CREATIVE_URL_INVALID),
      description: Yup.string().required(projectErrors.DESCRIPTION_REQUIRED),
      spots_length: Yup.string().required(projectErrors.SPOTS_LENGTH_REQUIRED),
      due_date: Yup.string().required(projectErrors.DUE_DATE_REQUIRED),
      project_type: Yup.string().required(projectErrors.PROJECT_TYPE_REQUIRED),
      talent_type: Yup.string().required(projectErrors.TALENT_TYPE_REQUIRED),
      languages: Yup.array()
        .of(Yup.string().required(projectErrors.LANGUAGE_REQUIRED))
        .required(projectErrors.LANGUAGE_REQUIRED),
      creative_styles: Yup.array()
        .of(Yup.string())
        .when(['project_type'], {
          is: (project_type: string) => {
            const usedPtype = pTypes.filter((p) => project_type === p.uuid);

            if (
              usedPtype.find(
                (p) =>
                  (!p.is_more_data && p.is_project_changes) ||
                  (!p.is_more_data && !p.is_project_changes),
              )
            )
              return true;
            return false;
          },
          then: (sch) =>
            sch
              .min(1, projectErrors.CREATIVE_STYLES_MIN_LENGTH)
              .max(5, projectErrors.CREATIVE_STYLES_MAX_LENGTH)
              .required(projectErrors.CREATIVE_STYLES_REQUIRED),
        }),
      talent_group: Yup.array()
        .of(Yup.string().required(projectErrors.TALENT_GROUP_REQUIRED))
        .required(projectErrors.TALENT_GROUP_REQUIRED),

      vs_is_all: Yup.bool(),
      vs_is_minority: Yup.bool(),
      vs_is_female: Yup.bool(),
      vs_is_east_coast: Yup.bool(),
      vs_is_west_coast: Yup.bool(),
      vs_is_veteran: Yup.bool(),
      vs_is_midwest: Yup.bool(),
      vs_is_freelance_talent: Yup.bool(),
      vs_is_vendor_rep: Yup.bool(),
      vs_is_sales_rep: Yup.bool(),
      vs_states: Yup.array().of(Yup.string()),
      vs_favorite_vendor_lists: Yup.array().of(Yup.string()),
    }),
    is_default: Yup.boolean(),
    project_id: Yup.string().required(),
    payment_intent: Yup.string(),
    payment_method: Yup.string(),
    existing_card: Yup.string(),
  });

export const mUpdateProjectFirstStepValidations = (
  pTypes: IProjectTypeType[],
) =>
  Yup.object().shape({
    upload_file_object_script: Yup.object().shape({
      name: Yup.string(),
      url: Yup.string(),
    }),
    project_object: Yup.object().shape({
      location: Yup.string().when(['project_type'], {
        is: (project_type: string) => {
          const usedPtype = pTypes.filter((p) => project_type === p.uuid);

          if (
            usedPtype.find(
              (p) =>
                (!p.is_more_data && !p.is_project_changes) ||
                (!p.is_more_data && p.is_project_changes),
            )
          )
            return true;
          return false;
        },
        then: (sch) => sch.required(projectErrors.LOCATION_REQUIRED),
      }),
      is_location_firm: Yup.boolean().when(['project_type'], {
        is: (project_type: string) => {
          const usedPtype = pTypes.filter((p) => project_type === p.uuid);

          if (usedPtype.find((p) => !p.is_more_data && !p.is_project_changes))
            return true;
          return false;
        },
        then: (sch) => sch.required('Firm Location is required!'),
      }),
      agency_state_id: Yup.string().required(
        projectErrors.AGENCY_LOCATION_REQUIRED,
      ),
      shoot_date: Yup.string().required(projectErrors.SHOOT_DATE_REQUIRED),
      end_shoot_date: Yup.string().required(
        projectErrors.SHOOT_END_DATE_REQUIRED,
      ),
      is_shoot_firm: Yup.boolean().when(['project_type'], {
        is: (project_type: string) => {
          const usedPtype = pTypes.filter((p) => project_type === p.uuid);

          if (usedPtype.find((p) => !p.is_more_data && !p.is_project_changes))
            return true;
          return false;
        },
        then: (sch) => sch.required('Firm shoot is required!'),
      }),
      shoot_day: Yup.string().when(['project_type'], {
        is: (project_type: string) => {
          const usedPtype = pTypes.filter((p) => project_type === p.uuid);

          if (usedPtype.find((p) => !p.is_more_data && !p.is_project_changes))
            return true;
          return false;
        },
        then: (sch) => sch.required(projectErrors.SHOOT_DAYS_REQUIRED),
      }),
      client_budget_range_id: Yup.string(),
      is_include_post: Yup.boolean().when(['project_type'], {
        is: (project_type: string) => {
          const usedPtype = pTypes.filter((p) => project_type === p.uuid);

          if (usedPtype.find((p) => !p.is_more_data && !p.is_project_changes))
            return true;
          return false;
        },
        then: (sch) => sch.required('Include post is required!'),
      }),
      is_union_talent: Yup.boolean().when(['project_type'], {
        is: (project_type: string) => {
          const usedPtype = pTypes.filter((p) => project_type === p.uuid);

          if (usedPtype.find((p) => !p.is_more_data && !p.is_project_changes))
            return true;
          return false;
        },
        then: (sch) => sch.required('Include non union is required!'),
      }),
      is_include_insurance: Yup.boolean().when(['project_type'], {
        is: (project_type: string) => {
          const usedPtype = pTypes.filter((p) => project_type === p.uuid);

          if (usedPtype.find((p) => !p.is_more_data && !p.is_project_changes))
            return true;
          return false;
        },
        then: (sch) => sch.required('Include insurance is required!'),
      }),
      is_include_audio: Yup.boolean().when(['project_type'], {
        is: (project_type: string) => {
          const usedPtype = pTypes.filter((p) => project_type === p.uuid);

          if (usedPtype.find((p) => !p.is_more_data && p.is_project_changes))
            return true;
          return false;
        },
        then: (sch) => sch.required('Include audio is required!'),
      }),
      is_include_color: Yup.boolean().when(['project_type'], {
        is: (project_type: string) => {
          const usedPtype = pTypes.filter((p) => project_type === p.uuid);

          if (usedPtype.find((p) => !p.is_more_data && p.is_project_changes))
            return true;
          return false;
        },
        then: (sch) => sch.required('Include color is required!'),
      }),
      is_include_logo: Yup.boolean().when(['project_type'], {
        is: (project_type: string) => {
          const usedPtype = pTypes.filter((p) => project_type === p.uuid);

          if (usedPtype.find((p) => !p.is_more_data && p.is_project_changes))
            return true;
          return false;
        },
        then: (sch) => sch.required('Include logo is required!'),
      }),
      creative_url: Yup.string().url(projectErrors.CREATIVE_URL_INVALID),
      description: Yup.string().required(projectErrors.DESCRIPTION_REQUIRED),
      spots_length: Yup.string().required(projectErrors.SPOTS_LENGTH_REQUIRED),
      due_date: Yup.string().required(projectErrors.DUE_DATE_REQUIRED),
      project_type: Yup.string().required(projectErrors.PROJECT_TYPE_REQUIRED),
      talent_type: Yup.string().required(projectErrors.TALENT_TYPE_REQUIRED),
      languages: Yup.array()
        .of(Yup.string().required(projectErrors.LANGUAGE_REQUIRED))
        .required(projectErrors.LANGUAGE_REQUIRED),
      creative_styles: Yup.array()
        .of(Yup.string())
        .when(['project_type'], {
          is: (project_type: string) => {
            const usedPtype = pTypes.filter((p) => project_type === p.uuid);

            if (
              usedPtype.find(
                (p) =>
                  (!p.is_more_data && p.is_project_changes) ||
                  (!p.is_more_data && !p.is_project_changes),
              )
            )
              return true;
            return false;
          },
          then: (sch) =>
            sch
              .min(1, projectErrors.CREATIVE_STYLES_MIN_LENGTH)
              .max(5, projectErrors.CREATIVE_STYLES_MAX_LENGTH)
              .required(projectErrors.CREATIVE_STYLES_REQUIRED),
        }),
      talent_group: Yup.array()
        .of(Yup.string().required(projectErrors.TALENT_GROUP_REQUIRED))
        .required(projectErrors.TALENT_GROUP_REQUIRED),
    }),
  });

export const mUpdateProjectSecondStepValidations = Yup.object().shape({
  project_object: Yup.object().shape({
    vs_is_all: Yup.bool(),
    vs_is_minority: Yup.bool(),
    vs_is_female: Yup.bool(),
    vs_is_east_coast: Yup.bool(),
    vs_is_west_coast: Yup.bool(),
    vs_is_veteran: Yup.bool(),
    vs_is_midwest: Yup.bool(),
    vs_is_freelance_talent: Yup.bool(),
    vs_is_vendor_rep: Yup.bool(),
    vs_is_sales_rep: Yup.bool(),
    vs_states: Yup.array().of(Yup.string()),
    vs_favorite_vendor_lists: Yup.array().of(Yup.string()),
  }),
  is_default: Yup.boolean(),
  project_id: Yup.string().required(),
  payment_intent: Yup.string(),
  payment_method: Yup.string(),
  existing_card: Yup.string(),
});
