import { gql } from '@apollo/client';
import { IClientCompanyInviteType, IUserType } from 'types';

export const qGetEnterpriseManagerAndSeats = gql`
  {
    getEnterpriseManagerAndSeats {
      seats
      managers {
        uuid
        name
        username
        email
        role
      }
      invitees {
        uuid
        name
        email
      }
    }
  }
`;

export interface qGetEnterpriseManagerAndSeatsRes {
  getEnterpriseManagerAndSeats?: {
    seats: number;
    managers: IUserType[];
    invitees: IClientCompanyInviteType[];
  };
}
