import { gql } from '@apollo/client';
import {
  IVendorRelationshipChatType,
  IVendorSalesRepRelationType,
} from 'types';

export const qFreelanceRelationForSalesrep = gql`
  {
    freelanceRelationForSalesrep {
      relations {
        uuid
        is_send_reel
        is_reply_message
        relationProfileIds
        relationFreelanceProfiles {
          vendorProfile {
            uuid
            projectType {
              uuid
            }
          }
          state {
            uuid
            name
          }
        }
        salesrepCompany {
          company_name
        }
        vendorCompany {
          company_name
          talentOverviews {
            profiles {
              uuid
              projectType {
                uuid
                vendor_label
                freelance_label
              }
            }
          }
          primaryProfile {
            uuid
            projectType {
              uuid
              vendor_label
            }
          }
          profiles {
            uuid
            projectType {
              uuid
              vendor_label
            }
          }
        }
      }
      invitees {
        uuid
        is_send_reel
        is_reply_message
        invitee_vendor_company_profile_ids
        invitedProfiles {
          state {
            uuid
            name
          }
          vendorCompanyProfile {
            uuid
            projectType {
              uuid
            }
          }
          freelanceProfile {
            uuid
            projectType {
              uuid
            }
          }
        }
        inviteeCompany {
          company_name
          talentOverviews {
            profiles {
              uuid
              projectType {
                uuid
                vendor_label
                freelance_label
              }
            }
          }
          primaryProfile {
            uuid
            projectType {
              uuid
              vendor_label
            }
          }
          profiles {
            uuid
            projectType {
              uuid
              vendor_label
            }
          }
        }
      }
    }
  }
`;

export interface qFreelanceRelationForSalesrepRes {
  freelanceRelationForSalesrep?: {
    relations: IVendorSalesRepRelationType[];
    invitees: IVendorRelationshipChatType[];
  };
}
