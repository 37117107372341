import { gql } from '@apollo/client';

export const qGetFile = gql`
  query getFile($id: String!) {
    getFile(id: $id)
  }
`;

export interface qGetFileRes {
  getFile?: string;
}
