import { gql } from '@apollo/client';
import { IS3FileType } from 'types';

export const mGetPresignedUrl = gql`
  mutation getPresignedURL($getPresignedURLInput: GetPresignedURLInput!) {
    getPresignedURL(getPresignedURLInput: $getPresignedURLInput)
  }
`;

export interface mGetPresignedUrlRes {
  getPresignedURL?: string;
}

export interface mGetPresignedUrlVar {
  getPresignedURLInput: {
    name: string;
    size: number;
    mimetype: string;
    fileTypes: IS3FileType[];
  };
}
