import { gql } from '@apollo/client';

export const mPayInvoice = gql`
  mutation payInvoice($invoice_id: ID!, $id: ID, $payment_method: String) {
    payInvoice(
      invoice_id: $invoice_id
      id: $id
      payment_method: $payment_method
    )
  }
`;

export interface mPayInvoiceRes {
  payInvoice?: boolean;
}

export interface mPayInvoiceVar {
  invoice_id: string;
  id?: string;
  payment_method?: string;
}
