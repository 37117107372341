import { gql } from '@apollo/client';

export const mGetClientApproveNdaByNda = gql`
  mutation getClientApproveNdaByNda($id: ID!) {
    getClientApproveNdaByNda(id: $id)
  }
`;

export interface mGetClientApproveNdaByNdaRes {
  getClientApproveNdaByNda?: boolean;
}
