import { gql } from '@apollo/client';
import { ISettingDataType } from 'types';

export const qCreativeStylesForAdmin = gql`
  {
    creativeStylesForAdmin {
      uuid
      name
      unique_name
      short_description
      long_description
      sort_order
      is_active
    }
  }
`;

export interface qCreativeStylesForAdminRes {
  creativeStylesForAdmin?: ISettingDataType[];
}
