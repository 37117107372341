import { gql } from '@apollo/client';
import * as Yup from 'yup';

export const mClientInviteManager = gql`
  mutation clientInviteManager($inviteInput: ClientCompanyInviteDto!) {
    clientInviteManager(inviteInput: $inviteInput)
  }
`;

export interface mClientInviteManagerRes {
  clientInviteManager?: boolean;
}

export interface mClientInviteManagerFormType {
  first_name: string;
  last_name: string;
  email: string;
}

export interface mClientInviteManagerVar {
  inviteInput: mClientInviteManagerFormType;
}

export const mClientInviteManagerInitVals: mClientInviteManagerFormType = {
  first_name: '',
  last_name: '',
  email: '',
};

export const mClientInviteManagerValidations = Yup.object().shape({
  first_name: Yup.string().required('First name is required!'),
  last_name: Yup.string().required('Last name is required!'),
  email: Yup.string()
    .trim()
    .lowercase()
    .email('Invalid email!')
    .required('Email is required!'),
});
