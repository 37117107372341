import { createContext, ReactNode, useEffect, useRef, useState } from 'react';

type ThemeType = 'dark' | 'light';
const getInitialTheme = (): boolean => {
  if (typeof window !== 'undefined' && window.localStorage) {
    // first preference to user stored preference
    const storedPrefs = window.localStorage.getItem('theme') as ThemeType;
    if (storedPrefs) {
      return storedPrefs === 'dark';
    }

    // second preference to user system color preference
    const userMedia = window.matchMedia('(prefers-color-scheme: dark)');
    if (userMedia.matches) return false;

    return false;
  }
  // final default as light
  return false;
};
interface ThemeContextType {
  isDarkMode: boolean;
  setIsDarkMode: (isDarkMode: boolean) => void;
  showPendingPaymentModal: boolean;
  setShowPendingPaymentModal: (showPendingPaymentModal: boolean) => void;
  pendingPaymentTimer?: React.MutableRefObject<NodeJS.Timeout | undefined>;
}
const defaultThemeContextValue: ThemeContextType = {
  isDarkMode: false,
  setIsDarkMode: () => {},
  showPendingPaymentModal: false,
  setShowPendingPaymentModal: () => {},
};
export const ConfigContext = createContext<ThemeContextType>(
  defaultThemeContextValue,
);
interface Props {
  children: ReactNode;
}
export function ThemeProvider({ children }: Props) {
  const [isDarkMode, setIsDarkMode] = useState<boolean>(getInitialTheme);
  const [showPendingPaymentModal, setShowPendingPaymentModal] = useState(false);
  const pendingPaymentTimer = useRef<NodeJS.Timeout>();
  useEffect(() => {
    const root = window.document.documentElement;
    root.classList.remove(isDarkMode ? 'light' : 'dark');
    const theme = isDarkMode ? 'dark' : 'light';

    root.classList.add(theme);
    localStorage.setItem('theme', theme);
  }, [isDarkMode]);
  return (
    <ConfigContext.Provider
      value={{
        isDarkMode,
        setIsDarkMode,
        showPendingPaymentModal,
        setShowPendingPaymentModal,
        pendingPaymentTimer,
      }}
    >
      {children}
    </ConfigContext.Provider>
  );
}
