import { gql } from '@apollo/client';

export const mCreateCreativeStyle = gql`
  mutation creativeStyle($creativeStyleInput: CreativeStyleInput!) {
    creativeStyle(creativeStyleInput: $creativeStyleInput)
  }
`;

export interface mCreateCreativeStyleRes {
  creativeStyle?: boolean;
}

export interface mCreateCreativeStyleFormType {
  name: string;
  short_description?: string;
  long_description?: string;
  sort_order: number;
}

export interface mCreateCreativeStyleVar {
  creativeStyleInput: mCreateCreativeStyleFormType;
}
