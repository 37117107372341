import { gql } from '@apollo/client';
import { passwordErrors } from 'errors';
import * as Yup from 'yup';

export const mResetPassword = gql`
  mutation resetPassword($token: String!, $password: String!) {
    resetPassword(token: $token, password: $password)
  }
`;

export interface mResetPasswordRes {
  resetPassword?: boolean;
}

export interface mResetPasswordVar {
  token: string;
  password: string;
}

export interface mResetPasswordForm {
  password: string;
  rpassword: string;
}

export const mResetPasswordValidations = Yup.object().shape({
  password: Yup.string()
    .matches(
      /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/,
      passwordErrors.PASSWORD_VALIDATION,
    )
    .required(passwordErrors.PASSWORD_REQUIRED),
  rpassword: Yup.string()
    .oneOf([Yup.ref('password')], 'Passwords do not match!')
    .required('Repeat password is required!'),
});
