import { gql } from '@apollo/client';
import { IVendorRelationshipChatType } from 'types';

export const qVendorRelatioinChatById = gql`
  query vendorRelatioinChatById($id: ID!) {
    vendorRelatioinChatById(id: $id) {
      uuid
      invitee_vendor_company_profile_ids
    }
  }
`;

export interface qVendorRelatioinChatByIdRes {
  vendorRelatioinChatById?: IVendorRelationshipChatType;
}
