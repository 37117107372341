import { gql } from '@apollo/client';

export const mCreateSubmissionVendor = gql`
  mutation createSubmissionVendor($submissionDto: SubmissionDto!) {
    createSubmissionVendor(submissionDto: $submissionDto)
  }
`;

export interface mCreateSubmissionVendorRes {
  createSubmissionVendor?: boolean;
}

export interface mCreateSubmissionVendorTalentType {
  id?: string;
  uuid: string;
  note: string;
  reel_password: string;
  reel_link: string;
  is_talent?: boolean;
}

export interface mCreateSubmissionVendorFormVars {
  talents: mCreateSubmissionVendorTalentType[];
}

export interface mCreateSubmissionVendorVars {
  submissionDto: {
    project_id: string;
    talents: mCreateSubmissionVendorTalentType[];
  };
}
