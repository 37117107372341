import { IClientCompanyType } from './ClientCompany.type';
import { IUserType } from './User.type';

export interface IEnterpriseClientRequestType {
  uuid: string;
  name: string;
  email: string;
  company_name: string;
  phone_number: string;
  user?: IUserType;
  company?: IClientCompanyType;
  seats?: number;
  base_seats?: number;
  add_seats?: number;
  base_monthly?: number;
  add_monthly?: number;
  base_annual?: number;
  start_date?: Date;
  renewal?: Date;
  user_note: string;
  admin_remarks: string;
  request_status: IEnterpriseClientRequestStatusTypeEnum;
  request_type: IEnterpriseClientRequestStatusRequestTypeEnum;
  created_at: Date;
  updated_at: Date;
}

export enum IEnterpriseClientRequestStatusTypeEnum {
  Approve = 'Approve',
  Reject = 'Reject',
  Close = 'Close',
  Pending = 'Pending',
}

export enum IEnterpriseClientRequestStatusRequestTypeEnum {
  UPGRADE_ENTERPRISE = 'Upgrade Enterprise',
  DOWNGRADE_ENTERPRISE = 'Downgrade Enterprise',
  UPGRADE_SEATS = 'Upgrade Seats',
}
export interface IUpdateEnterpriseClientRequestFormType {
  request_id: string;
  request_status: IEnterpriseClientRequestStatusTypeEnum | string;
  base_seats?: number | string;
  base_monthly?: number | string;
  add_seats?: number | string;
  add_monthly?: number | string;
  base_annual?: number | string;
  start_date?: string;
  renewal?: string;
  admin_remarks?: string;
}

export interface IUpdateEnterpriseClientWithoutRequestFormType {
  client_company_id: string;
  base_seats: number | string;
  base_monthly: number | string;
  base_annual: number | string;
  start_date?: string;
  renewal: string;
}

export interface IDowngradeFromEnterpriseWithoutRequestFormType {
  client_company_id: string;
  downgrade_company_type?: IClientCompanyType | string;
}

export interface IAddMoreSeatsEnterpriseClientRequestFormType {
  request_id: string;
  request_status: IEnterpriseClientRequestStatusTypeEnum | string;
  base_seats?: number | string;
  base_monthly?: number | string;
  add_seats?: number | string;
  add_monthly?: number | string;
  base_annual?: number | string;
  admin_remarks?: string;
}

export interface IDowngradeEnterpriseClientRequestFormType {
  request_id: string;
  request_status: IEnterpriseClientRequestStatusTypeEnum | string;
  admin_remarks?: string;
  downgrade_company_type?: IClientCompanyType | string;
}

export interface IUpdateEnterpriseSeatsFormType {
  client_company_id: string;
  seats: number | string;
}
