import { gql } from '@apollo/client';
import { IUserType } from 'types';

export const qGetVendorMembers = gql`
  query getVendorMembers($pid: ID!) {
    getVendorMembers(pid: $pid) {
      uuid
      name
      vendorCompany {
        uuid
        company_name
      }
    }
  }
`;

export interface qGetVendorMembersRes {
  getVendorMembers?: IUserType[];
}
