import { gql } from '@apollo/client';

export const mAddFavoriteVendorListItem = gql`
  mutation addFavoriteVendorListItem($id: ID!, $vendor_company_id: ID!) {
    addFavoriteVendorListItem(id: $id, vendor_company_id: $vendor_company_id)
  }
`;

export interface mAddFavoriteVendorListItemRes {
  addFavoriteVendorListItem?: boolean;
}

export interface mAddFavoriteVendorListItemVars {
  id: string;
  vendor_company_id: string;
}
