import { gql } from '@apollo/client';
import * as Yup from 'yup';

export const mPayAnnualSubscription = gql`
  mutation payAnnualSubscription(
    $paymentInput: AnnualSubscriptionPaymentInput!
  ) {
    payAnnualSubscription(paymentInput: $paymentInput)
  }
`;

export interface mPayAnnualSubscriptionRes {
  payAnnualSubscription?: boolean;
}

export interface mPayAnnualSubscriptionFormVar {
  is_default: boolean;
  payment_method?: string;
  existing_card?: string;
}

export interface mPayAnnualSubscriptionVar {
  paymentInput: mPayAnnualSubscriptionFormVar;
}

export const mPayAnnualSubscriptionInitVals: mPayAnnualSubscriptionFormVar = {
  is_default: false,
  payment_method: '',
  existing_card: '',
};

export const mPayAnnualSubscriptionValidations = Yup.object().shape({
  is_default: Yup.boolean(),
  payment_method: Yup.string(),
  existing_card: Yup.string(),
});
