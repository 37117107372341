import { gql } from '@apollo/client';

export const mUpdateProjectTypeHasVendorBudgetRanges = gql`
  mutation updateProjectTypeHasVendorBudgetRanges(
    $project_type_id: ID!
    $vendor_budget_range_ids: [ID!]!
  ) {
    updateProjectTypeHasVendorBudgetRanges(
      project_type_id: $project_type_id
      vendor_budget_range_ids: $vendor_budget_range_ids
    )
  }
`;

export interface mUpdateProjectTypeHasVendorBudgetRangesVars {
  project_type_id: string;
  vendor_budget_range_ids: string[];
}

export interface mUpdateProjectTypeHasVendorBudgetRangesRes {
  updateProjectTypeHasVendorBudgetRanges?: boolean;
}
