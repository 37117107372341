import { gql } from '@apollo/client';
import { IProjectTypeType } from 'types';

export const qProjectTypeById = gql`
  query projectTypeById($id: ID!) {
    projectTypeById(id: $id) {
      uuid
      name
      short_description
      long_description
      sort_order
      vendor_label
      freelance_label
      client_label
      dashboard_label
      is_more_data
      is_project_changes
    }
  }
`;

export interface qProjectTypeByIdRes {
  projectTypeById?: IProjectTypeType;
}
