import { gql } from '@apollo/client';

export const mActiveDeactiveProjectType = gql`
  mutation activeDeactiveProjectType($id: ID!, $status: Boolean!) {
    activeDeactiveProjectType(id: $id, status: $status)
  }
`;

export interface mActiveDeactiveProjectTypeVars {
  id: string;
  status: boolean;
}

export interface mActiveDeactiveProjectTypeRes {
  activeDeactiveProjectType?: boolean;
}
