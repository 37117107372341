import { gql } from '@apollo/client';
import { ITalentReelUpdateSortFormType } from 'types';

export const mUpdateTalentReelSortOrder = gql`
  mutation updateTalentReelSortOrder($sortData: [ReelSortOrderDto!]!) {
    updateTalentReelSortOrder(sortData: $sortData)
  }
`;

export interface mUpdateTalentReelSortOrderRes {
  updateTalentReelSortOrder?: boolean;
}

export interface mUpdateTalentReelSortVars {
  sortData: ITalentReelUpdateSortFormType[];
}
