import { gql } from '@apollo/client';

export const qGetRelationProfiles = gql`
  query getRelationProfiles(
    $relation_id: ID!
    $relation_with_freelance: Boolean!
  ) {
    getRelationProfiles(
      relation_id: $relation_id
      relation_with_freelance: $relation_with_freelance
    )
  }
`;

export interface qGetRelationProfilesRes {
  getRelationProfiles?: string[];
}
