import { gql } from '@apollo/client';

export const mConfirmNewEmail = gql`
  mutation confirmNewEmail($token: String!) {
    confirmNewEmail(token: $token)
  }
`;

export interface mConfirmNewEmailRes {
  confirmNewEmail?: boolean;
}

export interface mConfirmNewEmailVar {
  token: string;
}
