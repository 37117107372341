import { gql } from '@apollo/client';
import * as Yup from 'yup';

export const mShareProjectWithMangers = gql`
  mutation shareProjectWithMangers(
    $shareProjectInput: ShareProjectWithMangerInput!
  ) {
    shareProjectWithMangers(shareProjectInput: $shareProjectInput)
  }
`;

export interface mShareProjectWithMangersRes {
  shareProjectWithMangers?: boolean;
}

export interface mShareProjectWithMangersFormType {
  project_id: string;
  managers: string[];
}

export interface mShareProjectWithMangersVar {
  shareProjectInput: mShareProjectWithMangersFormType;
}

export const mShareProjectWithMangersInitVals: mShareProjectWithMangersFormType =
  {
    project_id: '',
    managers: [],
  };

export const mShareProjectWithMangersInitValidations = Yup.object().shape({
  project_id: Yup.string().required('Project is required!'),
  managers: Yup.array()
    .of(Yup.string().required('Minimum one manager is required!'))
    .required('Managers are required!'),
});
