import { gql } from '@apollo/client';
import { IUpdateEnterpriseAdminFormType } from 'types';

export const mAdminUpdateEnterpriceAdmin = gql`
  mutation adminUpdateEnterpriceAdmin(
    $updateEnterpriceAdmin: UpdateEnterpriceAdminDto!
  ) {
    adminUpdateEnterpriceAdmin(updateEnterpriceAdmin: $updateEnterpriceAdmin)
  }
`;

export interface mAdminUpdateEnterpriceAdminRes {
  adminUpdateEnterpriceAdmin?: boolean;
}

export interface mAdminUpdateEnterpriceAdminVars {
  updateEnterpriceAdmin: IUpdateEnterpriseAdminFormType;
}
