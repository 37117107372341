import { IClientCompanyTypeEnum, IMembershipType, IS3FileType } from 'types';

export const CUSTOM_ERROR = 'CustomError';
export const limit = 15;
export const shortLimit = 5;
export const adminLimit = 7;
export const defaultLimit = 10;
export const extraLimit = 20;

export const ANNUAL_SUBSCRIPTION_FEES = 399.99;
export const ANNUAL_PER_DROP_FEES = 24.99;
export const PAYASGO_PER_DROP_FEES = 49.99;
export const PAYASGO_PER_DROP_ANONYMOUS_FEES = 9.99;
export const PAYASGO_PER_DROP_WITH_ANONYMOUS_FEES = (
  PAYASGO_PER_DROP_FEES + PAYASGO_PER_DROP_ANONYMOUS_FEES
).toFixed(2);
export const PAYASGO_PROJECT_UPDATE_FEES = 9.99;

export const DASHBOARD_INTERVAL_TIME = 1000 * 60 * 5;

export const CONTACT_EMAIL = 'info@reeldrop.com';

export const FEMALE_OWNED_COMPANY = 'Female Owned Company Studio';
export const MINORITY_OWNED_COMPANY = 'Minority Owned Company Studio';
export const VETERAN_OWNED_COMPANY = 'Veteran Owned Studio';

export const THEME_LOCALSTORAGE_KEY = 'theme';

export const REFRESH_TOKEN = 'rd_refresh_token';
export const ACCESS_TOKEN = 'rd_access_token';

export const s3FileTypeIdentifiers: Record<IS3FileType, string[]> = {
  IMAGE: [
    '.bmp',
    '.gif',
    '.jpg',
    '.jpeg',
    '.png',
    '.svg',
    '.tiff',
    '.tiff',
    '.webp',
  ],
  DOCUMENT: ['.pdf'],
  VIDEO: [],
  AUDIO: [],
};

export const monthNameArray = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

export const clientMemberships = (
  isPricingPage: boolean,
): IMembershipType[] => {
  return [
    {
      id: IClientCompanyTypeEnum.PAYASGO,
      title: 'FREE TRIAL',
      isNeedCC: false,
      displayPrice: 'First Drop Free',
      price: 39,
      features: [
        'Submit a project to Vendors',
        'Anonymous Submission (+$9.99)',
        'NDA Shield ',
        'Manage & Sort Submissions',
        'Create Team Screening Rooms',
        'Review and vote with Team',
      ],
      buttonText: 'REGISTER',
    },
    {
      id: IClientCompanyTypeEnum.PAYASGO,
      title: 'PAY AS YOU GO',
      isNeedCC: false,
      displayPrice: '$49⁹⁹/drop',
      price: 49,
      features: [
        'Submit a project to Vendors',
        'Anonymous Submission (+$9.99)',
        'Project Update (+$9.99)',
        'NDA Shield',
        'Manage & Sort Submissions',
        'Create Team Screening Rooms',
        'Share Favorite Vendors Lists',
        'Search Talent Database',
      ],
      buttonText: 'REGISTER',
    },
    {
      id: IClientCompanyTypeEnum.ANNUAL,
      title: `${isPricingPage ? 'BULK RATE' : 'BULK RATE Subscription'}`,
      isNeedCC: true,
      displayPrice: '$399⁹⁹/year',
      price: 399,
      features: [
        '10 submissions per year',
        'Free Anonymous Submissions',
        'Free Project Updates',
        'NDA Shield Protection',
        'Manage & Sort Submission',
        'Create Team Screening Rooms',
        'Share Favorite Vendors Lists',
        'Search Talent Database',
        '50% off each drop after capacity',
      ],
    },
    {
      id: IClientCompanyTypeEnum.ENTERPRISE,
      title: 'ENTERPRISE',
      isNeedCC: true,
      displayPrice: 'ASK MORE',
      price: 240,
      features: [
        'Perfect for teams of 5 or more',
        'Unlimited Submissions',
        'Free Anonymous Submissions',
        'Free Project Updates',
        'NDA Shield Protection',
        'Manage & Sort Submission',
        'Create Team Screening Rooms ',
        'Share Favorite Vendors Lists',
        'Search Talent Database',
        'Team NDA Database Sharing',
        'Team Project Sharing',
      ],
    },
  ];
};

export enum IRoutes {
  About = 'https://www.reeldrop.com/about.html',
  Accept_Vendor_Manager = '/accept-vendor-manager',
  Admin = '/admin',
  Admin_Add_Talent = '/admin/add-talent',
  Admin_Add_Vendor = '/admin/add-vendor',
  Admin_Add_Vendor_Pre = '/admin/add-vendor-pre',
  Admin_Add_Enterprise_Client = '/admin/add-enterprise-client',
  Admin_Client_Budget_Range = '/admin/client-budget-ranges',
  Admin_Client_Budget_Range_Edit = '/admin/client-budget-range/edit',
  Admin_Client_Budget_Range_Add = '/admin/client-budget-range/add',
  Admin_Creative_Style = '/admin/creative-styles',
  Admin_Creative_Style_Add = '/admin/creative-style/add',
  Admin_Creative_Style_Edit = '/admin/creative-style/edit',
  Admin_Dashboard = '/admin/dashboard',
  Admin_Enterprise_Client = '/admin/enterprise-clients',
  Admin_Enterprise_Client_Request = '/admin/enterprise-client-requests',
  Admin_Language = '/admin/languages',
  Admin_Language_Add = '/admin/language/add',
  Admin_Language_Edit = '/admin/language/edit',
  Admin_Manage_Creative_Style = '/admin/manage-creative-style',
  Admin_Manage_Client_Budget_Range = '/admin/manage-client-budget-range',
  Admin_Manage_Language = '/admin/manage-languages',
  Admin_Manage_Talent_Group = '/admin/manage-talent-group',
  Admin_Manage_Talent_Type = '/admin/manage-talent-type',
  Admin_Manage_Vendor_Budget_Range = '/admin/manage-vendor-budget-range',
  Admin_Member = '/admin/member',
  Admin_Payasgo_Client = '/admin/payg-clients',
  Admin_Profession = '/admin/professions',
  Admin_Project = '/admin/projects',
  Admin_Project_Submission = '/admin/project/submissions',
  Admin_Project_Type = '/admin/project-types',
  Admin_Project_Type_Add = '/admin/project-type/add',
  Admin_Project_Type_Edit = '/admin/project-type/edit',
  Admin_Setting = '/admin/settings',
  Admin_Subscription_Client = '/admin/subscription-clients',
  Admin_Talent = '/admin/talent',
  Admin_Talent_Group = '/admin/talent-groups',
  Admin_Talent_Group_Add = '/admin/talent-group/add',
  Admin_Talent_Group_Edit = '/admin/talent-group/edit',
  Admin_Talent_Type = '/admin/talent-types',
  Admin_Talent_Type_Add = '/admin/talent-type/add',
  Admin_Talent_Type_Edit = '/admin/talent-type/edit',
  Admin_Update_Talent = '/admin/update-talent',
  Admin_User = '/admin/users',
  Admin_Vendor = '/admin/vendor',
  Admin_Vendors = '/admin/vendors',
  Admin_Vendor_Budget_Range = '/admin/vendor-budget-ranges',
  Admin_Vendor_Budget_Range_Add = '/admin/vendor-budget-range/add',
  Admin_Vendor_Budget_Range_Edit = '/admin/vendor-budget-range/edit',
  Approve_Account = '/approve-account',
  Chat = '/chat',
  Confirm_Email = '/confirm-email',
  Complete_Profile = '/complete-profile',
  Dashboard = '/dashboard',
  Dashboard_Add_New_Project = '/dashboard/add-new-project',
  Dashboard_Add_talent = '/dashboard/add-talent',
  Dashboard_Add_Profile_Reel = '/dashboard/add-profile-reel',
  Dashboard_Add_Profile_New_Reel = '/dashboard/add-new-profile-reel',
  Dashboard_Add_talent_reel = '/dashboard/add-talent-reel',
  Dashboard_Account_Setting = '/dashboard/account-settings',
  Dashboard_NDA_Center = '/dashboard/nda-center',
  Dashboard_Talent_Overview = '/dashboard/talent-overview',
  Dashboard_Profile_Overview = '/dashboard/profile-overview',
  Dashboard_Vendor_Talent_Overview = '/dashboard/vendor-talent-overview',
  Dashboard_Vendor_Profile_Overview = '/dashboard/vendor-profile-overview',
  Dashboard_Talent_Center = '/dashboard/talent-center',
  Dashboard_Talent_Center_Project = '/dashboard/talent-center/project',
  Dashboard_Talent_Search = '/dashboard/talent-search',
  Dashboard_Project = '/dashboard/project',
  Dashboard_Screening_Room = '/dashboard/screening-room',
  Dashboard_Project_Center = '/dashboard/project-center',
  Dashboard_Vendor_Center = '/dashboard/vendor-center',
  Dashboard_Vendor_overview = '/dashboard/vendor-overview',
  Dashboard_Vendor_NDA = '/dashboard/vendor-NDAs',
  Dashboard_Vendor_Submission = '/dashboard/vendor-submission',
  Dashboard_Vendor_Review_Submission = '/dashboard/vendor-review-submission',
  Dashboard_Favorite_Vendor = '/dashboard/favorite-vendors',
  Dashboard_Freelancer_Overview = '/dashboard/freelance-overview',
  Dashboard_Manage_User = '/dashboard/manage-users',
  Dashboard_Manage_Alert = '/dashboard/manage-alerts',
  Dashboard_Message_Center = '/dashboard/message-center',
  Dashboard_Manage_Team = '/dashboard/manage-teams',
  Dashboard_Update_Temp_Password = '/dashboard/update-temp-password',
  Dashboard_Salesrep_Overview = '/dashboard/independent-overview',
  Dashboard_Update_Talent = '/dashboard/update-talent',
  Dashboard_Update_Freelance = '/dashboard/update-freelance',
  Dashboard_Update_Project = '/dashboard/update-project',
  Dashboard_Help_Clients = '/dashboard/help-clients',
  Dashboard_Help_Vendors = '/dashboard/help-vendors',
  Downgrade_Success_Enterprise = '/downgrade-success-enterprise',
  Enterprise_Client_Request = '/enterprise-client-requests',
  Forgot_Password = '/forgot-password',
  Forgot_Username = '/forgot-username',
  Home = '/',
  Login = '/',
  Logout = '/logout',
  Landing_Page = 'https://www.reeldrop.com/',
  Merge_Account = '/merge-account',
  Pricing = '/pricing',
  Project = '/project',
  Pending_Verify_Vendor_Success = '/pending-verify-vendor-success',
  Register = '/register',
  Register_Success = '/register-success',
  Register_Client = '/register-client',
  Register_SalesRep = '/register-independent-rep',
  Register_Vendor = '/register-vendor',
  Register_Manager = '/register-manager',
  Reset_Password = '/reset-password',
  Register_Vendor_Manager = '/register-vendor-manager',
  Register_Team_Member = '/register-team-member',
  Register_Unclaimed_Vendor = '/register-unclaimed-vendor',
  Request_Success_Enterprise = '/request-success-enterprise',
  Setting = '/settings',
  Success_Contact_Admin = '/success-contact-admin',
  Team_Invite = '/team-invite',
  Update_Email = '/update-email',
  Vendor_Success = '/vendor-success',
  Verify_Email_Client = '/verify-email-client',
  Verify_Email_Vendor = '/verify-email-vendor',
  Admin_Messages = '/admin/messages',
  Stories = '/admin/stories',
  Admin_Add_Story_Type_Pre = '/admin/add-story-type-pre',
  Story_Types = '/admin/story-types',
  Stories_list = '/admin/stories-list',
  Admin_Add_Story = '/admin/add-story',
  Admin_Edit_Story = '/admin/edit-story',
  Dashboard_Stories = '/the-drop/stories',
  Send_Stories_Mail = '/admin/send-stories-mail',
}

export const IframePageRightDivWidth = 55;
export const IframePageLeftDivWidth = 45;
