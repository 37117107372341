import { gql } from '@apollo/client';

export const qGetPaymentIntentForProject = gql`
  query getPaymentIntentForProject($is_anonymous: Boolean) {
    getPaymentIntentForProject(is_anonymous: $is_anonymous)
  }
`;

export interface qGetPaymentIntentForProjectRes {
  getPaymentIntentForProject?: string;
}

export interface qGetPaymentIntentForProjectVar {
  is_anonymous: boolean;
}
