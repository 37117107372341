import { gql } from '@apollo/client';
import { IUserType } from 'types';

export const qGetMyCompanyUsers = gql`
  {
    getMyCompanyUsers {
      uuid
      name
    }
  }
`;

export interface qGetMyCompanyUsersRes {
  getMyCompanyUsers?: IUserType[];
}
