import { gql } from '@apollo/client';
import { talentOverviewString } from 'apis/talentOverview';
import { ITalentOverviewFormType, ITalentOverviewType } from 'types';

export const mAdminTalentOverview = gql`
  mutation adminTalentOverview(
    $talentInfo: TalentOverviewDto!
    $project_type_ids: [ID!]!
  ) {
    adminTalentOverview(
      talentInfo: $talentInfo
      project_type_ids: $project_type_ids
    ) {
      ${talentOverviewString}
    }
  }
`;

export interface mAdminTalentOverviewRes {
  adminTalentOverview?: ITalentOverviewType;
}

export const mAdminTalentOverviewInitVals: ITalentOverviewFormType = {
  talentInfo: {
    talent_name: '',
    bio: '',
    vendor_budget_range_id: '',
    talent_type_id: [],
    talent_group_ids: [],
    creative_style_ids: [],
    talent_language_ids: [],
    project_type_id: '',
    talent_image: '',
    vendor_company_id: '',
    is_primary: true,
  },
  project_type_ids: [],
};
