import { gql } from '@apollo/client';
import { INDAType } from 'types';

export const qGetNDAById = gql`
  query getNDAById($id: ID!) {
    getNDAById(id: $id) {
      uuid
      name
      ndaFile {
        name
        url
      }
      user {
        uuid
        role
      }
    }
  }
`;

export interface qGetNDAByIdRes {
  getNDAById?: INDAType;
}
