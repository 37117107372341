import { gql } from '@apollo/client';

export const mUpgradeClientManagerToAdmin = gql`
  mutation upgradeClientManagerToAdmin($id: ID!) {
    upgradeClientManagerToAdmin(id: $id)
  }
`;

export interface mUpgradeClientManagerToAdminRes {
  upgradeClientManagerToAdmin?: boolean;
}
