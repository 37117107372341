import { gql } from '@apollo/client';

export const mAdminDeleteTalentOverview = gql`
  mutation adminDeleteTalentOverview($id: ID!) {
    adminDeleteTalentOverview(id: $id)
  }
`;

export interface mAdminDeleteTalentOverviewRes {
  adminDeleteTalentOverview?: boolean;
}

export interface mAdminDeleteTalentOverviewVar {
  id: string;
}
