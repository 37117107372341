import { gql } from '@apollo/client';
import {
  IVendorFreelanceRelationType,
  IVendorRelationshipChatType,
} from 'types';

export const qRelationWithFreelancer = gql`
  {
    relationWithFreelancer {
      relations {
        uuid
        relationProfileIds
        freelanceCompany {
          company_name
          talentOverviews {
            profiles {
              uuid
              projectType {
                uuid
                vendor_label
                freelance_label
              }
            }
          }
        }
      }
      invitees {
        uuid
        invitee_vendor_company_profile_ids
        is_send_reel
        is_reply_message
        inviteeCompany {
          company_name
          talentOverviews {
            profiles {
              uuid
              projectType {
                uuid
                vendor_label
                freelance_label
              }
            }
          }
        }
      }
    }
  }
`;

export interface qRelationWithFreelancerRes {
  relationWithFreelancer?: {
    relations: IVendorFreelanceRelationType[];
    invitees: IVendorRelationshipChatType[];
  };
}
