import { gql } from '@apollo/client';
import { INdaVendorStatusType, IPaginationVars } from 'types';

export const qGetVendorNdaStatusByNda = gql`
  query getVendorNdaStatusByNda(
    $id: ID!
    $limit: Int!
    $offset: Int!
    $sorts: [OrderByInput!]
  ) {
    getVendorNdaStatusByNda(
      id: $id
      limit: $limit
      offset: $offset
      sorts: $sorts
    ) {
      total
      rows {
        uuid
        status
        created_at
        vendorCompany {
          uuid
          company_name
        }
        reviewBy {
          uuid
          name
        }
        uploadFile {
          name
          url
        }
      }
    }
  }
`;

export interface qGetVendorNdaStatusByNdaRes {
  getVendorNdaStatusByNda?: {
    total: number;
    rows: INdaVendorStatusType[];
  };
}

export interface qGetVendorNdaStatusByNdaVars extends IPaginationVars {
  id: string;
}
