import { gql } from '@apollo/client';
import * as Yup from 'yup';
import { IUploadFileType } from 'types';

export const mCreateNDA = gql`
  mutation createNDA($ndaInput: NDAInput!) {
    createNDA(ndaInput: $ndaInput)
  }
`;

export interface mCreateNDARes {
  createNDA?: boolean;
}

export interface mCreateNDAFormType {
  upload_file_object: IUploadFileType;
  name: string;
  note?: string;
  email_subject: string;
  email_note: string;
  project_type_ids: string[];
  managers: string[];
  vs_is_all: boolean;
  vs_is_minority: boolean;
  vs_is_female: boolean;
  vs_is_east_coast: boolean;
  vs_is_west_coast: boolean;
  vs_is_veteran: boolean;
  vs_is_midwest: boolean;
  vs_is_freelance_talent: boolean;
  vs_is_vendor_rep: boolean;
  vs_is_sales_rep: boolean;
  vs_emails: string[];
  vs_states: string[];
  vs_favorite_vendor_lists: string[];
}

export interface mCreateNDAVar {
  ndaInput: mCreateNDAFormType;
}

export const mCreateNDAInitVals: mCreateNDAFormType = {
  upload_file_object: { name: '', url: '' },
  name: '',
  note: '',
  email_subject: '',
  email_note: '',
  project_type_ids: [],
  managers: [],
  vs_is_all: false,
  vs_is_minority: false,
  vs_is_female: false,
  vs_is_east_coast: false,
  vs_is_west_coast: false,
  vs_is_veteran: false,
  vs_is_midwest: false,
  vs_is_freelance_talent: false,
  vs_is_vendor_rep: false,
  vs_is_sales_rep: false,
  vs_emails: [],
  vs_states: [],
  vs_favorite_vendor_lists: [],
};

export const mCreateNDAInitValidations = Yup.object().shape({
  name: Yup.string()
    .test(
      'len',
      'Nda name must not then bigger then 50 characters!',
      (val) => (val?.length || 0) <= 50,
    )
    .required('NDA name is required!'),
  note: Yup.string(),
  project_type_ids: Yup.array()
    .of(Yup.string().required('Production type is required!'))
    .min(1, 'Production type is required!')
    .required('Production type is required!'),
  managers: Yup.array().of(Yup.string()),
  upload_file_object: Yup.object({
    name: Yup.string().required('NDA file is required!'),
    url: Yup.string().required('NDA file is required!'),
  }).required('NDA file is required!'),

  vs_is_all: Yup.boolean(),
  vs_is_minority: Yup.boolean(),
  vs_is_female: Yup.boolean(),
  vs_is_east_coast: Yup.boolean(),
  vs_is_west_coast: Yup.boolean(),
  vs_is_veteran: Yup.boolean(),
  vs_is_midwest: Yup.boolean(),
  vs_is_freelance_talent: Yup.boolean(),
  vs_is_vendor_rep: Yup.boolean(),
  vs_is_sales_rep: Yup.boolean(),
  vs_states: Yup.array().of(Yup.string()),
  vs_favorite_vendor_lists: Yup.array().of(Yup.string()),

  email_subject: Yup.string().when({
    is: Yup.ref('vs_emails'),
    then: Yup.string().required('Email subject is required!'),
  }),
  email_note: Yup.string().when({
    is: Yup.ref('vs_emails'),
    then: Yup.string().required('Email note is required!'),
  }),
});
