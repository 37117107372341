import { gql } from '@apollo/client';
import { IProjectTypeType } from 'types';

export const qProjectTypesRegistation = gql`
  {
    projectTypes {
      uuid
      name
      unique_name
      short_description
      long_description
      sort_order
      vendor_label
      freelance_label
      client_label
      dashboard_label
      is_more_data
      is_project_changes
      creativeStyles {
        uuid
        name
      }
      talentGroups {
        uuid
        name
      }
      talentTypes {
        uuid
        name
      }
      languages {
        uuid
        name
      }
      clientBudgetRanges {
        uuid
        name
      }
      vendorBudgetRanges {
        uuid
        name
      }
    }
  }
`;

export interface qProjectTypesRegistationRes {
  projectTypes?: IProjectTypeType[];
}
