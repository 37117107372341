import { gql } from '@apollo/client';

export const qValidateEmail = gql`
  query validateEmail($email: String!) {
    validateEmail(email: $email)
  }
`;

export interface qValidateEmailRes {
  validateEmail: boolean;
}

export interface qValidateEmailVars {
  email: string;
}
