export interface INameUuidType {
  uuid: string;
  name: string;
  freelance_label?: string;
  vendor_label?: string;
  is_company_visible?: boolean;
  live_label?: string;
  other_label?: string;
}

export interface Sorts {
  order_by: string;
  order: SortOrder;
}

export interface IPaginationVars {
  sorts?: Sorts[];
  limit: number;
  offset: number;
}

export interface IIdType {
  id: string;
}

export interface IInviteToken {
  email: string;
  first_name: string;
  last_name: string;
  exp: number;
}

export interface ISettingDataType {
  uuid: string;
  name: string;
  unique_name: string;
  short_description: string;
  long_description: string;
  sort_order: number;
  is_active: boolean;
}

export interface ISettingDataFormType {
  id?: string;
  name: string;
  short_description: string;
  long_description: string;
  sort_order: number;
}

export enum SortOrder {
  ASC = 'ASC',
  DESC = 'DESC',
}

export declare const PaginationVarsConst: IPaginationVars;

export interface IColumnAnyVarsType {
  variables: typeof PaginationVarsConst;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setVariables: (variables: any) => void;
}
export interface IColumnVarsType {
  variables: IPaginationVars;
  setVariables: (vars: IPaginationVars) => void;
}
