import { gql } from '@apollo/client';
import { ITalentOverviewProfileType } from 'types';

export const qGetFreelanceProfileByProjectTypeId = gql`
  query freelanceProfileByProjectTypeId($id: ID!) {
    freelanceProfileByProjectTypeId(id: $id) {
      uuid
      bio
      is_primary
      relevancy_ranking
      talentGroups {
        uuid
        name
      }
      creativeStyles {
        uuid
        name
      }
      languages {
        uuid
        name
      }
      talentType {
        uuid
        name
      }
      vendorBudgetRange {
        uuid
        name
      }
    }
  }
`;

export interface qGetFreelanceProfileByProjectTypeIdRes {
  freelanceProfileByProjectTypeId?: ITalentOverviewProfileType;
}
