import { gql } from '@apollo/client';
import { ITalentOverviewType } from 'types';

export const searchTalentsQuery = gql`
  query searchTalents($filters: GetTalentOverviewWithFiltersInputDto!) {
    searchTalents(filters: $filters) {
      uuid
      talent_name
      company {
        uuid
        company_name
        company_type
      }
      profiles {
        uuid
        is_profile_completion
        is_primary
        projectType {
          uuid
          freelance_label
        }
        talentType {
          uuid
          name
        }
      }
    }
  }
`;

export interface GetTalentOverviewWithFiltersInputDto {
  project_type_id?: string | number;
  vendor_budget_range_id?: string | number;
  talent_type_id?: string | number;
  talent_group_ids?: string[];
  language_ids?: string[];
  creative_style_ids?: string[];
  is_freelance?: boolean;
  female_owned_company?: boolean;
  veteran_owned_company?: boolean;
  minority_owned_company?: boolean;
  is_free_bid?: boolean;
  is_union_crew?: boolean;
}

export interface searchTalentsQueryVars {
  filters?: GetTalentOverviewWithFiltersInputDto;
}

export interface searchTalentsRes {
  searchTalents: ITalentOverviewType[];
}
