import { gql } from '@apollo/client';

export const mAddVendorProjectDiscussionChat = gql`
  mutation addVendorProjectDiscussionChat(
    $project_id: ID!
    $message: String!
    $user_ids: [ID!]!
  ) {
    addVendorProjectDiscussionChat(
      project_id: $project_id
      message: $message
      user_ids: $user_ids
    )
  }
`;

export interface mAddVendorProjectDiscussionChatRes {
  addVendorProjectDiscussionChat?: boolean;
}

export interface mAddVendorProjectDiscussionChatVars {
  project_id: string;
  message: string;
  user_ids: string[];
}
