import { gql } from '@apollo/client';
import { IAuthUserType } from 'types';
import { userAttributesString } from 'apis/auth';

export const mCompleteVendorProfileWithoutBios = gql`
  mutation completeVendorProfileWithoutBios {
    completeVendorProfileWithoutBios ${userAttributesString}
  }
`;

export interface mCompleteVendorProfileWithoutBiosRes {
  completeVendorProfileWithoutBios?: IAuthUserType;
}
