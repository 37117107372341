import { gql } from '@apollo/client';
import {
  IPaginationVars,
  ISubmissionType,
  ISubmissionVoteType,
  IVendorCompanyTypeEnum,
} from 'types';

export const qGetSubmissionsByTeamMemberProjectId = gql`
  query getSubmissionsByTeamMemberProjectId(
    $id: ID!
    $limit: Int!
    $offset: Int!
    $sorts: [OrderByInput!]
  ) {
    getSubmissionsByTeamMemberProjectId(
      id: $id
      limit: $limit
      offset: $offset
      sorts: $sorts
    ) {
      total
      rows {
        uuid
        sumOfAllVotes
        relevancy_ranking
        reel_link
        reel_password
        external_talent_name
        external_company_name
        contact_email
        is_external
        note
        created_at
        teamMemberId
        status
        vote {
          uuid
          vote
        }
        project {
          uuid
          name
          talentType {
            uuid
            name
          }
          clientBudgetRange {
            uuid
            name
            vendorBudgetRanges {
              uuid
              name
            }
          }
          languages {
            uuid
            name
          }
          talentGroups {
            uuid
            name
          }
          creativeStyles {
            uuid
            name
          }
          company {
            uuid
          }
        }
        talentProfile {
          bio
          is_primary
          projectType {
            name
            vendor_label
            freelance_label
            client_label
            dashboard_label
          }
          talentType {
            uuid
            name
          }
          vendorBudgetRange {
            uuid
            name
          }
          talentGroups {
            uuid
            name
          }
          creativeStyles {
            uuid
            name
          }
          languages {
            uuid
            name
          }
          talentOverview {
            uuid
            talent_image
            rating
            talent_name
            company {
              uuid
              company_name
              company_type
            }
          }
        }
        styleServiceProfile {
          uuid
          talentType {
            uuid
            name
          }
          creativeStyle {
            uuid
            name
          }
          projectType {
            uuid
          }
          vendorCompany {
            uuid
            company_name
            company_type
            profiles {
              uuid
              company_bio
              projectType {
                uuid
                name
              }
            }
            primaryStyleService {
              talentType {
                uuid
                name
              }
              projectType {
                uuid
              }
            }
            additionalStyleService {
              creativeStyle {
                uuid
                name
              }
              projectType {
                uuid
              }
            }
            companyHasBudget {
              projectType {
                uuid
              }
              budgetRange {
                uuid
                name
              }
            }
            companyHasLanguage {
              projectType {
                uuid
              }
              language {
                uuid
                name
              }
            }
            companyHasTalentGroup {
              projectType {
                uuid
              }
              talentGroup {
                uuid
                name
              }
            }
          }
        }
      }
    }
  }
`;

export interface qGetSubmissionsByTeamMemberProjectIdRes {
  getSubmissionsByTeamMemberProjectId?: {
    total: number;
    rows: ISubmissionType[];
  };
}

export interface qGetSubmissionsByTeamMemberProjectIdVars
  extends IPaginationVars {
  id: string;
}

export interface qGetSubmissionsByTeamMemberProjectIdColumnType {
  uuid: string;
  name: string;
  talent_id: string;
  companyUUID: string;
  companyName: string;
  companyType: IVendorCompanyTypeEnum;
  reelLink: string;
  relevancy: string;
  projectTypeId: string;
  group: string;
  skill: string;
  talentType: string;
  talent_image: string;
  creativeStyles: string;
  languages: string;
  groups: string;
  budget: string;
  ranking: string;
  rating: number;
  bio: string;
  note: string;
  reel_password: string;
  status: string;
  total: number;

  // client member only
  vote: ISubmissionVoteType | null;
  client_company_id: string;
}
