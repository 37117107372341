import { gql } from '@apollo/client';
import { IVendorRatingFormType } from 'types';

export const mVendorRating = gql`
  mutation vendorRating($ratingInput: VendorRatingDto!) {
    vendorRating(ratingInput: $ratingInput)
  }
`;

export interface mVendorRatingRes {
  vendorRating?: boolean;
}

export interface mVendorRatingVar {
  ratingInput: IVendorRatingFormType;
}
