import { gql } from '@apollo/client';
import * as Yup from 'yup';

export const mVendorCompanyUpdate = gql`
  mutation vendorCompanyUpdate($updateVendorCompany: UpdateVendorCompanyDto!) {
    vendorCompanyUpdate(updateVendorCompany: $updateVendorCompany)
  }
`;

export interface mVendorCompanyUpdateRes {
  vendorCompanyUpdate?: boolean;
}

export interface mVendorCompanyUpdateFormVars {
  company_name: string;
  company_project_type: string;
  company_url?: string;
  company_bio?: string;
  company_logo?: string;
  female_owned_company?: boolean;
  minority_owned_company?: boolean;
  veteran_owned_company?: boolean;
  certified_small_business?: boolean;
  lgbt_owned_business?: boolean;
}

export interface mVendorCompanyUpdateVars {
  updateVendorCompany: mVendorCompanyUpdateFormVars;
}

export const mVendorCompanyUpdateValidations = Yup.object().shape({
  company_name: Yup.string().trim().required('Company name is required!'),
  company_project_type: Yup.string().required('Company type is required!'),
  company_url: Yup.string().matches(
    /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?\/?$/gm,
    'Invalid company url!',
  ),
  company_bio: Yup.string().trim(),
  company_logo: Yup.string().url('Invalid logo!'),
  female_owned_company: Yup.boolean(),
  minority_owned_company: Yup.boolean(),
  veteran_owned_company: Yup.boolean(),
});
