import { gql } from '@apollo/client';
import { IProjectType } from 'types';

export const qGetProjectsByTeamMember = gql`
  query getProjectsByTeamMember(
    $limit: Int!
    $offset: Int!
    $sorts: [OrderByInput!]
  ) {
    getProjectsByTeamMember(limit: $limit, offset: $offset, sorts: $sorts) {
      total
      rows {
        uuid
        name
        agency_name
        created_at
        reelCount
        project_status
        author {
          name
        }
      }
    }
  }
`;

export interface qGetProjectsByTeamMemberRes {
  getProjectsByTeamMember?: {
    total: number;
    rows: IProjectType[];
  };
}
