import { gql } from '@apollo/client';
import { IUploadFileType } from 'types';

export const mApproveNdaByVendor = gql`
  mutation approveNdaByVendor(
    $nda_id: ID!
    $upload_file_object: GetCreateFileInput!
  ) {
    approveNdaByVendor(nda_id: $nda_id, upload_file_object: $upload_file_object)
  }
`;

export interface mApproveNdaByVendorRes {
  approveNdaByVendor?: boolean;
}

export interface mApproveNdaByVendorVars {
  nda_id: string;
  upload_file_object: IUploadFileType;
}
