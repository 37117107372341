import { gql } from '@apollo/client';

export const mCancelSubscription = gql`
  mutation cancelSubscription($id: ID!) {
    cancelSubscription(id: $id)
  }
`;

export interface mCancelSubscriptionRes {
  cancelSubscription?: boolean;
}

export interface mCancelSubscriptionVar {
  id: string;
}
