import { gql } from '@apollo/client';

export const mCreateDuplicateFavoriteVendorList = gql`
  mutation createDuplicateFavoriteVendorList($name: String!, $list_id: ID!) {
    createDuplicateFavoriteVendorList(name: $name, list_id: $list_id)
  }
`;

export interface mCreateDuplicateFavoriteVendorListRes {
  createDuplicateFavoriteVendorList?: boolean;
}

export interface mCreateDuplicateFavoriteVendorListVar {
  name: string;
  list_id: string;
}
