import { gql } from '@apollo/client';

export const mBlockUnblockVendorCompany = gql`
  mutation blockUnblockVendorCompany($id: ID!, $status: Boolean!) {
    blockUnblockVendorCompany(id: $id, status: $status)
  }
`;

export interface mBlockUnblockVendorCompanyVars {
  id: string;
  status: boolean;
}

export interface mBlockUnblockVendorCompanyRes {
  blockUnblockVendorCompany?: boolean;
}
