import { gql } from '@apollo/client';
import * as Yup from 'yup';
import { projectErrors } from 'errors';
import {
  IProjectFormType,
  INameUuidType,
  IUploadFileType,
  IProjectTypeType,
} from 'types';
import { ISettingTypesType } from 'types/CreativeStyle.type';

export const qGetProjectRequirementByProjectType = gql`
  query getFieldsFromProjectTypeId($id: ID!) {
    getFieldsFromProjectTypeId(id: $id) {
      creativeStyles {
        uuid
        name
        sort_order
      }
      talentGroups {
        uuid
        name
        is_company_visible
      }
      talentTypes {
        uuid
        name
      }
      clientBudgetRanges {
        uuid
        name
        live_label
        other_label
      }
      languages {
        uuid
        name
      }
      vendorBudgetRanges {
        uuid
        name
      }
    }
  }
`;

export interface qGetProjectRequirementByProjectTypeRes {
  getFieldsFromProjectTypeId?: {
    creativeStyles?: ISettingTypesType[];
    talentGroups?: INameUuidType[];
    talentTypes?: INameUuidType[];
    clientBudgetRanges?: INameUuidType[];
    languages?: INameUuidType[];
    vendorBudgetRanges?: INameUuidType[];
  };
}

export const qCreateProjectRequirements = gql`
  {
    projectTypes {
      uuid
      name
      freelance_label
    }
    creativeStyles {
      uuid
      name
    }
    talentGroups {
      uuid
      name
    }
    talentTypes {
      uuid
      name
    }
    clientBudgetRanges {
      uuid
      name
    }
    languages {
      uuid
      name
    }
    vendorBudgetRanges {
      uuid
      name
    }
  }
`;

export interface qCreateProjectRequirementsRes {
  projectTypes?: INameUuidType[];
  creativeStyles?: INameUuidType[];
  talentGroups?: INameUuidType[];
  talentTypes?: INameUuidType[];
  clientBudgetRanges?: INameUuidType[];
  languages?: INameUuidType[];
  vendorBudgetRanges?: INameUuidType[];
}

export const mCreateProject = gql`
  mutation createProject($projectInput: ProjectInput!) {
    createProject(projectInput: $projectInput)
  }
`;

export interface mCreateProjectRes {
  createProject?: boolean;
}

export interface mCreateProjectFormVar {
  project_object: IProjectFormType;
  nda_id?: string;
  upload_file_object_script?: IUploadFileType;
  upload_file_object_nda?: IUploadFileType;
  is_default: boolean;
  payment_method?: string;
  payment_intent?: string;
  existing_card?: string;
  submit_without_nda?: boolean | string;
  managers?: string[];
}

export interface mCreateProjectVar {
  projectInput: mCreateProjectFormVar;
}

export const mCreateProjectInitVals: mCreateProjectFormVar = {
  project_object: {
    client_budget_range_id: undefined,
    project_type: '',
    rep_type: '',
    talent_type: '',
    languages: [],
    talent_group: [],
    creative_styles: [],
    is_include_insurance: false,
    is_include_post: false,
    is_union_talent: false,
    is_include_audio: false,
    is_include_color: false,
    is_include_logo: false,
    is_shoot_firm: false,
    is_location_firm: false,
    is_anonymous: false,
    name: '',
    agency_state_id: '',
    agency_name: '',
    brand_name: '',
    location: '',
    budget: '',
    shoot_date: '',
    end_shoot_date: '',
    shoot_day: '',
    spots_length: '',
    due_date: '',
    description: '',
    creative_url: '',
    vs_is_all: false,
    vs_is_minority: false,
    vs_is_female: false,
    vs_is_east_coast: false,
    vs_is_west_coast: false,
    vs_is_veteran: false,
    vs_is_midwest: false,
    vs_is_freelance_talent: false,
    vs_is_vendor_rep: false,
    vs_is_sales_rep: false,
    vs_states: [],
    vs_favorite_vendor_lists: [],
  },
  nda_id: '',
  upload_file_object_script: { name: '', url: '' },
  upload_file_object_nda: { name: '', url: '' },
  is_default: false,
  payment_intent: '',
  payment_method: '',
  existing_card: '',
  submit_without_nda: '',
  managers: [],
};

export const mCreateProjectInitTouched = {
  project_object: {
    vs_is_all: true,
    vs_is_minority: true,
    vs_is_female: true,
    vs_is_east_coast: true,
    vs_is_west_coast: true,
    vs_is_veteran: true,
    vs_is_midwest: true,
    vs_is_freelance_talent: true,
    vs_is_vendor_rep: true,
    vs_is_sales_rep: true,
    vs_states: true,
    vs_favorite_vendor_lists: true,
  },
};

export const mCreateProjectValidations = (pTypes: IProjectTypeType[]) =>
  Yup.object().shape({
    nda_id: Yup.string(),
    upload_file_object_script: Yup.object().shape({
      name: Yup.string(),
      url: Yup.string(),
    }),
    upload_file_object_nda: Yup.object().shape({
      name: Yup.string(),
      url: Yup.string(),
    }),
    submit_without_nda: Yup.boolean().when(
      ['nda_id', 'upload_file_object_nda'],
      {
        is: (
          nda_id: string,
          upload_file_object_nda: { name: string; url: string },
        ) => {
          if (
            (nda_id === undefined || nda_id === '') &&
            (upload_file_object_nda.name === undefined ||
              upload_file_object_nda.name === '') &&
            (upload_file_object_nda.url === undefined ||
              upload_file_object_nda.url === '')
          )
            return true;
          return false;
        },
        then: (sch) => sch.required('Must add NDA or submit without NDA!'),
      },
    ),
    project_object: Yup.object().shape({
      project_type: Yup.string().required(projectErrors.PROJECT_TYPE_REQUIRED),
      name: Yup.string().required(projectErrors.PROJECT_NAME_REQUIRED),
      agency_name: Yup.string().required(projectErrors.AGENCY_NAME_REQUIRED),
      agency_state_id: Yup.string().required(
        projectErrors.AGENCY_LOCATION_REQUIRED,
      ),
      brand_name: Yup.string().required(projectErrors.BRAND_NAME_REQUIRED),
      location: Yup.string().when(['project_type'], {
        is: (project_type: string) => {
          const usedPtype = pTypes.filter((p) => project_type === p.uuid);

          if (
            usedPtype.find(
              (p) =>
                (!p.is_more_data && !p.is_project_changes) ||
                (!p.is_more_data && p.is_project_changes),
            )
          )
            return true;
          return false;
        },
        then: (sch) => sch.required(projectErrors.LOCATION_REQUIRED),
      }),
      budget: Yup.string().required(projectErrors.BUDGET_REQUIRED),
      is_location_firm: Yup.boolean().when(['project_type'], {
        is: (project_type: string) => {
          const usedPtype = pTypes.filter((p) => project_type === p.uuid);

          if (usedPtype.find((p) => !p.is_more_data && !p.is_project_changes))
            return true;
          return false;
        },
        then: (sch) => sch.required('Firm Location is required!'),
      }),
      shoot_date: Yup.string().required(projectErrors.SHOOT_DATE_REQUIRED),
      end_shoot_date: Yup.string().required(
        projectErrors.SHOOT_END_DATE_REQUIRED,
      ),
      is_shoot_firm: Yup.boolean().when(['project_type'], {
        is: (project_type: string) => {
          const usedPtype = pTypes.filter((p) => project_type === p.uuid);

          if (usedPtype.find((p) => !p.is_more_data && !p.is_project_changes))
            return true;
          return false;
        },
        then: (sch) => sch.required('Firm shoot is required!'),
      }),
      shoot_day: Yup.string().when(['project_type'], {
        is: (project_type: string) => {
          const usedPtype = pTypes.filter((p) => project_type === p.uuid);

          if (usedPtype.find((p) => !p.is_more_data && !p.is_project_changes))
            return true;
          return false;
        },
        then: (sch) => sch.required(projectErrors.SHOOT_DAYS_REQUIRED),
      }),
      client_budget_range_id: Yup.string(),
      is_include_post: Yup.boolean().when(['project_type'], {
        is: (project_type: string) => {
          const usedPtype = pTypes.filter((p) => project_type === p.uuid);

          if (usedPtype.find((p) => !p.is_more_data && !p.is_project_changes))
            return true;
          return false;
        },
        then: (sch) => sch.required('Include post is required!'),
      }),
      is_union_talent: Yup.boolean().when(['project_type'], {
        is: (project_type: string) => {
          const usedPtype = pTypes.filter((p) => project_type === p.uuid);

          if (usedPtype.find((p) => !p.is_more_data && !p.is_project_changes))
            return true;
          return false;
        },
        then: (sch) => sch.required('Include non union is required!'),
      }),
      is_include_insurance: Yup.boolean().when(['project_type'], {
        is: (project_type: string) => {
          const usedPtype = pTypes.filter((p) => project_type === p.uuid);

          if (usedPtype.find((p) => !p.is_more_data && !p.is_project_changes))
            return true;
          return false;
        },
        then: (sch) => sch.required('Include insurance is required!'),
      }),
      is_include_audio: Yup.boolean().when(['project_type'], {
        is: (project_type: string) => {
          const usedPtype = pTypes.filter((p) => project_type === p.uuid);

          if (usedPtype.find((p) => !p.is_more_data && p.is_project_changes))
            return true;
          return false;
        },
        then: (sch) => sch.required('Include audio is required!'),
      }),
      is_include_color: Yup.boolean().when(['project_type'], {
        is: (project_type: string) => {
          const usedPtype = pTypes.filter((p) => project_type === p.uuid);

          if (usedPtype.find((p) => !p.is_more_data && p.is_project_changes))
            return true;
          return false;
        },
        then: (sch) => sch.required('Include color is required!'),
      }),
      is_include_logo: Yup.boolean().when(['project_type'], {
        is: (project_type: string) => {
          const usedPtype = pTypes.filter((p) => project_type === p.uuid);

          if (usedPtype.find((p) => !p.is_more_data && p.is_project_changes))
            return true;
          return false;
        },
        then: (sch) => sch.required('Include logo is required!'),
      }),
      creative_url: Yup.string().url(projectErrors.CREATIVE_URL_INVALID),
      description: Yup.string().required(projectErrors.DESCRIPTION_REQUIRED),
      spots_length: Yup.string().required(projectErrors.SPOTS_LENGTH_REQUIRED),
      due_date: Yup.string().required(projectErrors.DUE_DATE_REQUIRED),
      is_anonymous: Yup.boolean().required(),
      talent_type: Yup.string().required(projectErrors.TALENT_TYPE_REQUIRED),
      languages: Yup.array()
        .of(Yup.string().required(projectErrors.LANGUAGE_REQUIRED))
        .required(projectErrors.LANGUAGE_REQUIRED),
      creative_styles: Yup.array()
        .of(Yup.string())
        .when(['project_type'], {
          is: (project_type: string) => {
            const usedPtype = pTypes.filter((p) => project_type === p.uuid);

            if (
              usedPtype.find(
                (p) =>
                  (!p.is_more_data && p.is_project_changes) ||
                  (!p.is_more_data && !p.is_project_changes),
              )
            )
              return true;
            return false;
          },
          then: (sch) =>
            sch
              .min(1, projectErrors.CREATIVE_STYLES_MIN_LENGTH)
              .max(5, projectErrors.CREATIVE_STYLES_MAX_LENGTH)
              .required(projectErrors.CREATIVE_STYLES_REQUIRED),
        }),
      talent_group: Yup.array()
        .of(Yup.string().required(projectErrors.TALENT_GROUP_REQUIRED))
        .required(projectErrors.TALENT_GROUP_REQUIRED),

      vs_is_all: Yup.bool(),
      vs_is_minority: Yup.bool(),
      vs_is_female: Yup.bool(),
      vs_is_east_coast: Yup.bool(),
      vs_is_west_coast: Yup.bool(),
      vs_is_veteran: Yup.bool(),
      vs_is_midwest: Yup.bool(),
      vs_is_freelance_talent: Yup.bool(),
      vs_is_vendor_rep: Yup.bool(),
      vs_is_sales_rep: Yup.bool(),
      vs_states: Yup.array().of(Yup.string()),
      vs_favorite_vendor_lists: Yup.array().of(Yup.string()),
    }),
    is_default: Yup.boolean(),
    payment_intent: Yup.string(),
    payment_method: Yup.string(),
    existing_card: Yup.string(),
    managers: Yup.array().of(Yup.string()),
  });

export const mCreateProjectEnterpriseFirstStepValidations = Yup.object().shape({
  managers: Yup.array().of(Yup.string()),
});

export const mCreateProjectFirstStepValidations = Yup.object().shape({
  project_object: Yup.object().shape({
    project_type: Yup.string().required(projectErrors.PROJECT_TYPE_REQUIRED),
    rep_type: Yup.string().required(projectErrors.REP_TYPE_REQUIRED),
  }),
});

export const mCreateProjectSecondStepValidations = (
  pTypes: IProjectTypeType[],
) =>
  Yup.object()
    .shape({
      nda_id: Yup.string().nullable(true),
      upload_file_object_script: Yup.object().shape({
        name: Yup.string(),
        url: Yup.string(),
      }),
      upload_file_object_nda: Yup.object().shape({
        name: Yup.string(),
        url: Yup.string(),
      }),
      submit_without_nda: Yup.boolean().nullable(true),
      project_object: Yup.object().shape({
        name: Yup.string().required(projectErrors.PROJECT_NAME_REQUIRED),
        agency_state_id: Yup.string().required(
          projectErrors.AGENCY_LOCATION_REQUIRED,
        ),
        agency_name: Yup.string().required(projectErrors.AGENCY_NAME_REQUIRED),
        brand_name: Yup.string().required(projectErrors.BRAND_NAME_REQUIRED),
        location: Yup.string().when('project_type', {
          is: (project_type: string) => {
            const usedPtype = pTypes.filter((p) => project_type === p.uuid);
            return usedPtype.some(
              (p) =>
                !p.is_more_data &&
                (p.is_project_changes || !p.is_project_changes),
            );
          },
          then: (schema) => schema.required(projectErrors.LOCATION_REQUIRED),
        }),
        budget: Yup.string().required(projectErrors.BUDGET_REQUIRED),
        is_location_firm: Yup.boolean().when('project_type', {
          is: (project_type: string) => {
            const usedPtype = pTypes.filter((p) => project_type === p.uuid);
            return usedPtype.some(
              (p) => !p.is_more_data && !p.is_project_changes,
            );
          },
          then: (schema) => schema.required('Firm Location is required!'),
        }),
        shoot_date: Yup.date().required(projectErrors.SHOOT_DATE_REQUIRED),
        // .typeError(projectErrors.SHOOT_DATE_REQUIRED),
        end_shoot_date: Yup.date().required(
          projectErrors.SHOOT_END_DATE_REQUIRED,
        ),
        // .typeError(projectErrors.SHOOT_END_DATE_REQUIRED),
        is_shoot_firm: Yup.boolean().when('project_type', {
          is: (project_type: string) => {
            const usedPtype = pTypes.filter((p) => project_type === p.uuid);
            return usedPtype.some(
              (p) => !p.is_more_data && !p.is_project_changes,
            );
          },
          then: (schema) => schema.required('Firm shoot is required!'),
        }),
        shoot_day: Yup.string().when('project_type', {
          is: (project_type: string) => {
            const usedPtype = pTypes.filter((p) => project_type === p.uuid);
            return usedPtype.some(
              (p) => !p.is_more_data && !p.is_project_changes,
            );
          },
          then: (schema) => schema.required(projectErrors.SHOOT_DAYS_REQUIRED),
        }),
        client_budget_range_id: Yup.string(),
        is_include_post: Yup.boolean().when('project_type', {
          is: (project_type: string) => {
            const usedPtype = pTypes.filter((p) => project_type === p.uuid);
            return usedPtype.some(
              (p) => !p.is_more_data && !p.is_project_changes,
            );
          },
          then: (schema) => schema.required('Include post is required!'),
        }),
        is_union_talent: Yup.boolean().when('project_type', {
          is: (project_type: string) => {
            const usedPtype = pTypes.filter((p) => project_type === p.uuid);
            return usedPtype.some(
              (p) => !p.is_more_data && !p.is_project_changes,
            );
          },
          then: (schema) => schema.required('Include non union is required!'),
        }),
        is_include_insurance: Yup.boolean().when('project_type', {
          is: (project_type: string) => {
            const usedPtype = pTypes.filter((p) => project_type === p.uuid);
            return usedPtype.some(
              (p) => !p.is_more_data && !p.is_project_changes,
            );
          },
          then: (schema) => schema.required('Include insurance is required!'),
        }),
        is_include_audio: Yup.boolean().when('project_type', {
          is: (project_type: string) => {
            const usedPtype = pTypes.filter((p) => project_type === p.uuid);
            return usedPtype.some(
              (p) => !p.is_more_data && p.is_project_changes,
            );
          },
          then: (schema) => schema.required('Include audio is required!'),
        }),
        is_include_color: Yup.boolean().when('project_type', {
          is: (project_type: string) => {
            const usedPtype = pTypes.filter((p) => project_type === p.uuid);
            return usedPtype.some(
              (p) => !p.is_more_data && p.is_project_changes,
            );
          },
          then: (schema) => schema.required('Include color is required!'),
        }),
        is_include_logo: Yup.boolean().when('project_type', {
          is: (project_type: string) => {
            const usedPtype = pTypes.filter((p) => project_type === p.uuid);
            return usedPtype.some(
              (p) => !p.is_more_data && p.is_project_changes,
            );
          },
          then: (schema) => schema.required('Include logo is required!'),
        }),
        creative_url: Yup.string().url(projectErrors.CREATIVE_URL_INVALID),
        description: Yup.string().required(projectErrors.DESCRIPTION_REQUIRED),
        spots_length: Yup.string().required(
          projectErrors.SPOTS_LENGTH_REQUIRED,
        ),
        due_date: Yup.string().required(projectErrors.DUE_DATE_REQUIRED),
        is_anonymous: Yup.boolean().required(),
        talent_type: Yup.string().required(projectErrors.TALENT_TYPE_REQUIRED),
        languages: Yup.array()
          .of(Yup.string().required(projectErrors.LANGUAGE_REQUIRED))
          .required(projectErrors.LANGUAGE_REQUIRED),
        creative_styles: Yup.array()
          .of(Yup.string())
          .when('project_type', {
            is: (project_type: string) => {
              const usedPtype = pTypes.filter((p) => project_type === p.uuid);
              return usedPtype.some(
                (p) =>
                  !p.is_more_data &&
                  (p.is_project_changes || !p.is_project_changes),
              );
            },
            then: (schema) =>
              schema
                .min(1, projectErrors.CREATIVE_STYLES_MIN_LENGTH)
                .max(5, projectErrors.CREATIVE_STYLES_MAX_LENGTH)
                .required(projectErrors.CREATIVE_STYLES_REQUIRED),
          }),
        talent_group: Yup.array()
          .of(Yup.string().required(projectErrors.TALENT_GROUP_REQUIRED))
          .required(projectErrors.TALENT_GROUP_REQUIRED),
      }),
    })
    .test(
      'nda-or-submit',
      'Must add NDA or submit without NDA!',
      function (values) {
        const { nda_id, submit_without_nda, upload_file_object_nda } = values;
        if (
          (!nda_id || nda_id === '') &&
          !submit_without_nda &&
          (!upload_file_object_nda ||
            !upload_file_object_nda.name ||
            !upload_file_object_nda.url)
        ) {
          return this.createError({
            path: 'nda_id',
            message: 'Must add NDA or submit without NDA!',
          });
        }
        return true;
      },
    );

// export const mCreateProjectSecondStepValidations = (
//   pTypes: IProjectTypeType[],
// ) =>
//   Yup.object().shape({
//     nda_id: Yup.string().when(
//       ['submit_without_nda', 'upload_file_object_nda'],
//       {
//         is: (
//           submit_without_nda: boolean,
//           upload_file_object_nda: { name: string; url: string },
//         ) => {
//           return (
//             (submit_without_nda === false ||
//               submit_without_nda === undefined) &&
//             (!upload_file_object_nda ||
//               !upload_file_object_nda.name ||
//               !upload_file_object_nda.url)
//           );
//         },
//         then: (schema) =>
//           schema.required('Must add NDA or submit without NDA!'),
//       },
//     ),
//     upload_file_object_script: Yup.object().shape({
//       name: Yup.string(),
//       url: Yup.string(),
//     }),
//     upload_file_object_nda: Yup.object().shape({
//       name: Yup.string(),
//       url: Yup.string(),
//     }),
//     submit_without_nda: Yup.boolean().when(
//       ['nda_id', 'upload_file_object_nda'],
//       {
//         is: (
//           nda_id: string,
//           upload_file_object_nda: { name: string; url: string },
//         ) => {
//           return (
//             (!nda_id || nda_id === '') &&
//             (!upload_file_object_nda ||
//               !upload_file_object_nda.name ||
//               !upload_file_object_nda.url)
//           );
//         },
//         then: (schema) =>
//           schema.required('Must add NDA or submit without NDA!'),
//       },
//     ),
//     project_object: Yup.object().shape({
//       name: Yup.string().required(projectErrors.PROJECT_NAME_REQUIRED),
//       agency_state_id: Yup.string().required(
//         projectErrors.AGENCY_LOCATION_REQUIRED,
//       ),
//       agency_name: Yup.string().required(projectErrors.AGENCY_NAME_REQUIRED),
//       brand_name: Yup.string().required(projectErrors.BRAND_NAME_REQUIRED),
//       location: Yup.string().when(['project_type'], {
//         is: (project_type: string) => {
//           const usedPtype = pTypes.filter((p) => project_type === p.uuid);

//           if (
//             usedPtype.find(
//               (p) =>
//                 (!p.is_more_data && !p.is_project_changes) ||
//                 (!p.is_more_data && p.is_project_changes),
//             )
//           )
//             return true;
//           return false;
//         },
//         then: (sch) => sch.required(projectErrors.LOCATION_REQUIRED),
//       }),
//       budget: Yup.string().required(projectErrors.BUDGET_REQUIRED),
//       is_location_firm: Yup.boolean().when(['project_type'], {
//         is: (project_type: string) => {
//           const usedPtype = pTypes.filter((p) => project_type === p.uuid);

//           if (usedPtype.find((p) => !p.is_more_data && !p.is_project_changes))
//             return true;
//           return false;
//         },
//         then: (sch) => sch.required('Firm Location is required!'),
//       }),
//       shoot_date: Yup.date()
//         .required(projectErrors.SHOOT_DATE_REQUIRED)
//         .typeError(projectErrors.SHOOT_DATE_REQUIRED),
//       end_shoot_date: Yup.date()
//         .required(projectErrors.SHOOT_END_DATE_REQUIRED)
//         .typeError(projectErrors.SHOOT_END_DATE_REQUIRED),
//       is_shoot_firm: Yup.boolean().when(['project_type'], {
//         is: (project_type: string) => {
//           const usedPtype = pTypes.filter((p) => project_type === p.uuid);

//           if (usedPtype.find((p) => !p.is_more_data && !p.is_project_changes))
//             return true;
//           return false;
//         },
//         then: (sch) => sch.required('Firm shoot is required!'),
//       }),
//       shoot_day: Yup.string().when(['project_type'], {
//         is: (project_type: string) => {
//           const usedPtype = pTypes.filter((p) => project_type === p.uuid);

//           if (usedPtype.find((p) => !p.is_more_data && !p.is_project_changes))
//             return true;
//           return false;
//         },
//         then: (sch) => sch.required(projectErrors.SHOOT_DAYS_REQUIRED),
//       }),
//       client_budget_range_id: Yup.string(),
//       is_include_post: Yup.boolean().when(['project_type'], {
//         is: (project_type: string) => {
//           const usedPtype = pTypes.filter((p) => project_type === p.uuid);

//           if (usedPtype.find((p) => !p.is_more_data && !p.is_project_changes))
//             return true;
//           return false;
//         },
//         then: (sch) => sch.required('Include post is required!'),
//       }),
//       is_union_talent: Yup.boolean().when(['project_type'], {
//         is: (project_type: string) => {
//           const usedPtype = pTypes.filter((p) => project_type === p.uuid);

//           if (usedPtype.find((p) => !p.is_more_data && !p.is_project_changes))
//             return true;
//           return false;
//         },
//         then: (sch) => sch.required('Include non union is required!'),
//       }),
//       is_include_insurance: Yup.boolean().when(['project_type'], {
//         is: (project_type: string) => {
//           const usedPtype = pTypes.filter((p) => project_type === p.uuid);

//           if (usedPtype.find((p) => !p.is_more_data && !p.is_project_changes))
//             return true;
//           return false;
//         },
//         then: (sch) => sch.required('Include insurance is required!'),
//       }),
//       is_include_audio: Yup.boolean().when(['project_type'], {
//         is: (project_type: string) => {
//           const usedPtype = pTypes.filter((p) => project_type === p.uuid);

//           if (usedPtype.find((p) => !p.is_more_data && p.is_project_changes))
//             return true;
//           return false;
//         },
//         then: (sch) => sch.required('Include audio is required!'),
//       }),
//       is_include_color: Yup.boolean().when(['project_type'], {
//         is: (project_type: string) => {
//           const usedPtype = pTypes.filter((p) => project_type === p.uuid);

//           if (usedPtype.find((p) => !p.is_more_data && p.is_project_changes))
//             return true;
//           return false;
//         },
//         then: (sch) => sch.required('Include color is required!'),
//       }),
//       is_include_logo: Yup.boolean().when(['project_type'], {
//         is: (project_type: string) => {
//           const usedPtype = pTypes.filter((p) => project_type === p.uuid);

//           if (usedPtype.find((p) => !p.is_more_data && p.is_project_changes))
//             return true;
//           return false;
//         },
//         then: (sch) => sch.required('Include logo is required!'),
//       }),
//       creative_url: Yup.string().url(projectErrors.CREATIVE_URL_INVALID),
//       description: Yup.string().required(projectErrors.DESCRIPTION_REQUIRED),
//       spots_length: Yup.string().required(projectErrors.SPOTS_LENGTH_REQUIRED),
//       due_date: Yup.string().required(projectErrors.DUE_DATE_REQUIRED),
//       is_anonymous: Yup.boolean().required(),
//       talent_type: Yup.string().required(projectErrors.TALENT_TYPE_REQUIRED),
//       languages: Yup.array()
//         .of(Yup.string().required(projectErrors.LANGUAGE_REQUIRED))
//         .required(projectErrors.LANGUAGE_REQUIRED),
//       creative_styles: Yup.array()
//         .of(Yup.string())
//         .when(['project_type'], {
//           is: (project_type: string) => {
//             const usedPtype = pTypes.filter((p) => project_type === p.uuid);

//             if (
//               usedPtype.find(
//                 (p) =>
//                   (!p.is_more_data && p.is_project_changes) ||
//                   (!p.is_more_data && !p.is_project_changes),
//               )
//             )
//               return true;
//             return false;
//           },
//           then: (sch) =>
//             sch
//               .min(1, projectErrors.CREATIVE_STYLES_MIN_LENGTH)
//               .max(5, projectErrors.CREATIVE_STYLES_MAX_LENGTH)
//               .required(projectErrors.CREATIVE_STYLES_REQUIRED),
//         }),
//       talent_group: Yup.array()
//         .of(Yup.string().required(projectErrors.TALENT_GROUP_REQUIRED))
//         .required(projectErrors.TALENT_GROUP_REQUIRED),
//     }),
//   });

export const mCreateProjectThiredStepValidations = Yup.object().shape({
  project_object: Yup.object().shape({
    vs_is_all: Yup.bool(),
    vs_is_minority: Yup.bool(),
    vs_is_female: Yup.bool(),
    vs_is_east_coast: Yup.bool(),
    vs_is_west_coast: Yup.bool(),
    vs_is_veteran: Yup.bool(),
    vs_is_midwest: Yup.bool(),
    vs_is_freelance_talent: Yup.bool(),
    vs_is_vendor_rep: Yup.bool(),
    vs_is_sales_rep: Yup.bool(),
    vs_states: Yup.array().of(Yup.string()),
    vs_favorite_vendor_lists: Yup.array().of(Yup.string()),
  }),
  is_default: Yup.boolean(),
  payment_intent: Yup.string(),
  payment_method: Yup.string(),
  existing_card: Yup.string(),
});
