import { gql } from '@apollo/client';

export const qGetSetupIntent = gql`
  {
    getSetupIntent
  }
`;

export interface qGetSetupIntentRes {
  getSetupIntent?: string;
}
