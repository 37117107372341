import { gql } from '@apollo/client';

export const mUpdateVendorNdaStatusMessage = gql`
  mutation updateVendorNdaStatusMessage(
    $id: ID!
    $channel_id: ID!
    $status: Boolean!
    $url: String!
  ) {
    updateVendorNdaStatusMessage(
      id: $id
      channel_id: $channel_id
      status: $status
      url: $url
    )
  }
`;

export interface mUpdateVendorNdaStatusMessageRes {
  updateVendorNdaStatusMessage?: boolean;
}

export interface mUpdateVendorNdaStatusMessageVars {
  id: string;
  channel_id: string;
  status: boolean;
  url: string;
}
