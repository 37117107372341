import { gql } from '@apollo/client';
import * as Yup from 'yup';
import {
  IClientCompanyTypeEnum,
  IDowngradeFromEnterpriseWithoutRequestFormType,
} from 'types';

export const mAdminDowngradeEnterpriseAccountWithoutRequest = gql`
  mutation adminDowngradeEnterpriseAccountWithoutRequest(
    $downgradeClientFromEnterprise: AdminDowngradeEnterpriseClientWithoutRequestDto!
  ) {
    adminDowngradeEnterpriseAccountWithoutRequest(
      downgradeClientFromEnterprise: $downgradeClientFromEnterprise
    )
  }
`;

export interface mAdminDowngradeEnterpriseAccountWithoutRequestVars {
  downgradeClientFromEnterprise: IDowngradeFromEnterpriseWithoutRequestFormType;
}

export interface mAdminDowngradeEnterpriseAccountWithoutRequestRes {
  adminDowngradeEnterpriseAccountWithoutRequest?: boolean;
}

export const mAdminDowngradeEnterpriseAccountWithoutRequestInitVals: IDowngradeFromEnterpriseWithoutRequestFormType =
  {
    client_company_id: '',
    downgrade_company_type: '',
  };

export const mAdminDowngradeEnterpriseAccountWithoutRequestValidations =
  Yup.object().shape({
    client_company_id: Yup.string().required('Client company id is required!'),
    downgrade_company_type: Yup.string()
      .oneOf([IClientCompanyTypeEnum.PAYASGO, IClientCompanyTypeEnum.ANNUAL])
      .required('Company type is required!'),
  });
