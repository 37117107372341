import { gql } from '@apollo/client';

export const qValidateVendorUrl = gql`
  query validateVendorUrl($url: String!) {
    validateVendorUrl(url: $url)
  }
`;

export interface qValidateVendorUrlRes {
  validateVendorUrl: boolean;
}

export interface qValidateVendorUrlVars {
  url: string;
}
