import { gql } from '@apollo/client';
import * as Yup from 'yup';
import {
  IProjectTypeType,
  ITalentOverviewType,
  IVendorTalentBioUpdateInput,
} from 'types';
import { projectErrors } from 'errors';
import { talentOverviewString } from './getFreelancerTalentOverview.api';

export const mUpdateTalentProfileBio = gql`
  mutation updateTalentProfileBio(
    $id: ID!
    $addBioDto: UpdateFreelanceTalentOverviewDto!
  ) {
    updateTalentProfileBio(
      id: $id
      addBioDto: $addBioDto
    ) {
      ${talentOverviewString}
    }
  }
`;

export interface mUpdateTalentProfileBioRes {
  updateTalentProfileBio?: ITalentOverviewType;
}

export interface mUpdateTalentProfileBioVars {
  id: string;
  addBioDto: IVendorTalentBioUpdateInput;
}

export const mUpdateTalentProfileBioInitVals: IVendorTalentBioUpdateInput = {
  talent_name: '',
  bio: '',
  vendor_budget_range_id: '',
  talent_type_id: '',
  talent_group_ids: [],
  creative_style_ids: [],
  talent_language_ids: [],
  project_type_id: '',
  vendor_company_id: '',
};

export const mUpdateTalentProfileBioValidations = (
  pTypes: IProjectTypeType[],
) =>
  Yup.object().shape({
    talent_name: Yup.string().required('Talent name is required!'),
    bio: Yup.string().required('Bio is required!'),
    talent_type_id: Yup.string().required('Talent type is required!'),
    project_type_id: Yup.string().required('Project type is required!'),
    vendor_company_id: Yup.string(),
    talent_group_ids: Yup.array().of(Yup.string()),
    creative_style_ids: Yup.array()
      .of(Yup.string())
      .when(['project_type_id'], {
        is: (project_type_id: string) => {
          const usedPtype = pTypes.find((p) => project_type_id === p.uuid);

          if (!usedPtype?.is_more_data) return true;
          return false;
        },
        then: (sch) =>
          sch.when('project_type_id', {
            is: (project_type_id: string) =>
              pTypes.find((p) => project_type_id === p.uuid)?.unique_name ===
              'live_action_production',
            then: (schema) =>
              schema
                .min(1, 'Creative Styles are required!')
                .max(20, 'You can select up to 20 creative styles only!')
                .required(projectErrors.CREATIVE_STYLES_REQUIRED),
            otherwise: (schema) =>
              schema
                .min(1, 'Creative Styles are required!')
                .max(5, 'You can select up to 5 creative styles only!')
                .required(projectErrors.CREATIVE_STYLES_REQUIRED),
          }),
      }),
    vendor_budget_range_id: Yup.string().required('Budget range is required!'),
    talent_language_ids: Yup.array()
      .of(Yup.string())
      .min(1, 'Talent language is required!'),
  });
