import { gql } from '@apollo/client';
import { SalesRepVendorRelationTerritoryFormType } from 'components/VendorSendInviteToSalesRep/VendorSendInviteToSalesRep.component';
import * as Yup from 'yup';

export const mUpdateSalesRepInviteRelationPermissionWithNewProfiles = gql`
  mutation updateSalesRepInviteRelationPermissionWithNewProfiles(
    $id: ID!
    $profile_ids: [ID!]!
    $is_send_reel: Boolean!
    $is_reply_message: Boolean!
    $view_only: Boolean!
    $not_viewable: Boolean!
    $is_invited: Boolean!
    $relation_with_freelance: Boolean!
    $territory_info: [VendorSalesrepRalationshipChatTerritoryInput!]
    $general_market_advertising: Boolean!
    $multicultural_advertising: Boolean!
    $music_video: Boolean!
  ) {
    updateSalesRepInviteRelationPermissionWithNewProfiles(
      id: $id
      profile_ids: $profile_ids
      is_send_reel: $is_send_reel
      is_reply_message: $is_reply_message
      is_invited: $is_invited
      view_only: $view_only
      not_viewable: $not_viewable
      relation_with_freelance: $relation_with_freelance
      territory_info: $territory_info
      general_market_advertising: $general_market_advertising
      multicultural_advertising: $multicultural_advertising
      music_video: $music_video
    )
  }
`;

export interface mUpdateSalesRepInviteRelationPermissionWithNewProfilesRes {
  updateSalesRepInviteRelationPermissionWithNewProfiles?: boolean;
}

export interface mUpdateSalesRepInviteRelationPermissionWithNewProfilesVars {
  id: string;
  profile_ids: string[];
  profile_id: string;
  is_send_reel: boolean;
  is_reply_message: boolean;
  view_only?: boolean;
  not_viewable?: boolean;
  is_invited: boolean;
  relation_with_freelance: boolean;
  territory_info?: SalesRepVendorRelationTerritoryFormType[];
  territory_ids: string[];
  independent_rep_general_market_advertising?: boolean;
  independent_rep_multicultural_advertising?: boolean;
  independent_rep_music_video?: boolean;
  independent_flag?: {
    general_market_advertising?: boolean;
    multicultural_advertising?: boolean;
    music_video?: boolean;
  };
}

interface IndependentFlag {
  general_market_advertising?: boolean;
  multicultural_advertising?: boolean;
  music_video?: boolean;
}

export const validationTerritorySchema = Yup.object().shape({
  profile_id: Yup.string().required('Capability type is required!'),
  state_ids: Yup.array()
    .of(Yup.string().required('Territory is required!'))
    .min(1, 'At least one territory is required!'),
});

export const mUpdateSalesRepInviteRelationPermissionWithNewProfilesValidations =
  Yup.object().shape({
    id: Yup.string().required('Invite id is required!'),
    profile_ids: Yup.array().of(Yup.string()),
    is_send_reel: Yup.boolean().required('Reel permission is required!'),
    view_only: Yup.boolean(),
    not_viewable: Yup.boolean(),
    is_reply_message: Yup.boolean().required('Reply permission is required!'),
    is_invited: Yup.boolean().required('Invite info is required!'),
    relation_with_freelance: Yup.boolean().required('Relation by required!'),
    independent_flag: Yup.object()
      .shape({
        general_market_advertising: Yup.boolean(),
        multicultural_advertising: Yup.boolean(),
        music_video: Yup.boolean(),
      })
      .test(
        'at-least-one',
        'At least one checkbox must be selected',
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        (values) => {
          return (
            values.general_market_advertising ||
            values.multicultural_advertising ||
            values.music_video
          );
        },
      ),
    territory_ids: Yup.array().when('independent_flag', {
      is: (independentFlag: IndependentFlag) =>
        independentFlag && independentFlag.general_market_advertising === true,
      then: Yup.array()
        .of(Yup.string().required('Territory is required!'))
        .min(1, 'At least one territory is required!'),
      otherwise: Yup.array().of(Yup.string()), // Validation is skipped if general_market_advertising is false
    }),
  });
