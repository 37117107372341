import { gql } from '@apollo/client';
import * as Yup from 'yup';

export const mCreateInvoice = gql`
  mutation createInvoice(
    $createInvoiceInput: AdminCreateInvoiceClientRequestDto!
  ) {
    createInvoice(createInvoiceInput: $createInvoiceInput)
  }
`;

export interface ICreateInvoiceFormType {
  client_company_id: string;
  price: number | string;
  due_date?: string;
  attachment: string;
  auto_charge: boolean;
}

export interface mCreateInvoiceVars {
  createInvoiceInput: ICreateInvoiceFormType;
}

export interface mCreateInvoiceRes {
  createInvoice?: boolean;
}

export const mCreateInvoiceInitVals: ICreateInvoiceFormType = {
  client_company_id: '',
  price: '',
  attachment: '',
  auto_charge: false,
};

export const mCreateInvoiceValidations = Yup.object().shape({
  client_company_id: Yup.string(),
  price: Yup.number().required('Price is required!'),
  due_date: Yup.string(),
  attachment: Yup.string().required('Attachment is required!'),
  auto_charge: Yup.boolean().required('Auto chaege is required!'),
});
