import { gql } from '@apollo/client';

export const mUpdateProjectTypeHasTalentGroups = gql`
  mutation updateProjectTypeHasTalentGroups(
    $project_type_id: ID!
    $talent_group_ids: [ID!]!
  ) {
    updateProjectTypeHasTalentGroups(
      project_type_id: $project_type_id
      talent_group_ids: $talent_group_ids
    )
  }
`;

export interface mUpdateProjectTypeHasTalentGroupsVars {
  project_type_id: string;
  talent_group_ids: string[];
}

export interface mUpdateProjectTypeHasTalentGroupsRes {
  updateProjectTypeHasTalentGroups?: boolean;
}
