import { gql } from '@apollo/client';
import * as Yup from 'yup';

export const mUpdateSalesRepProfileReelLink = gql`
  mutation updateSalesRepProfileReelLink(
    $id: ID!
    $reel_link: String!
    $is_reel_upload: Boolean!
  ) {
    updateSalesRepProfileReelLink(
      id: $id
      reel_link: $reel_link
      is_reel_upload: $is_reel_upload
    )
  }
`;

export interface mUpdateSalesRepProfileReelLinkRes {
  updateSalesRepProfileReelLink?: boolean;
}

export interface mUpdateSalesRepProfileReelLinkVars {
  id: string;
  reel_link: string;
  is_reel_upload: boolean;
}

export const mUpdateSalesRepProfileReelLinkValidations = Yup.object().shape({
  id: Yup.string().required('Profile is required!'),
  reel_link: Yup.string().required('link is required!'),
  is_reel_upload: Yup.boolean(),
});

export const mUpdateSalesRepProfileReelLinkInitVals: mUpdateSalesRepProfileReelLinkVars =
  {
    id: '',
    reel_link: '',
    is_reel_upload: false,
  };
