import jwtDecode from 'jwt-decode';
import { apiUri } from 'config/config';
import { refreshTokenQueryString as query } from 'apis';
import { ACCESS_TOKEN, REFRESH_TOKEN } from 'config/constants';
import {
  getAccessToken,
  getRefreshToken,
  setRefreshToken,
  setAccessToken,
  invalidToken,
} from 'utils/token.util';

export const customFetch = async (
  url: string,
  options: RequestInit,
): Promise<Response> => {
  // get existing tokens
  const aToken = getAccessToken();
  const rToken = getRefreshToken();

  // check if it is refresh token request
  const isRefreshQuery = options.body?.toString().includes('refreshToken');

  if (!aToken || !rToken) return fetch(url, options);

  const customOptions = {
    ...options,
    headers: { ...(options.headers || {}), Authorization: aToken },
  };

  const { exp } = jwtDecode<{ exp: number }>(aToken);

  // if access token is valid & not a refresh query
  if (!invalidToken(exp) && !isRefreshQuery) return fetch(url, customOptions);

  // make a fetch request to get new access token
  const res = await fetch(apiUri, {
    method: 'POST',
    credentials: 'include',
    body: JSON.stringify({ query, variables: {} }),
    headers: {
      Authorization: rToken || '',
      'Content-Type': 'application/json; charset=utf-8',
    },
  });

  // response is valid then update tokens and send request
  if (res.status === 200) {
    const refreshToken = res.headers.get(REFRESH_TOKEN);
    const accessToken = res.headers.get(ACCESS_TOKEN);

    if (refreshToken && accessToken) {
      setRefreshToken(refreshToken);
      setAccessToken(accessToken);

      customOptions.headers.Authorization = accessToken;

      if (!isRefreshQuery) return fetch(url, customOptions);
    }
  }

  // invalid response send refresh query response
  if (isRefreshQuery) return res;

  // invalid response continue with request
  return fetch(url, options);
};
