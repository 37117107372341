export enum IS3FileType {
  IMAGE = 'IMAGE',
  DOCUMENT = 'DOCUMENT',
  VIDEO = 'VIDEO',
  AUDIO = 'AUDIO',
}

export interface IUploadFileType {
  name: string;
  url: string;
}

export interface IUploadFileTypeWithId extends IUploadFileType {
  id: string;
}

export interface IPreSignedURLType {
  fields: {
    Policy: string;
    'X-Amz-Algorithm': string;
    'X-Amz-Credential': string;
    'X-Amz-Date': string;
    'X-Amz-Signature': string;
    bucket: string;
    key: string;
  };
  url: string;
  name?: string;
}
