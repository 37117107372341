import { gql } from '@apollo/client';

export const mDeleteFavoriteVendorList = gql`
  mutation deleteFavoriteVendorList($id: ID!) {
    deleteFavoriteVendorList(id: $id)
  }
`;

export interface mDeleteFavoriteVendorListRes {
  deleteFavoriteVendorList?: boolean;
}

export interface mDeleteFavoriteVendorListVar {
  id: string;
}
