import { gql } from '@apollo/client';
import { ITeamMemberHasProjectType } from 'types';

export const qGetTeamMembersByProject = gql`
  query getTeamMembersByProject($id: String!) {
    getTeamMembersByProject(id: $id) {
      uuid
      teamMember {
        uuid
        private_user_name
        invite_email
        user {
          name
        }
      }
    }
  }
`;

export interface qGetTeamMembersByProjectRes {
  getTeamMembersByProject: ITeamMemberHasProjectType[];
}

export interface qGetTeamMembersByProjectVars {
  id: string;
}
