import { gql } from '@apollo/client';

export const mCreateVendorBudgetRange = gql`
  mutation vendorBudgetRange($vendorBudgetRangeInput: VendorBudgetRangeInput!) {
    vendorBudgetRange(vendorBudgetRangeInput: $vendorBudgetRangeInput)
  }
`;

export interface mCreateVendorBudgetRangeRes {
  vendorBudgetRange?: boolean;
}

export interface mCreateVendorBudgetRangeFormType {
  name: string;
  short_description?: string;
  long_description?: string;
  sort_order: number;
}

export interface mCreateVendorBudgetRangeVar {
  vendorBudgetRangeInput: mCreateVendorBudgetRangeFormType;
}
