import { gql } from '@apollo/client';
import { IVendorSalesrepRelationsType } from 'types/SalesrepRelationship.type';

export const qSalesRepVendorRelations = gql`
  query salesRepVendorRelations($limit: Int!, $offset: Int!) {
    salesRepVendorRelations(limit: $limit, offset: $offset) {
      total
      rows {
        uuid
        vendorCompany {
          uuid
          company_name
          company_type
          talentOverviewsCount
          owner {
            name
          }
        }
      }
    }
  }
`;

export interface qSalesRepVendorRelationsRes {
  salesRepVendorRelations?: {
    total: number;
    rows: IVendorSalesrepRelationsType[];
  };
}
