import { gql } from '@apollo/client';
import { IProjectType } from 'types';

export const qGetAdminProjects = gql`
  query getAdminProjects(
    $limit: Int!
    $offset: Int!
    $search: String
    $company_id: ID
    $sorts: [OrderByInput!]
  ) {
    getAdminProjects(
      limit: $limit
      offset: $offset
      search: $search
      company_id: $company_id
      sorts: $sorts
    ) {
      total
      rows {
        uuid
        name
        shoot_date
        submissionCount
        company {
          uuid
          company_name
          company_type
        }
      }
    }
  }
`;

export interface qGetAdminProjectsRes {
  getAdminProjects: {
    total: number;
    rows: IProjectType[];
  };
}

export interface qGetAdminProjectsVars {
  limit: number;
  offset: number;
  search?: string;
  company_id?: string;
}

export interface qGetAdminProjectsResTableDataType {
  uuid: string;
  name: string;
  shoot_date: string;
  submissionCount: number;
  company_name: string;
}
