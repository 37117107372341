import { gql } from '@apollo/client';

export const mActiveDeactiveCreativeStyle = gql`
  mutation activeDeactiveCreativeStyle($id: ID!, $status: Boolean!) {
    activeDeactiveCreativeStyle(id: $id, status: $status)
  }
`;

export interface mActiveDeactiveCreativeStyleVars {
  id: string;
  status: boolean;
}

export interface mActiveDeactiveCreativeStyleRes {
  activeDeactiveCreativeStyle?: boolean;
}
