import { gql } from '@apollo/client';
import { IStyleServiceProfileReelFormType } from 'types/StyleServiceProfile.type';

export const mUpdateStyleServiceReel = gql`
  mutation updateStyleServiceReel(
    $profileReelDto: VendorCompanyStyleServiceProfileReelDto!
    $id: ID!
  ) {
    updateStyleServiceReel(profileReelDto: $profileReelDto, id: $id)
  }
`;

export interface mUpdateStyleServiceReelRes {
  updateStyleServiceReel?: boolean;
}

export interface mUpdateStyleServiceReelVars {
  profileReelDto: IStyleServiceProfileReelFormType;
  id: string;
}
