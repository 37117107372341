import { IProjectType } from './Project.type';
import { ISubmissionVoteType } from './SubmissionVote.type';
import { ITalentOverviewProfileType } from './TalentOverview.type';
import { IUserType } from './User.type';
import {
  IVendorCompanyStyleServiceProfileType,
  IVendorCompanyType,
} from './VendorCompany.type';
import { IVendorSalesRepRelationType } from './VendorSalesRepRelation.type';

export enum ISubmissionStatus {
  Pending = 'Pending',
  Shortlisted = 'Shortlisted',
  FinalList = 'FinalList',
  Rejected = 'Rejected',
  Approved = 'Approved',
}

export interface ISubmissionType {
  uuid: string;
  project: IProjectType;
  vendorCompany: IVendorCompanyType;
  talentProfile?: ITalentOverviewProfileType | null;
  styleServiceProfile?: IVendorCompanyStyleServiceProfileType | null;
  submitBy: IUserType;
  reel_link: string;
  note: string | null;
  reel_password: string | null;
  created_at: string;
  updated_at: string;
  external_company_name: string;
  external_talent_name: string;
  is_external: boolean;
  contact_email: string;

  lastUpdateBy: IUserType | null;

  // client only
  votesCount: number;
  sumOfAllVotes: number;
  relevancy_ranking: number;
  company_relevancy_ranking?: number;
  comments: [];
  status: ISubmissionStatus;

  // client member only
  vote: ISubmissionVoteType | null;
  teamVote: ISubmissionVoteType | null;

  // team member only
  teamMemberId: string | null;
  vendorSalesRepRelation: IVendorSalesRepRelationType | null;
}
