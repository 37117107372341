import { gql } from '@apollo/client';
import { IVendorCompanyType } from 'types';

export const qValidateVendorUrlStatus = gql`
  query validateVendorUrlStatus($url: String!) {
    validateVendorUrlStatus(url: $url) {
      uuid
      status
    }
  }
`;

export interface qValidateVendorUrlStatusRes {
  validateVendorUrlStatus: IVendorCompanyType | null;
}

export interface qValidateVendorUrlStatusVars {
  url: string;
}
