import { gql } from '@apollo/client';

export const mCreateProjectType = gql`
  mutation projectType($projectTypeInput: ProjectTypeInput!) {
    projectType(projectTypeInput: $projectTypeInput)
  }
`;

export interface mCreateProjectTypeRes {
  projectType?: boolean;
}

export interface mCreateProjectTypeFormType {
  name: string;
  short_description?: string;
  long_description?: string;
  sort_order: number;
}

export interface mCreateProjectTypeVar {
  projectTypeInput: mCreateProjectTypeFormType;
}
