import { ReactElement } from 'react';
import { get } from 'lodash';
import { notification } from 'antd';
import { ArgsProps } from 'antd/lib/notification';
import { Message } from '@twilio/conversations';
import { IUploadFileTypeWithId } from 'types';
import { logError } from './logError';

export const sendError = (err: unknown): void => {
  if (typeof err === 'string') return notification.error({ message: err });

  const customErrorMessage = get(err, 'message') as unknown as string;
  if (customErrorMessage)
    return notification.error({ message: customErrorMessage });

  const message = get(
    err,
    'graphQLErrors[0].message',
    'Something went wrong!',
  ) as string;

  logError(err);

  return notification.error({ message });
};

type Msg = string | ReactElement;

export const sendMessage = (
  message: Msg,
  description?: Msg,
  duration?: number | null,
): void => {
  const args: ArgsProps = { message, duration };
  if (description) args.description = description;

  notification.success(args);
};

export const sendChatMessage = (m: Message): void => {
  const attachments = get(
    m,
    'attributes.attachments',
    [],
  ) as IUploadFileTypeWithId[];

  const message = `New Message in ${m.conversation.friendlyName || 'Chat'}`;
  const description = attachments.length
    ? `${attachments.length} attachment(s)!`
    : m.body;

  notification.success({ message, description });
};
