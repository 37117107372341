import { gql } from '@apollo/client';
import {
  IEnterpriseClientRequestStatusTypeEnum,
  IEnterpriseClientRequestType,
} from 'types';

export const qEnterpriseClientRequests = gql`
  query getEnterpriseClientRequests(
    $limit: Int!
    $offset: Int!
    $request_status: EnterpriseClientRequestStatusType
    $client_company_id: ID
    $sorts: [OrderByInput!]
  ) {
    getEnterpriseClientRequests(
      limit: $limit
      offset: $offset
      request_status: $request_status
      client_company_id: $client_company_id
      sorts: $sorts
    ) {
      total
      rows {
        uuid
        name
        email
        seats
        base_seats
        add_seats
        base_monthly
        add_monthly
        base_annual
        start_date
        renewal
        user_note
        request_status
        request_type
        company_name
        user {
          uuid
          first_name
          email
        }
        company {
          uuid
          company_name
          company_type
          billing {
            uuid
            is_auto_pay
          }
        }
      }
    }
  }
`;

export interface qGetEnterpriseClientRequestsRes {
  getEnterpriseClientRequests: {
    total: number;
    rows: IEnterpriseClientRequestType[];
  };
}

export interface qGetEnterpriseClientRequestsVars {
  limit: number;
  offset: number;
  request_status?: IEnterpriseClientRequestStatusTypeEnum;
  client_company_id?: string;
}
