import { gql } from '@apollo/client';

export const qValidatePhoneNumber = gql`
  query validatePhoneNumber($phone_number: String!) {
    validatePhoneNumber(phone_number: $phone_number)
  }
`;

export interface qValidatePhoneNumberRes {
  validatePhoneNumber: boolean;
}

export interface qValidatePhoneNumberVars {
  phone_number: string;
}
