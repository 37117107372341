import { gql } from '@apollo/client';

export const mCreateTalentType = gql`
  mutation talentType($talentTypeInput: TalentTypeInput!) {
    talentType(talentTypeInput: $talentTypeInput)
  }
`;

export interface mCreateTalentTypeRes {
  talentType?: boolean;
}

export interface mCreateTalentTypeFormType {
  name: string;
  short_description?: string;
  long_description?: string;
  sort_order: number;
}

export interface mCreateTalentTypeVar {
  talentTypeInput: mCreateTalentTypeFormType;
}
