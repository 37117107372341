import { combineReducers } from 'redux';
import { RootState } from '../types';
import { chatInitialValues, chatReducer } from './chat.reducer';

export const reducers = combineReducers<RootState>({
  chat: chatReducer,
});

export const initialState: RootState = {
  chat: chatInitialValues,
};
