import { gql } from '@apollo/client';
import {
  IVendorRelationshipChatType,
  IVendorSalesRepRelationType,
} from 'types';

export const qRelationWithSalesrep = gql`
  {
    relationWithSalesRep {
      relations {
        uuid
        is_send_reel
        is_reply_message
        view_only
        not_viewable
        relationProfileIds
        general_market_advertising
        multicultural_advertising
        music_video
        relationVendorProfiles {
          vendorProfile {
            uuid
            projectType {
              uuid
              vendor_label
            }
          }
          vendorRelation {
            uuid
          }
          state {
            uuid
            name
          }
        }
        relationFreelanceProfiles {
          vendorProfile {
            uuid
            projectType {
              uuid
            }
          }
          vendorRelation {
            uuid
          }
          state {
            uuid
            name
          }
        }
        salesrepCompany {
          company_name
          general_market_advertising
          multicultural_advertising
          music_video
        }
        vendorCompany {
          company_name
          primaryProfile {
            uuid
            projectType {
              uuid
              vendor_label
            }
          }
          profiles {
            uuid
            projectType {
              uuid
              vendor_label
            }
          }
        }
      }
      invitees {
        uuid
        is_send_reel
        is_reply_message
        view_only
        not_viewable
        general_market_advertising
        multicultural_advertising
        music_video
        invitee_vendor_company_profile_ids
        invitedProfiles {
          state {
            uuid
            name
          }
          vendorCompanyProfile {
            uuid
            projectType {
              uuid
            }
          }
          freelanceProfile {
            uuid
            projectType {
              uuid
            }
          }
        }
        inviteeCompany {
          company_name
          general_market_advertising
          multicultural_advertising
          music_video
          primaryProfile {
            uuid
            projectType {
              uuid
              vendor_label
            }
          }
          profiles {
            uuid
            projectType {
              uuid
              vendor_label
            }
          }
        }
      }
    }
  }
`;

export interface qRelationWithSalesrepRes {
  relationWithSalesRep?: {
    relations: IVendorSalesRepRelationType[];
    invitees: IVendorRelationshipChatType[];
  };
}
