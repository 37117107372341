import { createContext, ReactElement, useState } from 'react';
import { ITalentReelType } from 'types';

const defaultReelsContextValue = {
  reels: [],
  setReels(): void {},
  preview: false,
  setPreview(): void {},
};

interface ReelContext {
  reels: ITalentReelType[];
  setReels: (reels: ITalentReelType[]) => void;
  preview: boolean;
  setPreview: (preview: boolean) => void;
}

export const ReelListContext = createContext<ReelContext>(
  defaultReelsContextValue,
);

export const ReelListProvider = ({
  children,
}: {
  children: ReactElement;
}): ReactElement => {
  const [reels, setReels] = useState<ITalentReelType[]>([]);
  const [preview, setPreview] = useState<boolean>(false);

  return (
    <ReelListContext.Provider value={{ reels, setReels, preview, setPreview }}>
      {children}
    </ReelListContext.Provider>
  );
};
