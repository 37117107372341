import { gql } from '@apollo/client';
import * as Yup from 'yup';

export const mUpdateSalesRepTerritory = gql`
  mutation updateSalesRepTerritory($territory_ids: [ID!]!) {
    updateSalesRepTerritory(territory_ids: $territory_ids)
  }
`;

export interface mUpdateSalesRepTerritoryRes {
  updateSalesRepTerritory?: boolean;
}

export interface mUpdateSalesRepTerritoryVars {
  territory_ids: string[];
}

export const mUpdateSalesRepTerritoryValidations = Yup.object().shape({
  territory_ids: Yup.array()
    .of(Yup.string().required('Territory is required!'))
    .required('Territory is required!'),
});

export const mUpdateSalesRepTerritoryInitVals: mUpdateSalesRepTerritoryVars = {
  territory_ids: [],
};
