import { gql } from '@apollo/client';
import { IVendorCompanyStatusEnum, IVendorCompanyType } from 'types';

export const qGetAdminVendors = gql`
  query getAdminVendors(
    $limit: Int!
    $offset: Int!
    $sorts: [OrderByInput!]
    $searchTerm: String
  ) {
    getAdminVendors(
      limit: $limit
      offset: $offset
      sorts: $sorts
      searchTerm: $searchTerm
    ) {
      total
      rows {
        uuid
        company_name
        company_type
        created_at
        status
        profiles {
          is_primary
          projectType {
            uuid
            name
            vendor_label
            freelance_label
            client_label
            dashboard_label
          }
        }
        primaryProfile {
          projectType {
            uuid
            name
            vendor_label
            freelance_label
            client_label
            dashboard_label
          }
        }
        managerCount
        submissionCount
        owner {
          uuid
          name
        }
      }
    }
  }
`;

export interface qGetAdminVendorsRes {
  getAdminVendors: {
    total: number;
    rows: IVendorCompanyType[];
  };
}

export interface qGetAdminVendorsVars {
  limit: number;
  offset: number;
  searchTerm?: string;
}

export interface qGetAdminVendorsResTableDataType {
  uuid: string;
  company_name: string;
  status: IVendorCompanyStatusEnum | undefined;
  type: string;
  adminName: string;
  managerCount: number | undefined;
  submissionCount: number | undefined;
  createdAt: string;
}
