export interface IStateType {
  uuid: string;
  region: string;
  territory: string;
  name: string;
  abbreviation: string;
  created_at: Date;
  updated_at: Date;
}

export enum IStateTerritoryEnum {
  SOUTH_TERRITORY = 'South Territory',
  WEST_COAST_TERRITORY = 'West Coast Territory',
  EAST_COAST_TERRITORY = 'East Coast Territory',
  MIDWEST_TERRITORY = 'Midwest Territory',
}
