import { gql } from '@apollo/client';
import {
  IPaginationVars,
  IVendorCompanyType,
  IVendorCompanyTypeEnum,
} from 'types';

export const qSearchVendorCompanyByNameForFavoriteList = gql`
  query searchVendorCompanyByNameForFavoriteList(
    $limit: Int!
    $offset: Int!
    $id: ID!
    $searchTerm: String!
    $company_type: [VendorCompanyType!]
    $sorts: [OrderByInput!]
  ) {
    searchVendorCompanyByNameForFavoriteList(
      limit: $limit
      offset: $offset
      id: $id
      searchTerm: $searchTerm
      company_type: $company_type
      sorts: $sorts
    ) {
      total
      rows {
        uuid
        company_name
        company_type
        profiles {
          is_profile_completion
          is_primary
          projectType {
            name
            vendor_label
            freelance_label
            client_label
            dashboard_label
          }
        }
        primaryProfile {
          projectType {
            name
            vendor_label
            freelance_label
            client_label
            dashboard_label
          }
        }
        talentOverviews {
          uuid
          talent_name
          talent_image
          profiles {
            uuid
            is_primary
            bio
            projectType {
              uuid
              name
              vendor_label
              freelance_label
              client_label
              dashboard_label
            }
          }
          primaryProfile {
            uuid
            is_primary
            bio
            is_profile_completion
            projectType {
              uuid
              name
              vendor_label
              freelance_label
              client_label
              dashboard_label
            }
          }
        }
        companyTalentTypes
      }
    }
  }
`;

export interface qSearchVendorCompanyByNameForFavoriteListRes {
  searchVendorCompanyByNameForFavoriteList?: {
    total: number;
    rows: IVendorCompanyType[];
  };
}

export interface qSearchVendorCompanyByNameForFavoriteListVars
  extends IPaginationVars {
  id: string;
  searchTerm: string;
  company_type?: IVendorCompanyTypeEnum[];
}
