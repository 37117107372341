import { gql } from '@apollo/client';
import * as Yup from 'yup';

export const mUpgradeSeatsEnterpriseAccount = gql`
  mutation upgradeSeatsEnterpriseAccount(
    $upgradeSeatsEnterpriseAccountInput: UpgradeSeatsEnterpriseAccountInput!
  ) {
    upgradeSeatsEnterpriseAccount(
      upgradeSeatsEnterpriseAccountInput: $upgradeSeatsEnterpriseAccountInput
    )
  }
`;

export interface mUpgradeSeatsEnterpriseAccountRes {
  upgradeSeatsEnterpriseAccount?: boolean;
}

export interface mUpgradeSeatsEnterpriseAccountFormType {
  upgradeSeatsEnterpriseAccountInput: {
    user_note: string;
  };
}

export const mUpgradeSeatsEnterpriseAccountInitVals: mUpgradeSeatsEnterpriseAccountFormType =
  {
    upgradeSeatsEnterpriseAccountInput: {
      user_note: '',
    },
  };

export const mUpgradeSeatsEnterpriseAccountValidations = Yup.object().shape({
  upgradeSeatsEnterpriseAccountInput: Yup.object().shape({
    user_note: Yup.string().required('Note is required!'),
  }),
});
