import { gql } from '@apollo/client';
import * as Yup from 'yup';

export const mVendorContactUpdate = gql`
  mutation vendorContactUpdate(
    $id: ID!
    $companyContact: VendorCompanyContactDto!
  ) {
    vendorContactUpdate(id: $id, companyContact: $companyContact)
  }
`;

export interface mVendorContactUpdateRes {
  vendorContactUpdate?: boolean;
}

export interface mVendorContactUpdateFormVars {
  name: string;
  title: string;
  email: string;
  phone_number?: string;
}

export interface mVendorContactUpdateVars {
  id: string;
  companyContact: mVendorContactUpdateFormVars;
}

export const mVendorContactUpdateValidations = Yup.object().shape({
  name: Yup.string().required('Name is required!'),
  title: Yup.string().required('Job title is required!'),
  email: Yup.string()
    .trim()
    .lowercase()
    .email('Invalid email!')
    .required('Email is required!'),
  phone_number: Yup.string().matches(
    /^\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/,
    'Invalid mobile number!',
  ),
});
