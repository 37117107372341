import { gql } from '@apollo/client';
import { IVendorCompanyType } from 'types';

export const qGetAdminVendorById = gql`
  query getAdminVendorById($id: ID!) {
    getAdminVendorById(id: $id) {
      uuid
      company_name
      company_url
      company_type
      company_logo
      unclaimed_admin_name
      unclaimed_admin_email
      unclaimed_emails
      profiles {
        uuid
        company_bio
        reel_link
        is_reel_upload
        is_primary
        projectType {
          uuid
          name
          vendor_label
          freelance_label
          client_label
          dashboard_label
        }
      }
      primaryProfile {
        uuid
        company_bio
        projectType {
          uuid
          name
          vendor_label
          freelance_label
          client_label
          dashboard_label
        }
      }
      created_at
      status
      is_added_by_admin
      is_blocked
      managerCount
      submissionCount
      owner {
        uuid
        name
        email
        phone_number
      }
      contacts {
        uuid
        email
        name
        phone_number
      }
      locations {
        uuid
        mailing_address1
        zip
        city
        state {
          name
        }
      }
      talentOverviews {
        uuid
        talent_name
        talent_image
        profiles {
          uuid
          is_primary
          bio
          projectType {
            uuid
            name
            vendor_label
            freelance_label
            client_label
            dashboard_label
          }
        }
        company {
          uuid
        }
      }
      cliamRequestUser {
        user {
          uuid
          name
          email
          phone_number
        }
      }
      members {
        uuid
        name
        role
        created_at
      }
      relationalVendors {
        uuid
        created_at
        vendorCompany {
          uuid
          company_type
          company_name
          created_at
        }
      }
    }
  }
`;

export interface qGetAdminVendorByIdRes {
  getAdminVendorById?: IVendorCompanyType;
}
