import { gql } from '@apollo/client';
import { IUserType } from 'types';

export const qGetUserDataById = gql`
  query getUserDataById($id: ID!) {
    getUserDataById(id: $id) {
      uuid
      username
      first_name
      last_name
      name
      email
      phone_number
      role
      new_email
      company {
        uuid
        company_name
        company_type
      }
      vendorCompany {
        uuid
        company_name
      }
    }
  }
`;

export interface qGetUserDataByIdRes {
  getUserDataById?: IUserType;
}
