import { gql } from '@apollo/client';
import * as Yup from 'yup';

export const mRequestEnterpriseAccount = gql`
  mutation requestEnterpriseAccount(
    $requestEnterpriseAccountInput: RequestEnterpriseAccountInput!
  ) {
    requestEnterpriseAccount(
      requestEnterpriseAccountInput: $requestEnterpriseAccountInput
    )
  }
`;

export interface mRequestEnterpriseAccountRes {
  requestEnterpriseAccount?: boolean;
}

export interface mRequestEnterpriseAccountFormType {
  requestEnterpriseAccountInput: {
    user_note: string;
  };
}

export const mRequestEnterpriseAccountInitVals: mRequestEnterpriseAccountFormType =
  {
    requestEnterpriseAccountInput: {
      user_note: '',
    },
  };

export const mRequestEnterpriseAccountValidations = Yup.object().shape({
  requestEnterpriseAccountInput: Yup.object().shape({
    user_note: Yup.string().required('Note is required!'),
  }),
});
