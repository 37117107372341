import { gql } from '@apollo/client';
import { IUserType } from 'types';

export const qGetClientManagersList = gql`
  {
    getClientManagersList {
      uuid
      name
    }
  }
`;

export interface qGetClientManagersListRes {
  getClientManagersList: IUserType[] | null;
}
