import { gql } from '@apollo/client';

export const mDeleteTeamMemberProject = gql`
  mutation deleteTeamMemberProject($id: ID!) {
    deleteTeamMemberProject(id: $id)
  }
`;

export interface mDeleteTeamMemberProjectRes {
  deleteTeamMemberProject?: boolean;
}
