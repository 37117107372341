import { gql } from '@apollo/client';
import { IVendorCompanyType } from 'types';

export const qGetUniqueVendorCompanyFromFavoriteVendorListItems = gql`
  {
    getUniqueVendorCompanyFromFavoriteVendorListItems {
      uuid
      company_name
    }
  }
`;

export interface qGetUniqueVendorCompanyFromFavoriteVendorListItemsRes {
  getUniqueVendorCompanyFromFavoriteVendorListItems?: IVendorCompanyType[];
}
