import { gql } from '@apollo/client';
import { IVendorCompanyType } from 'types';

export const qGetMyVendorCompany = gql`
  query {
    getMyVendorCompany {
      uuid
      company_name
      company_url
      company_type
      company_logo
      profilePending
      is_having_freelance
      female_owned_company
      minority_owned_company
      certified_small_business
      lgbt_owned_business
      veteran_owned_company
      profiles {
        uuid
        company_bio
        reel_link
        is_reel_upload
        is_primary
        is_profile_completion
        projectType {
          uuid
          name
          vendor_label
          freelance_label
          client_label
          dashboard_label
          is_more_data
          is_project_changes
        }
        profileRelationWithSalesrep {
          states {
            uuid
            territory
            abbreviation
            name
          }
          vendorRelation {
            salesrepCompany {
              uuid
              company_name
            }
          }
        }
      }
      primaryProfile {
        uuid
        company_bio
        reel_link
        is_reel_upload
        projectType {
          uuid
          name
          vendor_label
          freelance_label
          client_label
          dashboard_label
          is_more_data
          is_project_changes
        }
      }
      rating
      locations {
        uuid
        city
        mailing_address1
        mailing_address2
        zip
        region
        state {
          uuid
          name
        }
      }
      contacts {
        uuid
        email
        title
        name
        phone_number
      }
      talentOverviews {
        uuid
        talent_name
        profiles {
          uuid
          is_primary
          is_profile_completion
          projectType {
            uuid
            name
            vendor_label
            freelance_label
            client_label
            dashboard_label
            is_more_data
          }
        }
      }
      relationalFreelancers {
        uuid
        freelanceCompany {
          uuid
          company_name
          talentOverviews {
            uuid
            talent_name
            profiles {
              uuid
              is_primary
              is_profile_completion
              projectType {
                uuid
                name
                vendor_label
                freelance_label
                client_label
                dashboard_label
              }
            }
          }
        }
      }
      primaryStyleService {
        projectType {
          uuid
        }
        talentType {
          uuid
          name
        }
      }
      additionalStyleService {
        projectType {
          uuid
        }
        creativeStyle {
          uuid
          name
        }
      }
      companyHasBudget {
        projectType {
          uuid
        }
        budgetRange {
          uuid
          name
        }
      }
      companyHasTalentGroup {
        projectType {
          uuid
        }
        talentGroup {
          uuid
          name
        }
      }
      companyHasLanguage {
        projectType {
          uuid
        }
        language {
          uuid
          name
        }
      }
      primaryStyleServiceProfiles {
        uuid
        projectType {
          uuid
          freelance_label
          is_more_data
        }
        talentType {
          uuid
          name
        }
        vendorCompany {
          uuid
          company_name
        }
      }
      additionalStyleServiceProfiles {
        uuid
        projectType {
          uuid
          freelance_label
          is_more_data
        }
        vendorCompany {
          uuid
          company_name
        }
        creativeStyle {
          uuid
          name
        }
      }
      territory {
        uuid
        name
      }
      general_market_advertising
      multicultural_advertising
      music_video
    }
  }
`;

export interface qGetMyVendorCompanyRes {
  getMyVendorCompany?: IVendorCompanyType;
}
