import { gql } from '@apollo/client';

export const mUpdateLanguage = gql`
  mutation updateLanguage(
    $id: ID!
    $name: String!
    $short_description: String
    $long_description: String
    $sort_order: Int!
  ) {
    updateLanguage(
      id: $id
      name: $name
      short_description: $short_description
      long_description: $long_description
      sort_order: $sort_order
    )
  }
`;

export interface mUpdateLanguageRes {
  updateLanguage?: boolean;
}
