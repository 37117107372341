import { gql } from '@apollo/client';
import { ITalentReelUpdateSortFormType } from 'types';

export const mUpdateStyleServiceReelSortOrder = gql`
  mutation updateStyleServiceReelSortOrder(
    $sortData: [StyleServiceProfileReelSortOrderDto!]!
  ) {
    updateStyleServiceReelSortOrder(sortData: $sortData)
  }
`;

export interface mUpdateStyleServiceReelSortOrderRes {
  updateStyleServiceReelSortOrder?: boolean;
}

export interface mUpdateStyleServiceReelSortVars {
  sortData: ITalentReelUpdateSortFormType[];
}
