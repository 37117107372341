import { gql } from '@apollo/client';
import * as Yup from 'yup';

export const mUpdateVendorProfileReelLink = gql`
  mutation updateVendorProfileReelLink(
    $id: ID!
    $reel_link: String!
    $is_reel_upload: Boolean!
  ) {
    updateVendorProfileReelLink(
      id: $id
      reel_link: $reel_link
      is_reel_upload: $is_reel_upload
    )
  }
`;

export interface mUpdateVendorProfileReelLinkRes {
  updateVendorProfileReelLink?: boolean;
}

export interface mUpdateVendorProfileReelLinkVars {
  id: string;
  reel_link: string;
  is_reel_upload: boolean;
}

export const mUpdateVendorProfileReelLinkValidations = Yup.object().shape({
  id: Yup.string().required('Profile is required!'),
  reel_link: Yup.string().required('link is required!'),
});

export const mUpdateVendorProfileReelLinkInitVals: mUpdateVendorProfileReelLinkVars =
  {
    id: '',
    reel_link: '',
    is_reel_upload: false,
  };
