import { gql } from '@apollo/client';
import * as Yup from 'yup';
import { zipValidationRequired } from 'types/Zip-validation.type';
import { IClientCompanyTypeEnum } from 'types';

export const mAdminAddEnterpriseClient = gql`
  mutation adminAddEnterpriseClient(
    $user: EnterpriseClientInputDto!
    $company: ClientCompanyDto!
    $company_billing: EnterpriseAccountSettingInputDto!
  ) {
    adminAddEnterpriseClient(
      user: $user
      company: $company
      company_billing: $company_billing
    )
  }
`;

export interface mAdminAddEnterpriseClientFormType {
  user: {
    username: string;
    email: string;
    phone_number: string;
    first_name: string;
    last_name: string;
  };
  company: {
    company_type: IClientCompanyTypeEnum | '';
    company_name: string;
    state_id: string;
    mailing_address1: string;
    mailing_address2?: string;
    city: string;
    zip: string | number;
  };
  company_billing: {
    base_seats: number | string;
    base_monthly: number | string;
    add_seats: number | string;
    add_monthly: number | string;
    base_annual: number | string;
    start_date: Date | string;
    renewal: Date | string;
  };
}

export const mAdminAddEnterpriseClientInitVals: mAdminAddEnterpriseClientFormType =
  {
    user: {
      username: '',
      first_name: '',
      last_name: '',
      email: '',
      phone_number: '',
    },
    company: {
      company_type: IClientCompanyTypeEnum.ENTERPRISE,
      company_name: '',
      mailing_address1: '',
      mailing_address2: '',
      city: '',
      zip: '',
      state_id: '',
    },
    company_billing: {
      base_seats: '',
      base_monthly: '',
      add_seats: '',
      add_monthly: '',
      base_annual: '',
      start_date: '',
      renewal: '',
    },
  };

export const mAdminAddEnterpriseClientValidations = Yup.object().shape({
  user: Yup.object().shape({
    username: Yup.string()
      .trim()
      .lowercase()
      .matches(
        /^(?=[a-zA-Z0-9._]{6,20}$)(?!.*[_.]{2})[^_.].*[^_.]$/,
        'Username not allowed spaces and should be minimum 6 character!',
      )
      .required('Username is required!'),
    first_name: Yup.string().required('First name is required!'),
    last_name: Yup.string().required('Last name is required!'),
    email: Yup.string()
      .trim()
      .lowercase()
      .email('Invalid email!')
      .required('Email is required!'),
    phone_number: Yup.string().matches(
      /^\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/,
      'Invalid mobile number!',
    ),
  }),
  company: Yup.object().shape({
    company_type: Yup.string()
      .oneOf([IClientCompanyTypeEnum.ENTERPRISE])
      .required('Company type is required!'),
    company_name: Yup.string().when('company_type', {
      is: IClientCompanyTypeEnum.ENTERPRISE,
      then: Yup.string().required(
        'Company name is required for enterprise user!',
      ),
    }),
    mailing_address1: Yup.string().required('Mailing address is required!'),
    mailing_address2: Yup.string(),
    city: Yup.string().required('City is required!'),
    zip: zipValidationRequired,
    state_id: Yup.string().required('State is required!'),
  }),
  company_billing: Yup.object().shape({
    base_seats: Yup.number()
      .typeError('Base seats must be a number!')
      .required('Base seats are required!'),
    base_monthly: Yup.string().required('Base monthly is required!'),
    add_seats: Yup.number()
      .typeError('Add seats must be a number!')
      .required('Add seats are required!'),
    add_monthly: Yup.string().required('Add monthly is required!'),
    base_annual: Yup.string().required('Base annual is required!'),
    start_date: Yup.date().required('Start date is required!'),
    renewal: Yup.date().required('Renewal date is required!'),
  }),
});

export interface mAdminAddEnterpriseClientRes {
  adminAddEnterpriseClient?: boolean;
}
