import { gql } from '@apollo/client';
import { IProjectType } from 'types';

export const qGetProjectById = gql`
  query getProjectById($id: ID!) {
    getProjectById(id: $id) {
      uuid
      name
      agency_name
      agencyLocation {
        uuid
        name
        region
        abbreviation
      }
      brand_name
      shoot_date
      end_shoot_date
      location
      is_include_post
      is_union_talent
      is_include_insurance
      is_shoot_firm
      is_location_firm
      is_include_audio
      is_include_color
      is_include_logo
      budget
      shoot_day
      spots_length
      due_date
      creative_url
      description
      vs_is_all
      vs_is_minority
      vs_is_female
      vs_is_east_coast
      vs_is_west_coast
      vs_is_veteran
      vs_is_midwest
      vs_is_freelance_talent
      vs_is_vendor_rep
      vs_is_sales_rep
      vs_states
      vs_favorite_vendor_lists
      vendorLists {
        uuid
        name
        company {
          uuid
          company_name
        }
      }
      stateLists {
        uuid
        name
      }
      talentType {
        uuid
        name
      }
      talentGroups {
        uuid
        name
      }
      clientBudgetRange {
        uuid
        name
        live_label
        other_label
      }
      creativeStyles {
        uuid
        name
      }
      languages {
        uuid
        name
      }
      projectType {
        uuid
        name
        client_label
        vendor_label
        freelance_label
        is_more_data
        is_project_changes
      }
      is_anonymous
      project_status
      nda {
        uuid
        name
        ndaFile {
          name
          url
        }
      }
      creativeScript {
        uuid
        name
        url
      }
      rep_type
    }
  }
`;

export interface qGetProjectByIdRes {
  getProjectById?: IProjectType;
}
