import { gql } from '@apollo/client';
import { ISubmissionStatus } from 'types';

export const mUpdateSubmissionStatus = gql`
  mutation updateSubmissionStatus($uuid: ID!, $status: SubmissionStatus!) {
    updateSubmissionStatus(uuid: $uuid, status: $status)
  }
`;

export interface mUpdateSubmissionStatusRes {
  updateSubmissionStatus?: boolean;
}

export interface mUpdateSubmissionStatusVars {
  uuid: string;
  status: ISubmissionStatus;
}
