import { gql } from '@apollo/client';
import * as Yup from 'yup';
import { IProjectTypeType, IUpdateVendorTalentOverviewFormType } from 'types';
import { projectErrors } from 'errors';

export const mUpdateTalentOverview = gql`
  mutation updateTalentOverview(
    $talentOverviewDto: UpdateTalentOverviewDto!
    $id: ID!
  ) {
    updateTalentOverview(talentOverviewDto: $talentOverviewDto, id: $id)
  }
`;

export interface mUpdateTalentOverviewRes {
  updateTalentOverview?: boolean;
}

export interface mUpdateTalentOverviewVars {
  talentOverviewDto: IUpdateVendorTalentOverviewFormType;
  id: string;
}

export const mVendorUpdateTalentOverviewInitVals: IUpdateVendorTalentOverviewFormType =
  {};

export const mUpdateTalentOverviewValidations = (
  isMaxLength: boolean,
  pTypes?: IProjectTypeType[],
) =>
  Yup.object().shape({
    talent_name: Yup.string(),
    talent_image: Yup.string(),
    bio: Yup.string(),
    talent_type_id: Yup.array()
      .of(Yup.string())
      .min(1, projectErrors.TALENT_TYPE_REQUIRED)
      .max(2, projectErrors.TALENT_TYPE_REQUIRED)
      .required(projectErrors.TALENT_TYPE_REQUIRED),
    talent_group_ids: Yup.array().of(Yup.string()),
    creative_style_ids: Yup.array()
      .of(Yup.string())
      .when(['project_type_id'], {
        is: (talent_type_id: string) => {
          if (talent_type_id && !isMaxLength) return true;
          return false;
        },
        then: (sch) =>
          sch.when('project_type_id', {
            is: (project_type_id: string) =>
              pTypes && pTypes?.length > 0
                ? pTypes.find((p) => project_type_id === p.uuid)
                    ?.unique_name === 'live_action_production'
                : false,
            then: (schema) =>
              schema
                .min(1, projectErrors.CREATIVE_STYLES_MIN_LENGTH)
                .max(20, 'Max 20 creative styles can be choose!')
                .required(projectErrors.CREATIVE_STYLES_REQUIRED),
            otherwise: (schema) =>
              schema
                .min(1, projectErrors.CREATIVE_STYLES_MIN_LENGTH)
                .max(5, projectErrors.CREATIVE_STYLES_MAX_LENGTH)
                .required(projectErrors.CREATIVE_STYLES_REQUIRED),
          }),
      }),
    vendor_budget_range_id: Yup.string(),
    talent_language_ids: Yup.array().of(Yup.string()),
    online_reel_url: Yup.string().matches(
      /^((http|https):\/\/)(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#-]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?\/?$/gm,
      'Invalid reel url!',
    ),
  });
