import { gql } from '@apollo/client';
import * as Yup from 'yup';
import { IUpdateVendorCompanyFormType } from 'types';

export const mAdminUpdateVendor = gql`
  mutation adminUpdateVendor(
    $updateVendorCompany: AdminUpdateVendorCompanyDto!
  ) {
    adminUpdateVendor(updateVendorCompany: $updateVendorCompany)
  }
`;

export interface mAdminUpdateVendorRes {
  adminUpdateVendor?: boolean;
}

export interface mAdminUpdateVendorVars {
  updateVendorCompany: IUpdateVendorCompanyFormType;
}

export const mAdminUpdateVendorValidations = Yup.object().shape({
  vendor_company_id: Yup.string().required('Company id is required!'),
  company_name: Yup.string().trim().required('Company name is required!'),
  company_project_type: Yup.string().required('Company type is required!'),
  company_url: Yup.string().matches(
    /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?\/?$/gm,
    'Invalid company url!',
  ),
  company_bio: Yup.string().trim(),
  company_logo: Yup.string().url('Invalid logo!'),
  unclaimed_emails: Yup.array().of(Yup.string()),
});
