import { gql } from '@apollo/client';

export const mDeleteClientManagerByID = gql`
  mutation deleteClientManagerByID($id: ID!, $isInvited: Boolean!) {
    deleteClientManagerByID(id: $id, isInvited: $isInvited)
  }
`;

export interface mDeleteClientManagerByIDVars {
  id: string;
  isInvited: boolean;
}

export interface mDeleteClientManagerByIDRes {
  deleteClientManagerByID?: boolean;
}
