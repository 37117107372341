import { gql } from '@apollo/client';
import { IProjectType, ITeamMemberType } from 'types';

export const qGetMyTeamMembers = gql`
  query {
    getTeamMembers {
      uuid
      private_user_name
      invite_email
      created_at
      user {
        name
      }
      projects {
        uuid
        project {
          uuid
          name
        }
      }
    }

    getTeamMemberInvites {
      uuid
      email
      created_at
      project {
        uuid
        name
      }
    }
  }
`;

export interface ITeamMemberInviteType {
  uuid: string;
  email: string;
  created_at: Date;
  project: IProjectType;
}

export interface qGetMyTeamMembersRes {
  getTeamMembers?: ITeamMemberType[];
  getTeamMemberInvites?: ITeamMemberInviteType[];
}
