import { gql } from '@apollo/client';
import * as Yup from 'yup';

export const mTeamMemberInviteToSubmissions = gql`
  mutation teamMemberInviteToSubmissions($inviteInput: TeamMemberInviteDto!) {
    teamMemberInviteToSubmissions(inviteInput: $inviteInput)
  }
`;

export interface mTeamMemberInviteToSubmissionsRes {
  teamMemberInviteToSubmissions?: boolean;
}

export interface mTeamMemberInviteToSubmissionsFormVars {
  project_id?: string;
  emails: string[];
  existing_users: string[];
  subject: string;
  note: string;
}

export interface mTeamMemberInviteToSubmissionsVars {
  inviteInput: mTeamMemberInviteToSubmissionsFormVars;
}

export const mTeamMemberInviteToSubmissionsInitVals: mTeamMemberInviteToSubmissionsFormVars =
  {
    project_id: '',
    emails: [],
    existing_users: [],
    subject: '',
    note: '',
  };

export const mTeamMemberInviteToSubmissionsValidations = Yup.object().shape({
  emails: Yup.array().of(Yup.string().email('Invalid email!')),
  existing_users: Yup.array().of(Yup.string()),
  subject: Yup.string(),
  note: Yup.string().required('Note is required!'),
});
