import { gql } from '@apollo/client';
import { IEnterpriseClientRequestType } from 'types';

export const qGetEnterpriseClientRequestById = gql`
  query getEnterpriseClientRequestById($id: ID!) {
    getEnterpriseClientRequestById(id: $id) {
      uuid
      name
      email
      base_seats
      add_seats
      base_monthly
      add_monthly
      base_annual
      start_date
      renewal
      admin_remarks
      phone_number
      user_note
      request_status
      request_type
      company_name
      user {
        uuid
        first_name
      }
      company {
        uuid
        company_name
        company_type
        billing {
          seats
        }
      }
    }
  }
`;

export interface qGetEnterpriseClientRequestByIdRes {
  getEnterpriseClientRequestById?: IEnterpriseClientRequestType;
}
