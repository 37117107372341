import { gql } from '@apollo/client';
import { ITalentOverviewProfileType } from 'types';
import { talentOverviewString } from './getFreelancerTalentOverview.api';

export const qGetTalentOverviewProfileById = gql`
  query talentOverviewProfileById($id: ID!) {
    talentOverviewProfileById(id: $id) {
      uuid
      bio
      online_reel_url
      is_primary
      next_unlock_date
      last_update_date
      created_at
      talentOverview {
        ${talentOverviewString}
      }
      projectType {
        uuid
        vendor_label
        freelance_label
        client_label
        dashboard_label
      }
      reels {
        uuid
        name
        video_code
        sort_order
      }
      talentGroups {
        uuid
        name
      }
      creativeStyles {
        uuid
        name
      }
      languages {
        uuid
        name
      }
      talentType {
        uuid
        name
      }
      vendorBudgetRange {
        uuid
        name
      }
    }
  }
`;

export interface qGetTalentOverviewProfileByIdRes {
  talentOverviewProfileById?: ITalentOverviewProfileType;
}
