import { gql } from '@apollo/client';
import { IS3FileType } from 'types';

export const mGetPresignedURLPublicPut = gql`
  mutation getPresignedURLPublicPut(
    $getPresignedURLInput: GetPresignedURLInput!
  ) {
    getPresignedURLPublicPut(getPresignedURLInput: $getPresignedURLInput)
  }
`;

export interface mGetPresignedURLPublicPutRes {
  getPresignedURLPublicPut?: string;
}

export interface mGetPresignedURLPublicPutVar {
  getPresignedURLInput: {
    name: string;
    size: number;
    mimetype: string;
    fileTypes: IS3FileType[];
  };
}
