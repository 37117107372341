import { gql } from '@apollo/client';
import { IUserType } from 'types';

export const qMyProfile = gql`
  {
    myProfile {
      uuid
      username
      name
      role
      first_name
      last_name
      email
      phone_number
      is_newsletter_subscribe
      emails {
        uuid
        email
        new_email
        is_primary
      }
      phone_numbers {
        uuid
        phone_number
        new_phone_number
        is_primary
      }
      role
      vendorCompany {
        uuid
        company_name
        profiles {
          uuid
          projectType {
            uuid
            name
            vendor_label
            freelance_label
            client_label
            dashboard_label
          }
        }
        locations {
          uuid
          mailing_address1
          mailing_address2
          city
          zip
          state {
            uuid
            name
          }
        }
      }
      company {
        uuid
        company_name
        company_type
        mailing_address1
        mailing_address2
        city
        zip
        state {
          uuid
          name
        }
        billing {
          uuid
          base_seats
          add_seats
          is_auto_pay
        }
        admins {
          uuid
          first_name
          last_name
        }
      }
    }
  }
`;

export interface qMyProfileRes {
  myProfile?: IUserType;
}
