import { gql } from '@apollo/client';
import * as Yup from 'yup';
import { IClientCompanyTypeEnum } from 'types';
import { zipValidationRequired } from 'types/Zip-validation.type';
import { passwordErrors } from 'errors';

export const mRegisterClient = gql`
  mutation registerClient(
    $user: UserInputDto!
    $company: ClientCompanyDto!
    $payment_method: String
  ) {
    registerClient(
      user: $user
      company: $company
      payment_method: $payment_method
    )
  }
`;

export interface mRegisterClientRes {
  registerClient?: boolean;
}

export interface mRegisterClientFormType {
  user: {
    username: string;
    email: string;
    phone_number: string;
    first_name: string;
    last_name: string;
    password: string;
    rpassword?: string;
  };
  company: {
    company_type: IClientCompanyTypeEnum | '';
    company_name: string;
    state_id: string;
    mailing_address1: string;
    mailing_address2?: string;
    city: string;
    zip: string | number;
  };
  payment_method?: string;
}

export const mRegisterClientInitVals: mRegisterClientFormType = {
  user: {
    username: '',
    password: '',
    rpassword: '',
    first_name: '',
    last_name: '',
    email: '',
    phone_number: '',
  },
  company: {
    company_type: '',
    company_name: '',
    mailing_address1: '',
    mailing_address2: '',
    city: '',
    zip: '',
    state_id: '',
  },
  payment_method: '',
};

export const mRegisterClientValidations = Yup.object().shape({
  user: Yup.object().shape({
    username: Yup.string()
      .trim()
      .lowercase()
      .matches(
        /^(?=[a-zA-Z0-9._]{6,20}$)(?!.*[_.]{2})[^_.].*[^_.]$/,
        'Username not allowed spaces and should be minimum 6 character!',
      )
      .required('Username is required!'),
    password: Yup.string()
      .matches(
        /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/,
        passwordErrors.PASSWORD_VALIDATION,
      )
      .required(passwordErrors.PASSWORD_REQUIRED),
    rpassword: Yup.string()
      .oneOf([Yup.ref('password')], 'Password do not match!')
      .required('Password confirmation is required!'),
    first_name: Yup.string().required('First name is required!'),
    last_name: Yup.string().required('Last name is required!'),
    email: Yup.string()
      .trim()
      .lowercase()
      .email('Invalid email!')
      .required('Email is required!'),
    phone_number: Yup.string().matches(
      /^\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/,
      'Invalid mobile number!',
    ),
  }),
  company: Yup.object().shape({
    company_type: Yup.string()
      .oneOf([
        IClientCompanyTypeEnum.PAYASGO,
        IClientCompanyTypeEnum.ANNUAL,
        IClientCompanyTypeEnum.ENTERPRISE,
      ])
      .required('Company type is required!'),
    company_name: Yup.string().when('company_type', {
      is: IClientCompanyTypeEnum.ENTERPRISE,
      then: Yup.string().required(
        'Company name is required for enterprise user!',
      ),
    }),
    mailing_address1: Yup.string().required('Mailing address is required!'),
    mailing_address2: Yup.string(),
    city: Yup.string().required('City is required!'),
    zip: zipValidationRequired,
    state_id: Yup.string().required('State is required!'),
  }),
  payment_method: Yup.string(),
});
