import { gql } from '@apollo/client';

export const mActiveDeactiveTalentGroup = gql`
  mutation activeDeactiveTalentGroup($id: ID!, $status: Boolean!) {
    activeDeactiveTalentGroup(id: $id, status: $status)
  }
`;

export interface mActiveDeactiveTalentGroupVars {
  id: string;
  status: boolean;
}

export interface mActiveDeactiveTalentGroupRes {
  activeDeactiveTalentGroup?: boolean;
}
