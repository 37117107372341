import { gql } from '@apollo/client';
import * as Yup from 'yup';
import { IAuthUserType } from 'types';
import { passwordErrors } from 'errors';
import { userAttributesString } from './refreshToken.api';

export const mRegisterClientManager = gql`
  mutation registerClientManager(
    $registerClientManagerInput: RegisterClientManagerInput!
  ) {
    registerClientManager(
      registerClientManagerInput: $registerClientManagerInput
    ) ${userAttributesString}
  }
`;

export interface mRegisterClientManagerRes {
  registerClientManager?: IAuthUserType;
}

export interface mRegisterClientManagerFormType {
  token: string;
  username: string;
  phone_number: string;
  first_name: string;
  last_name: string;
  password: string;
  rpassword?: string;
}

export interface mRegisterClientManagerVar {
  registerClientManagerInput: mRegisterClientManagerFormType;
}

export const mRegisterClientManagerInitVals: mRegisterClientManagerFormType = {
  username: '',
  password: '',
  rpassword: '',
  first_name: '',
  last_name: '',
  token: '',
  phone_number: '',
};

export const mRegisterClientManagerValidations = Yup.object().shape({
  username: Yup.string()
    .trim()
    .lowercase()
    .matches(
      /^(?=[a-zA-Z0-9._]{6,20}$)(?!.*[_.]{2})[^_.].*[^_.]$/,
      'Username not allowed spaces and should be minimum 6 character!',
    )
    .required('Username is required!'),
  password: Yup.string()
    .matches(
      /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/,
      passwordErrors.PASSWORD_VALIDATION,
    )
    .required(passwordErrors.PASSWORD_REQUIRED),
  rpassword: Yup.string()
    .oneOf([Yup.ref('password')], 'Password do not match!')
    .required('Password confirmation is required!'),
  first_name: Yup.string().required('First name is required!'),
  last_name: Yup.string().required('Last name is required!'),
  phone_number: Yup.string().matches(
    /^\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/,
    'Invalid mobile number!',
  ),
});
