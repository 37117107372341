import { gql } from '@apollo/client';

export const mDeleteBillingCard = gql`
  mutation deleteBillingCard($id: ID!) {
    deleteBillingCard(id: $id)
  }
`;

export interface mDeleteBillingCardRes {
  deleteBillingCard?: boolean;
}

export interface mDeleteBillingCardVars {
  id: string;
}
