import { gql } from '@apollo/client';
import { IVendorNDAType } from 'types';

export const qVendorNDAs = gql`
  query vendorNDAs($limit: Int!, $offset: Int!, $sorts: [OrderByInput!]) {
    vendorNDAs(limit: $limit, offset: $offset, sorts: $sorts) {
      total
      pending_ndas
      rows {
        uuid
        name
        status
        created_at
        approved_at
        updated_at
        awaitingApprovalCount
        ndaFile {
          name
          url
          created_at
        }
        signedNda {
          name
          url
          created_at
        }
        company {
          uuid
          company_name
        }
      }
    }
  }
`;

export interface qVendorNDAsRes {
  vendorNDAs?: {
    total: number;
    pending_ndas: number;
    rows: IVendorNDAType[];
  };
}
