import { gql } from '@apollo/client';

export const mDeleteTeamMemberFromScreeningRoom = gql`
  mutation deleteTeamMemberFromScreeningRoom(
    $project_id: ID!
    $member_id: ID!
  ) {
    deleteTeamMemberFromScreeningRoom(
      project_id: $project_id
      member_id: $member_id
    )
  }
`;

export interface mDeleteTeamMemberFromScreeningRoomRes {
  deleteTeamMemberFromScreeningRoom?: boolean;
}

export interface mDeleteTeamMemberFromScreeningRoomVar {
  project_id: string;
  member_id: string;
}
