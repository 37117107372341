import { gql } from '@apollo/client';

export const qGetTwilioAccessToken = gql`
  mutation getTwilioAccessToken($identity: String!) {
    getTwilioAccessToken(identity: $identity)
  }
`;

export interface qGetTwilioAccessTokenRes {
  getTwilioAccessToken?: string;
}

export interface qGetTwilioAccessTokenVars {
  identity: string;
}
