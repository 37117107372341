import { gql } from '@apollo/client';
import { IVendorFreelanceRelationType } from 'types';

export const qRelationWithVendorByClient = gql`
  query relationWithVendorByClient($id: ID!) {
    relationWithVendorByClient(id: $id) {
      relations {
        uuid
        relationProfileIds
        vendorCompany {
          uuid
          company_name
          company_logo
          primaryProfile {
            projectType {
              name
              vendor_label
              freelance_label
              client_label
              dashboard_label
            }
          }
          profiles {
            uuid
            projectType {
              uuid
              name
              vendor_label
              freelance_label
              client_label
              dashboard_label
            }
          }
          owner {
            name
          }
        }
      }
    }
  }
`;

export interface qRelationWithVendorByClientRes {
  relationWithVendorByClient?: {
    relations: IVendorFreelanceRelationType[];
  };
}
