import { gql } from '@apollo/client';

export const mCreateTalentGroup = gql`
  mutation talentGroup($talentGroupInput: TalentGroupInput!) {
    talentGroup(talentGroupInput: $talentGroupInput)
  }
`;

export interface mCreateTalentGroupRes {
  talentGroup?: boolean;
}

export interface mCreateTalentGroupFormType {
  name: string;
  short_description?: string;
  long_description?: string;
  sort_order: number;
}

export interface mCreateTalentGroupVar {
  talentGroupInput: mCreateTalentGroupFormType;
}
