import { gql } from '@apollo/client';
import {
  IPaginationVars,
  ISubmissionType,
  ISubmissionVoteType,
  IVendorCompanyTypeEnum,
} from 'types';

export const qGetSubmissionsByProjectIdClient = gql`
  query getSubmissionsByProjectIdClient(
    $id: ID!
    $limit: Int!
    $offset: Int!
    $status: String
    $sorts: [OrderByInput!]
    $filters: GetSubmissionWithFiltersInputDto!
    $pagination: Boolean!
  ) {
    getSubmissionsByProjectIdClient(
      id: $id
      limit: $limit
      offset: $offset
      status: $status
      sorts: $sorts
      filters: $filters
      pagination: $pagination
    ) {
      total
      rows {
        uuid
        sumOfAllVotes
        reel_link
        external_talent_name
        external_company_name
        contact_email
        is_external
        note
        reel_password
        relevancy_ranking
        company_relevancy_ranking
        created_at
        status
        vote {
          uuid
          vote
        }
        vendorCompany {
          uuid
          company_name
          company_type
        }
        project {
          name
          projectType {
            uuid
            name
          }
          talentType {
            uuid
            name
          }
          clientBudgetRange {
            uuid
            name
            vendorBudgetRanges {
              uuid
              name
            }
          }
          languages {
            uuid
            name
          }
          talentGroups {
            uuid
            name
          }
          creativeStyles {
            uuid
            name
          }
          company {
            uuid
          }
        }
        talentProfile {
          bio
          is_primary
          projectType {
            name
            vendor_label
            freelance_label
            client_label
            dashboard_label
          }
          talentType {
            uuid
            name
          }
          vendorBudgetRange {
            uuid
            name
          }
          talentGroups {
            uuid
            name
          }
          creativeStyles {
            uuid
            name
          }
          languages {
            uuid
            name
          }
          talentOverview {
            uuid
            talent_image
            rating
            talent_name
            company {
              uuid
              company_name
              company_type
            }
          }
        }
        styleServiceProfile {
          uuid
          talentType {
            uuid
            name
          }
          creativeStyle {
            uuid
            name
          }
          vendorCompany {
            uuid
            company_name
            company_type
            profiles {
              uuid
              company_bio
              projectType {
                uuid
                name
              }
            }
            primaryStyleService {
              talentType {
                uuid
                name
              }
              projectType {
                uuid
              }
            }
            additionalStyleService {
              creativeStyle {
                uuid
                name
              }
              projectType {
                uuid
              }
            }
            companyHasBudget {
              projectType {
                uuid
              }
              budgetRange {
                uuid
                name
              }
            }
            companyHasLanguage {
              projectType {
                uuid
              }
              language {
                uuid
                name
              }
            }
            companyHasTalentGroup {
              projectType {
                uuid
              }
              talentGroup {
                uuid
                name
              }
            }
          }
        }
      }
    }
  }
`;

export interface qGetSubmissionsByProjectIdClientRes {
  getSubmissionsByProjectIdClient?: {
    total: number;
    rows: ISubmissionType[];
  };
}

export interface GetSubmissionWithFiltersInputDto {
  vendor_budget_range_id?: string | number;
  talent_type_id?: string | number;
  talent_group_ids?: string[];
  language_ids?: string[];
  creative_style_ids?: string[];
  is_freelance?: boolean;
  female_owned_company?: boolean;
  veteran_owned_company?: boolean;
  minority_owned_company?: boolean;
}

export interface qGetSubmissionsByProjectIdClientVars extends IPaginationVars {
  id: string;
  status?: string;
  filters?: GetSubmissionWithFiltersInputDto;
  pagination?: boolean;
}

export interface qGetSubmissionsByProjectIdClientColumnType {
  uuid: string;
  name: string;
  talent_id: string;
  companyUUID: string;
  companyName: string;
  companyType: IVendorCompanyTypeEnum;
  reelLink: string;
  relevancy: string;
  skill: string;
  group: string;
  projectTypeId: string;
  talentType: string;
  creativeStyles: string;
  groups: string;
  budget: string;
  languages: string;
  ranking: string;
  rating?: number;
  bio: string;
  note: string;
  reel_password: string;
  status: string;
  talent_image: string;
  totalVotes?: number;
  isTalent?: boolean;
  is_external?: boolean;

  variableStatus?: string;

  // client member only
  vote?: ISubmissionVoteType | null;
  client_company_id: string;

  // team member only
  teamMemberId?: string | null;
}
