import { gql } from '@apollo/client';
import { ITalentRatingType } from 'types';

export const qMyRatingToTalent = gql`
  query myRatingToTalent($id: ID!) {
    myRatingToTalent(id: $id) {
      uuid
      rating
    }
  }
`;

export interface qMyRatingToTalentRes {
  myRatingToTalent?: ITalentRatingType;
}
