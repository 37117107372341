import { gql } from '@apollo/client';

export const mApproveShareListChat = gql`
  mutation approveShareListChat(
    $id: ID!
    $channel_id: ID!
    $list_name: String!
  ) {
    approveShareListChat(
      id: $id
      channel_id: $channel_id
      list_name: $list_name
    )
  }
`;

export interface mApproveShareListChatRes {
  approveShareListChat?: boolean;
}

export interface mApproveShareListChatVar {
  id: string;
  channel_id: string;
  list_name: string;
}
