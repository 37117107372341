import { gql } from '@apollo/client';

export const mAddSubmissionComment = gql`
  mutation addSubmissionComment($id: ID!, $comment: String!) {
    addSubmissionComment(id: $id, comment: $comment)
  }
`;

export interface mAddSubmissionCommentRes {
  addSubmissionComment?: boolean;
}

export interface mAddSubmissionCommentVars {
  id: string;
  comment: string;
}
