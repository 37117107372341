import { gql } from '@apollo/client';
import { IProjectType } from 'types';

export const qGetClientProjects = gql`
  query getProjectsByClient(
    $limit: Int!
    $offset: Int!
    $sorts: [OrderByInput!]
  ) {
    getProjectsByClient(limit: $limit, offset: $offset, sorts: $sorts) {
      total
      rows {
        uuid
        name
        created_at
        due_date
        submissionCount
        project_status
        is_anonymous
        agency_name
        projectType {
          uuid
          name
          client_label
          dashboard_label
        }
        author {
          name
          role
        }
        company {
          uuid
          company_name
        }
        rep_type
      }
    }
  }
`;

export interface qGetClientProjectsRes {
  getProjectsByClient?: {
    total: number;
    rows: IProjectType[];
  };
}
