import { gql } from '@apollo/client';
import * as Yup from 'yup';

export const mApproveAllAwaitingNdas = gql`
  mutation approveAllAwaitingNdas($id: ID!) {
    approveAllAwaitingNdas(id: $id)
  }
`;

export interface mApproveAllAwaitingNdasRes {
  approveAllAwaitingNdas?: boolean;
}

export interface mApproveAllAwaitingNdasVar {
  id: string;
}

export const mApproveAllAwaitingNdasInitVals: mApproveAllAwaitingNdasVar = {
  id: '',
};

export const mApproveAllAwaitingNdasInitValidations = Yup.object().shape({
  id: Yup.string().required('Nda is required!'),
});
