import { gql } from '@apollo/client';
import * as Yup from 'yup';
import { IUpdateEnterpriseClientWithoutRequestFormType } from 'types';

export const mUpgradeNonEnterpriseToEnterpriseWithoutRequest = gql`
  mutation upgradeNonEnterpriseToEnterpriseWithoutRequest(
    $upgradeClientToEnterprise: AdminUpgradeEnterpriseClientWithoutRequestDto!
  ) {
    upgradeNonEnterpriseToEnterpriseWithoutRequest(
      upgradeClientToEnterprise: $upgradeClientToEnterprise
    )
  }
`;

export interface mUpgradeNonEnterpriseToEnterpriseWithoutRequestVars {
  upgradeClientToEnterprise: IUpdateEnterpriseClientWithoutRequestFormType;
}

export interface mUpgradeNonEnterpriseToEnterpriseWithoutRequestRes {
  upgradeNonEnterpriseToEnterpriseWithoutRequest?: boolean;
}

export const mUpgradeNonEnterpriseToEnterpriseWithoutRequestInitVals: IUpdateEnterpriseClientWithoutRequestFormType =
  {
    client_company_id: '',
    base_seats: '',
    base_annual: '',
    base_monthly: '',
    renewal: '',
  };

export const mUpgradeNonEnterpriseToEnterpriseWithoutRequestValidations =
  Yup.object().shape({
    client_company_id: Yup.string().required('Client company id is required!'),
    base_seats: Yup.number().required('Base Seats are required!'),
    base_monthly: Yup.number().required('Base monthly is required!'),
    base_annual: Yup.number().required('Base annual is required!'),
    start_date: Yup.date(),
    renewal: Yup.date().required('Renewal date is required!'),
  });
