import { gql } from '@apollo/client';

export const mCancelRelationWithSalesrepByFreelancer = gql`
  mutation cancelRelationWithSalesrepByFreelancer(
    $isInvited: Boolean!
    $profile_id: ID!
    $relation_id: ID!
  ) {
    cancelRelationWithSalesrepByFreelancer(
      isInvited: $isInvited
      profile_id: $profile_id
      relation_id: $relation_id
    )
  }
`;

export interface mCancelRelationWithSalesrepByFreelancerRes {
  cancelRelationWithSalesrepByFreelancer?: boolean;
}

export interface mCancelRelationWithSalesrepByFreelancerVars {
  isInvited: boolean;
  profile_id: string;
  relation_id: string;
}
