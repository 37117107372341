import { gql } from '@apollo/client';
import * as Yup from 'yup';
import {
  IAddMoreSeatsEnterpriseClientRequestFormType,
  IEnterpriseClientRequestStatusTypeEnum,
} from 'types';

export const mAddMoreSeatToEnterprise = gql`
  mutation addMoreSeatToEnterprise(
    $moreSeatRequest: AdminAddMoreSeatRequestDto!
  ) {
    addMoreSeatToEnterprise(moreSeatRequest: $moreSeatRequest)
  }
`;

export interface mAddMoreSeatToEnterpriseVars {
  moreSeatRequest: IAddMoreSeatsEnterpriseClientRequestFormType;
}

export interface mAddMoreSeatToEnterpriseRes {
  addMoreSeatToEnterprise?: boolean;
}

export const mAddMoreSeatToEnterpriseInitVals: IAddMoreSeatsEnterpriseClientRequestFormType =
  {
    request_id: '',
    request_status: '',
    add_seats: '',
    base_seats: '',
    admin_remarks: '',
  };

export const mAddMoreSeatToEnterpriseValidations = Yup.object().shape({
  request_id: Yup.string(),
  request_status: Yup.string()
    .oneOf([
      IEnterpriseClientRequestStatusTypeEnum.Approve,
      IEnterpriseClientRequestStatusTypeEnum.Reject,
    ])
    .required('Status is required!'),
  add_seats: Yup.number().when('request_status', {
    is: IEnterpriseClientRequestStatusTypeEnum.Approve,
    then: Yup.number().required('Additional Seats are required!'),
  }),
  base_seats: Yup.number().when('request_status', {
    is: IEnterpriseClientRequestStatusTypeEnum.Approve,
    then: Yup.number().required('Base Seats are required!'),
  }),
  base_monthly: Yup.number().when('request_status', {
    is: IEnterpriseClientRequestStatusTypeEnum.Approve,
    then: Yup.number().required('Base monthly is required!'),
  }),
  add_monthly: Yup.number().when('request_status', {
    is: IEnterpriseClientRequestStatusTypeEnum.Approve,
    then: Yup.number().required('Add monthly is required!'),
  }),
  base_annual: Yup.number().when('request_status', {
    is: IEnterpriseClientRequestStatusTypeEnum.Approve,
    then: Yup.number().required('Base annual is required!'),
  }),
  admin_remarks: Yup.string(),
});
