import { gql } from '@apollo/client';

export const mRemoveTalentOverview = gql`
  mutation removeTalentOverview($id: ID!) {
    removeTalentOverview(id: $id)
  }
`;

export interface mRemoveTalentOverviewRes {
  removeTalentOverview?: boolean;
}

export interface mRemoveTalentOverviewVars {
  id: string;
}
