import { gql } from '@apollo/client';

export const mUpdateProjectTypeHasTalentTypes = gql`
  mutation updateProjectTypeHasTalentTypes(
    $project_type_id: ID!
    $talent_type_ids: [ID!]!
  ) {
    updateProjectTypeHasTalentTypes(
      project_type_id: $project_type_id
      talent_type_ids: $talent_type_ids
    )
  }
`;

export interface mUpdateProjectTypeHasTalentTypesVars {
  project_type_id: string;
  talent_type_ids: string[];
}

export interface mUpdateProjectTypeHasTalentTypesRes {
  updateProjectTypeHasTalentTypes?: boolean;
}
