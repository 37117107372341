import { gql } from '@apollo/client';
import { IVendorCompanyType } from 'types';

export const qGetUniqueVendorCompanyFromFavoriteVendorListIds = gql`
  query getUniqueVendorCompanyFromFavoriteVendorListIds($ids: [ID!]!) {
    getUniqueVendorCompanyFromFavoriteVendorListIds(ids: $ids) {
      uuid
      company_name
    }
  }
`;

export interface qGetUniqueVendorCompanyFromFavoriteVendorListIdsRes {
  getUniqueVendorCompanyFromFavoriteVendorListIds?: IVendorCompanyType[];
}

export interface qGetUniqueVendorCompanyFromFavoriteVendorListIdsVars {
  ids: string[];
}
