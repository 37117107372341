import {
  ApolloClient,
  HttpLink,
  from,
  InMemoryCache,
  ApolloLink,
} from '@apollo/client';
import { apiUri as uri } from 'config/config';
import { REFRESH_TOKEN, ACCESS_TOKEN } from 'config/constants';
import { setRefreshToken, setAccessToken } from 'utils/token.util';
import { RetryLink } from '@apollo/client/link/retry';
import { customFetch } from './customFetch';

interface ContextType {
  response: {
    headers: {
      get: (key: string) => string;
    };
  };
}

const httpLink = new HttpLink({
  uri,
  credentials: 'include',
  fetch: customFetch,
});

const afterwareLink = new ApolloLink((operation, forward) =>
  forward(operation).map((response) => {
    const context = operation.getContext() as ContextType;
    const {
      response: { headers },
    } = context;

    if (headers) {
      const refreshToken = headers.get(REFRESH_TOKEN);
      const accessToken = headers.get(ACCESS_TOKEN);

      if (refreshToken) setRefreshToken(refreshToken);
      if (accessToken) setAccessToken(accessToken);
    }

    return response;
  }),
);

const retryLink = new RetryLink({ attempts: { max: 3 } });

const httpLinkWithMiddleware = afterwareLink.concat(httpLink);

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: from([retryLink, httpLinkWithMiddleware]),
});

export { client };
