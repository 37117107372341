import { gql } from '@apollo/client';

export const qValidateUsername = gql`
  query validateUsername($username: String!) {
    validateUsername(username: $username)
  }
`;

export interface qValidateUsernameRes {
  validateUsername: boolean;
}

export interface qValidateUsernameVars {
  username: string;
}
