import { gql } from '@apollo/client';
import { INDAVendorStatus } from 'types';

export const mNdaVendorStatusByClient = gql`
  mutation ndaVendorStatusByClient($id: ID!, $status: String!) {
    ndaVendorStatusByClient(id: $id, status: $status)
  }
`;

export interface mNdaVendorStatusByClientRes {
  ndaVendorStatusByClient?: boolean;
}

export interface mNdaVendorStatusByClientVars {
  id: string;
  status: INDAVendorStatus;
}
