import { gql } from '@apollo/client';
import { userAttributesString } from 'apis/auth';
import { IAuthUserType } from 'types';
import * as Yup from 'yup';

export const mAddProfileCapabilities = gql`
  mutation addProfileCapabilities($ids: [ID!]!, $is_having_freelance: Boolean) {
    addProfileCapabilities(ids: $ids, is_having_freelance: $is_having_freelance) ${userAttributesString}
  }
`;

export interface mAddProfileCapabilitiesRes {
  addProfileCapabilities?: IAuthUserType;
}

export interface mAddProfileCapabilitiesVars {
  ids: string[];
  is_having_freelance?: boolean;
}

export const mAddProfileCapabilitiesValidations = Yup.object().shape({
  ids: Yup.array()
    .of(Yup.string().required('Capability is required!'))
    .min(1, 'Minimum one capability is required!'),
  is_having_freelance: Yup.bool(),
});
