import { gql } from '@apollo/client';

export const qDBEnumTypes = gql`
  {
    getDBTypes {
      regions
      projectStatus
    }
  }
`;

export interface qDBEnumTypesRes {
  getDBTypes?: {
    regions: string[];
    projectStatus: string[];
  };
}
