import { gql } from '@apollo/client';
import { IClientBudgetRangeType } from 'types';

export const qClientBudgetRangeById = gql`
  query clientBudgetRangeById($id: ID!) {
    clientBudgetRangeById(id: $id) {
      uuid
      name
      short_description
      long_description
      sort_order
      vendor_budget_range_ids
      vendorBudgetRanges {
        uuid
        name
      }
    }
  }
`;

export interface qClientBudgetRangeByIdRes {
  clientBudgetRangeById?: IClientBudgetRangeType;
}
