import { gql } from '@apollo/client';

export const mUpgradeVendorManagerToAdmin = gql`
  mutation upgradeVendorManagerToAdmin($id: ID!) {
    upgradeVendorManagerToAdmin(id: $id)
  }
`;

export interface mUpgradeVendorManagerToAdminRes {
  upgradeVendorManagerToAdmin?: boolean;
}
