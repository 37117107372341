import { gql } from '@apollo/client';

export const mMergeTeamMemberAccount = gql`
  mutation mergeTeamMemberAccount($token: String!) {
    mergeTeamMemberAccount(token: $token)
  }
`;

export interface mMergeTeamMemberAccountRes {
  mergeTeamMemberAccount?: boolean;
}

export interface mMergeTeamMemberAccountVar {
  token: string;
}
