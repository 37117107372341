import { gql } from '@apollo/client';
import * as Yup from 'yup';
import { IFreelanceTalentOverviewUpdateInput } from 'types';
import { projectErrors } from 'errors';

export const mUpdateFreelanceTalentOverview = gql`
  mutation updateFreelanceTalentOverview(
    $id: ID!
    $talentOverviewDto: UpdateFreelanceTalentOverviewDto!
  ) {
    updateFreelanceTalentOverview(
      id: $id
      talentOverviewDto: $talentOverviewDto
    )
  }
`;

export interface mUpdateFreelanceTalentOverviewRes {
  updateFreelanceTalentOverview?: boolean;
}

export interface mUpdateFreelanceTalentOverviewVars {
  id: string;
  talentOverviewDto: IFreelanceTalentOverviewUpdateInput;
}

export const mUpdateFreelanceTalentOverviewValidations = (
  is_more_data: boolean,
) =>
  Yup.object().shape({
    talent_image: Yup.string().url('Invalid talent image!'),
    bio: Yup.string().required('Bio is required!'),
    talent_type_id: Yup.string().required('Talent type is required!'),
    talent_group_ids: Yup.array().of(Yup.string()),
    creative_style_ids: Yup.array()
      .of(Yup.string())
      .when(['talent_type_id'], {
        is: (talent_type_id: string) => {
          if (!is_more_data && talent_type_id) return true;
          return false;
        },
        then: (sch) =>
          sch
            .min(1, 'Creative Styles are required!')
            .max(5, 'You can select up to 5 creative styles only!')
            .required(projectErrors.CREATIVE_STYLES_REQUIRED),
      }),
    talent_language_ids: Yup.array()
      .of(Yup.string())
      .min(1, 'Talent language is required!'),
    vendor_budget_range_id: Yup.string().required('Budget range is required!'),
  });
