import { gql } from '@apollo/client';

export const mDeleteTeamMember = gql`
  mutation deleteTeamMember($id: ID!, $isInvited: Boolean!) {
    deleteTeamMember(id: $id, isInvited: $isInvited)
  }
`;

export interface mDeleteTeamMemberRes {
  deleteTeamMember?: boolean;
}

export interface mDeleteTeamMemberVar {
  id: string;
  isInvited: boolean;
}
