import { gql } from '@apollo/client';
import { mAddTeamMemberVotePrivateLinkVars } from './addTeamMemberVotePrivateLink.api';

export const mUpdateTeamMemberVotePrivateLink = gql`
  mutation updateTeamMemberVotePrivateLink(
    $id: ID!
    $vote: Int!
    $team_project_id: ID!
  ) {
    updateTeamMemberVotePrivateLink(
      id: $id
      vote: $vote
      team_project_id: $team_project_id
    )
  }
`;

export interface mUpdateTeamMemberVotePrivateLinkRes {
  updateTeamMemberVotePrivateLink?: boolean;
}

export type mUpdateTeamMemberVotePrivateLinkVars =
  mAddTeamMemberVotePrivateLinkVars;
