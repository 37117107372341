import { gql } from '@apollo/client';

export const mUpdateVendorRelationshipOrInvite = gql`
  mutation updateVendorRelationshipOrInvite(
    $id: ID!
    $profile_ids: [ID!]!
    $is_invited: Boolean!
  ) {
    updateVendorRelationshipOrInvite(
      id: $id
      profile_ids: $profile_ids
      is_invited: $is_invited
    )
  }
`;

export interface mUpdateVendorRelationshipOrInviteRes {
  updateVendorRelationshipOrInvite?: boolean;
}

export interface mUpdateVendorRelationshipOrInviteVars {
  id: string;
  profile_ids: string[];
  is_invited: boolean;
}
