import { gql } from '@apollo/client';
import { IUserRole, IUserType } from 'types';

export const qGetVendorManagersList = gql`
  {
    getVendorManagersList {
      managers {
        uuid
        name
        username
        email
        role
        is_reel_access
        is_nda_access
        is_reply_access
        is_talent_access
        vendorCompany {
          company_name
          company_type
        }
      }
      invitees {
        uuid
        name
        email
        is_reel_access
        is_nda_access
        is_reply_access
        is_talent_access
      }
    }
  }
`;

export interface qGetVendorManagersListRes {
  getVendorManagersList: {
    managers: IUserType[];
    invitees: {
      uuid: string;
      name: string;
      email: string;
      is_reel_access: boolean;
      is_nda_access: boolean;
      is_reply_access: boolean;
      is_talent_access: boolean;
    }[];
  };
}

export interface ISeatType {
  index?: number;
  uuid: string;
  name: string;
  username: string;
  email: string;
  isInvited: boolean;
  isOpen: boolean;
  role?: IUserRole;
}

export interface IManagerPermissioinType {
  index?: number;
  uuid: string;
  name: string;
  username: string;
  email: string;
  isInvited: boolean;
  isOpen: boolean;
  role?: IUserRole;
  is_reel_access: boolean;
  is_nda_access: boolean;
  is_reply_access: boolean;
  is_talent_access: boolean;
}
