import { gql } from '@apollo/client';

export const mUpdateProjectTypeHasCreativeStyles = gql`
  mutation updateProjectTypeHasCreativeStyles(
    $project_type_id: ID!
    $creative_style_ids: [ID!]!
  ) {
    updateProjectTypeHasCreativeStyles(
      project_type_id: $project_type_id
      creative_style_ids: $creative_style_ids
    )
  }
`;

export interface mUpdateProjectTypeHasCreativeStylesVars {
  project_type_id: string;
  creative_style_ids: string[];
}

export interface mUpdateProjectTypeHasCreativeStylesRes {
  updateProjectTypeHasCreativeStyles?: boolean;
}
