import { gql } from '@apollo/client';

export const mCreateLanguage = gql`
  mutation language($languageInput: LanguageInput!) {
    language(languageInput: $languageInput)
  }
`;

export interface mCreateLanguageRes {
  language?: boolean;
}

export interface mCreateLanguageFormType {
  name: string;
  short_description?: string;
  long_description?: string;
  sort_order: number;
}

export interface mCreateLanguageVar {
  languageInput: mCreateLanguageFormType;
}
