import { gql } from '@apollo/client';
import { IPaginationVars, IVendorProjectType } from 'types';

export const qGetProjectsByVendor = gql`
  query getProjectsByVendor(
    $limit: Int!
    $offset: Int!
    $due_filter: Boolean
    $sorts: [OrderByInput!]
  ) {
    getProjectsByVendor(
      limit: $limit
      offset: $offset
      sorts: $sorts
      due_filter: $due_filter
    ) {
      total
      rows {
        uuid
        name
        agency_name
        due_date
        project_status
        projectType {
          uuid
          name
          vendor_label
          dashboard_label
        }
        is_anonymous
        vendor_nda_status
        is_new
        is_opened
        created_at
        updated_at
        submission_date
        submission_by
        nda {
          uuid
          name
          ndaFile {
            name
            url
            created_at
          }
          company {
            uuid
            company_name
          }
        }
        company {
          company_name
        }
        author {
          name
        }
        rep_type
      }
    }
  }
`;

export interface qGetProjectsByVendorRes {
  getProjectsByVendor?: {
    total: number;
    rows: IVendorProjectType[];
  };
}

export interface qGetProjectsByVendorVars extends IPaginationVars {
  due_filter: boolean;
}
