import { gql } from '@apollo/client';
import { IStateType } from 'types';

export const qGetStates = gql`
  {
    states {
      uuid
      name
      region
      territory
    }
  }
`;

export interface qGetStatesRes {
  states?: IStateType[];
}
