import { gql } from '@apollo/client';

export const mActiveDeactiveClientBudgetRange = gql`
  mutation activeDeactiveClientBudgetRange($id: ID!, $status: Boolean!) {
    activeDeactiveClientBudgetRange(id: $id, status: $status)
  }
`;

export interface mActiveDeactiveClientBudgetRangeVars {
  id: string;
  status: boolean;
}

export interface mActiveDeactiveClientBudgetRangeRes {
  activeDeactiveClientBudgetRange?: boolean;
}
