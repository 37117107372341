import { createContext, ReactElement, ReactNode, useState } from 'react';
import { UploadFile } from 'antd/lib/upload/interface';

type IFilesList = Record<string, UploadFile[]>;

interface UploadFileListContext {
  filesList: IFilesList;
  setFiles: (key: string, filesList: UploadFile[]) => void;
  emptyFiles: () => void;
}

const defaultUserContextValue: UploadFileListContext = {
  filesList: {},
  emptyFiles(): void {},
  setFiles(): void {},
};

export const UploadFileContext = createContext<UploadFileListContext>(
  defaultUserContextValue,
);

export const UploadFileProvider = ({
  children,
}: {
  children: ReactNode;
}): ReactElement => {
  const [filesList, setFilesList] = useState<IFilesList>({});

  const emptyFiles = () => setFilesList({});

  const setFiles = (key: string, files: UploadFile[]) =>
    setFilesList({
      ...filesList,
      [key]: files,
    });

  return (
    <UploadFileContext.Provider value={{ filesList, setFiles, emptyFiles }}>
      {children}
    </UploadFileContext.Provider>
  );
};
