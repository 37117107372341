import { gql } from '@apollo/client';
import {
  IPaginationVars,
  IVendorCompanyType,
  IVendorCompanyTypeEnum,
} from 'types';

export const qSearchVendorCompanyByName = gql`
  query searchVendorCompanyByName(
    $limit: Int!
    $offset: Int!
    $searchTerm: String!
    $company_type: [VendorCompanyType!]
  ) {
    searchVendorCompanyByName(
      limit: $limit
      offset: $offset
      searchTerm: $searchTerm
      company_type: $company_type
    ) {
      total
      rows {
        uuid
        company_name
        companyTalentTypes
      }
    }
  }
`;

export interface qSearchVendorCompanyByNameRes {
  searchVendorCompanyByName?: {
    total: number;
    rows: IVendorCompanyType[];
  };
}

export interface qSearchVendorCompanyByNameVars extends IPaginationVars {
  searchTerm: string;
  company_type?: IVendorCompanyTypeEnum[];
}
