import { gql } from '@apollo/client';
import { talentOverviewString } from 'apis/talentOverview';
import { ITalentOverviewType } from 'types';

export const qGetVendorTalentById = gql`
  query getVendorTalentById(
    $id: ID!
  ) {
    getVendorTalentById(
      id: $id
    ) {
      ${talentOverviewString}
    }
  }
`;

export interface qGetVendorTalentByIdRes {
  getVendorTalentById?: ITalentOverviewType;
}
