import { gql } from '@apollo/client';

export const qClientNDAUsersByNDAId = gql`
  query clientNDAUsersByNDAId($id: ID!) {
    clientNDAUsersByNDAId(id: $id)
  }
`;

export interface qClientNDAUsersByNDAIdRes {
  clientNDAUsersByNDAId?: string[];
}
