import { gql } from '@apollo/client';
import { ITalentOverviewType } from 'types';
import { talentOverviewString } from './getFreelancerTalentOverview.api';

export const qGetTalentOverviewById = gql`
  query talentOverviewById($id: ID!) {
    talentOverviewById(id: $id) {
      ${talentOverviewString}
    }
  }
`;

export interface qGetTalentOverviewByIdRes {
  talentOverviewById?: ITalentOverviewType;
}
