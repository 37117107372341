import { gql } from '@apollo/client';
import { ITalentOverviewType } from 'types';

export const talentOverviewString = `
  uuid
  talent_image
  talent_name
  allCurrentProjectCount
  allShortListProjectCount
  allTotalProjectCount
  company {
    uuid
    company_logo
    company_name
    relationalVendors {
      vendorCompany {
        uuid
        company_logo
        company_name
      }
    }
    owner {
      uuid
      name
    }
    profiles {
      uuid
      projectType {
        uuid
        name
        vendor_label
        freelance_label
        client_label
        dashboard_label
        is_more_data
      }
      profileRelationWithSalesrep {
        states {
          uuid
          territory
          abbreviation
          name
        }
        vendorRelation {
          salesrepCompany {
            uuid
            company_name
          }
        }
      }
    }
  }
  profiles {
    uuid
    is_profile_completion
    is_primary
    is_hidden
    is_deleted
    bio
    online_reel_url
    next_unlock_date
    last_update_date
    projectType {
      uuid
      name
      vendor_label
      freelance_label
      client_label
      dashboard_label
      is_more_data
      unique_name
    }
    profileRelationWithSalesrep {
      states {
        uuid
        territory
        abbreviation
        name
      }
      vendorRelation {
        salesrepCompany {
          uuid
          company_name
        }
      }
    }
    reels {
      uuid
      name
      video_code
      sort_order
    }
    talentGroups {
      uuid
      name
    }
    creativeStyles {
      uuid
      name
    }
    languages {
      uuid
      name
    }
    talentType {
      uuid
      name
    }
    vendorBudgetRange {
      uuid
      name
    }
  }
  primaryProfile {
    uuid
    is_profile_completion
    is_primary
    is_hidden
    is_deleted
    bio
    online_reel_url
    next_unlock_date
    last_update_date
    projectType {
      uuid
      name
      vendor_label
      freelance_label
      client_label
      dashboard_label
    }
    reels {
      uuid
      name
      video_code
      sort_order
    }
    talentGroups {
      uuid
      name
    }
    creativeStyles {
      uuid
      name
    }
    languages {
      uuid
      name
    }
    talentType {
      uuid
      name
    }
    vendorBudgetRange {
      uuid
      name
    }
  }
  rating
`;

export const qGetFreelancerTalentOverview = gql`
  query {
    getFreelancerTalentOverview {
      ${talentOverviewString}
    }
  }
`;

export interface qGetFreelancerTalentOverviewRes {
  getFreelancerTalentOverview?: ITalentOverviewType;
}
