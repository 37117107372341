import { gql } from '@apollo/client';
import { IClientBudgetRangeType } from 'types';

export const qClientBudgetRangesForAdmin = gql`
  {
    clientBudgetRangesForAdmin {
      uuid
      name
      unique_name
      short_description
      long_description
      sort_order
      is_active
      vendorBudgetRanges {
        uuid
        name
      }
    }
  }
`;

export interface qClientBudgetRangesForAdminRes {
  clientBudgetRangesForAdmin?: IClientBudgetRangeType[];
}
