import { gql } from '@apollo/client';

export const mUpdateVendorProfile = gql`
  mutation updateVendorProfile($profile: UserVendorProfileInput!) {
    updateVendorProfile(profile: $profile)
  }
`;

export interface mUpdateVendorProfileRes {
  updateVendorProfile?: boolean;
}

export interface mUpdateVendorProfileFormVars {
  first_name: string;
  last_name: string;
  profile_pic?: string;
  location_id?: string;
  company_id?: string;
  company_name?: string;
  mailing_address1?: string;
  mailing_address2?: string;
  city?: string;
  state_id?: string;
  state_name?: string;
  zip?: number | string;
}

export interface mUpdateVendorProfileVars {
  profile: mUpdateVendorProfileFormVars;
}
