import { gql } from '@apollo/client';
import { mVendorContactUpdateFormVars } from './vendorContactUpdate.api';

export const mVendorContactAdd = gql`
  mutation vendorContactAdd($companyContact: VendorCompanyContactDto!) {
    vendorContactAdd(companyContact: $companyContact)
  }
`;

export interface mVendorContactAddRes {
  vendorContactAdd?: boolean;
}

export interface mVendorContactAddVars {
  companyContact: mVendorContactUpdateFormVars;
}

export const mVendorContactAddInitVals: mVendorContactUpdateFormVars = {
  name: '',
  title: '',
  email: '',
};
