import { gql } from '@apollo/client';
import { INameUuidType } from 'types';

export const qLanguages = gql`
  query languages($project_type_id: ID) {
    languages(project_type_id: $project_type_id) {
      uuid
      name
    }
  }
`;

export interface qLanguagesRes {
  languages?: INameUuidType[];
}

export interface qLanguagesVar {
  project_type_id?: string;
}
