export const PROJECT_TYPE_REQUIRED = 'Project type is required!';

export const PROJECT_NAME_REQUIRED = 'Project name is required!';
export const AGENCY_NAME_REQUIRED = 'Agency name is required!';
export const AGENCY_LOCATION_REQUIRED = 'Creative location is required!';
export const BRAND_NAME_REQUIRED = 'Brand name is required!';
export const LOCATION_REQUIRED = 'Location name is required!';
export const SHOOT_DATE_REQUIRED = 'Shoot date is required!';
export const SHOOT_END_DATE_REQUIRED = 'Shoot End date is required!';
export const SHOOT_DAYS_REQUIRED = 'Shoot days are required!';
export const BUDGET_RANGE_REQUIRED = 'Budget range is required!';
export const DESCRIPTION_REQUIRED = 'Description is required!';
export const SPOTS_LENGTH_REQUIRED = 'Spots length is required!';
export const DUE_DATE_REQUIRED = 'Due date is required!';
export const LANGUAGE_REQUIRED = 'Language is required!';
export const BUDGET_REQUIRED = 'Budget info is required!';
export const TALENT_TYPE_REQUIRED = 'Talent type is required!';
export const CREATIVE_STYLES_REQUIRED = 'Creative styles are required!';
export const CREATIVE_STYLES_MIN_LENGTH = 'Creative styles are required!';
export const CREATIVE_STYLES_MAX_LENGTH =
  'Max five creative styles can be choose!';
export const SKILL_TYPE_REQUIRED = 'Skill type is required!';
export const EXPERIENCE_LEVEL_REQUIRED = 'Experience level is required!';

export const TALENT_GROUP_REQUIRED = 'Talent group is required!';

export const CREATIVE_URL_INVALID = 'Invalid creative url!';

export const REP_TYPE_REQUIRED = 'Relationship type is required!';
