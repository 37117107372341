import { gql } from '@apollo/client';
import * as Yup from 'yup';

export const mUpdateVendorManagerPermission = gql`
  mutation updateVendorManagerPermission(
    $inviteInput: UpdateManagerInvitePermissionDto!
  ) {
    updateVendorManagerPermission(inviteInput: $inviteInput)
  }
`;

export interface mUpdateVendorManagerPermissionRes {
  updateVendorManagerPermission?: boolean;
}

export interface mUpdateVendorManagerPermissionFormVars {
  id: string;
  is_invited: boolean;
  is_reel_access: boolean;
  is_nda_access: boolean;
  is_reply_access: boolean;
  is_talent_access: boolean;
}

export interface mUpdateVendorManagerPermissionVars {
  inviteInput: mUpdateVendorManagerPermissionFormVars;
}

export const mUpdateVendorManagerPermissionValidations = Yup.object().shape({
  id: Yup.string().required('Id is required!'),
  is_invited: Yup.boolean().required(),
  is_reel_access: Yup.boolean().required(),
  is_nda_access: Yup.boolean().required(),
  is_reply_access: Yup.boolean().required(),
  is_talent_access: Yup.boolean().required(),
});

export const mUpdateVendorManagerPermissionInitVals: mUpdateVendorManagerPermissionFormVars =
  {
    id: '',
    is_invited: false,
    is_reel_access: false,
    is_nda_access: false,
    is_reply_access: false,
    is_talent_access: false,
  };
