import { gql } from '@apollo/client';
import {
  ITalentOverviewProfileType,
  IVendorCompanyStyleServiceProfileType,
} from 'types';

export const qGetTalentOverviewsForSubmissions = gql`
  query getTalentOverviewsForSubmissions($id: ID!) {
    getTalentOverviewsForSubmissions(id: $id) {
      uuid
      relevancy_ranking
      projectType {
        uuid
        vendor_label
      }
      talentOverview {
        talent_name
        company {
          uuid
        }
      }
    }

    getStyleServiceProfilesForSubmissions(id: $id) {
      uuid
      relevancy_ranking
      talentType {
        uuid
        name
      }
      creativeStyle {
        uuid
        name
      }
      vendorCompany {
        uuid
        company_name
      }
      projectType {
        uuid
        vendor_label
      }
    }
  }
`;

export interface qGetTalentOverviewsForSubmissionsRes {
  getTalentOverviewsForSubmissions?: ITalentOverviewProfileType[];
  getStyleServiceProfilesForSubmissions?: IVendorCompanyStyleServiceProfileType[];
}
