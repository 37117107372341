import { gql } from '@apollo/client';
import { IUpdateClientCompanyFormType } from 'types';

export const mAdminUpdateClient = gql`
  mutation adminUpdateClient($updateClient: AdminUpdateClientDto!) {
    adminUpdateClient(updateClient: $updateClient)
  }
`;

export interface mAdminUpdateClientVars {
  updateClient: IUpdateClientCompanyFormType;
}

export interface mAdminUpdateClientRes {
  adminUpdateClient?: boolean;
}
