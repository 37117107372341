import { gql } from '@apollo/client';
import * as Yup from 'yup';
import { IAuthUserType } from 'types';
import { passwordErrors } from 'errors';
import { userAttributesString } from './refreshToken.api';

export const mRegisterVendorManager = gql`
  mutation registerVendorManager(
    $registerVendorManagerInput: RegisterVendorManagerInput!
  ) {
    registerVendorManager(
      registerVendorManagerInput: $registerVendorManagerInput
    ) ${userAttributesString}
  }
`;

export interface mRegisterVendorManagerRes {
  registerVendorManager?: IAuthUserType;
}

export interface mRegisterVendorManagerFormType {
  token: string;
  username: string;
  phone_number: string;
  first_name: string;
  last_name: string;
  password: string;
  rpassword?: string;
}

export interface mRegisterVendorManagerVar {
  registerVendorManagerInput: mRegisterVendorManagerFormType;
}

export const mRegisterVendorManagerInitVals: mRegisterVendorManagerFormType = {
  username: '',
  password: '',
  rpassword: '',
  first_name: '',
  last_name: '',
  token: '',
  phone_number: '',
};

export const mRegisterVendorManagerValidations = Yup.object().shape({
  username: Yup.string()
    .trim()
    .lowercase()
    .matches(
      /^(?=[a-zA-Z0-9._]{6,20}$)(?!.*[_.]{2})[^_.].*[^_.]$/,
      'Username not allowed spaces and should be minimum 6 character!',
    )
    .required('Username is required!'),
  password: Yup.string()
    .matches(
      /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/,
      passwordErrors.PASSWORD_VALIDATION,
    )
    .required(passwordErrors.PASSWORD_REQUIRED),
  rpassword: Yup.string()
    .oneOf([Yup.ref('password')], 'Password do not match!')
    .required('Password confirmation is required!'),
  first_name: Yup.string().required('First name is required!'),
  last_name: Yup.string().required('Last name is required!'),
  phone_number: Yup.string().matches(
    /^\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/,
    'Invalid mobile number!',
  ),
});
