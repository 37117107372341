import { gql } from '@apollo/client';
import { IClientCompanyBillingCardType } from 'types';

export const qGetBillingCards = gql`
  {
    getBillingCards {
      uuid
      brand
      nick_name
      exp_month
      exp_year
      last4
      is_default
      billingInfo {
        uuid
        autorenew
      }
    }
  }
`;

export interface qGetBillingCardsRes {
  getBillingCards?: IClientCompanyBillingCardType[];
}
