import { gql } from '@apollo/client';

export const mUpdateDefaultBillingCard = gql`
  mutation updateDefaultBillingCard($id: ID!) {
    updateDefaultBillingCard(id: $id)
  }
`;

export interface mUpdateDefaultBillingCardRes {
  updateDefaultBillingCard?: boolean;
}

export interface mUpdateDefaultBillingCardVars {
  id: string;
}
