import * as env from 'env-var';

export const apiUri: string = env
  .get('REACT_APP_API_URI')
  .required()
  .asString();

export const bucketName: string = env
  .get('REACT_APP_AWS_S3_BUCKET_NAME')
  .required()
  .asString();

export const stripePublicKey: string = env
  .get('REACT_APP_STRIPE_PUBLIC_KEY')
  .required()
  .asString();

export const isProduction = process.env.NODE_ENV === 'production';
