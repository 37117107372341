import { LastMessage } from '@twilio/conversations';
import dayjs from 'dayjs';
import { uniqBy } from 'lodash';
import { customError, sendChatMessage } from 'helpers';
import { IRoutes } from 'config/constants';
import { Action, ActionType, ChatState, IChatType } from '../types';

export const chatInitialValues: ChatState = {
  connectionStatus: 'unknown',
  chats: [],
  activeChatSid: '',
};

const sortChats = (chats: IChatType[]) =>
  uniqBy(
    chats
      .map((c) => {
        const messages = uniqBy(c.messages, 'sid').sort(
          (a, b) => b.index - a.index,
        );

        return { ...c, messages };
      })
      .sort(
        (a, b) =>
          dayjs(
            b?.lastMessage?.dateCreated || b.conversation.dateUpdated,
          ).unix() -
          dayjs(
            a?.lastMessage?.dateCreated || a.conversation.dateUpdated,
          ).unix(),
      ),
    'sid',
  );

export const chatReducer = (
  state = chatInitialValues,
  action: Action,
): ChatState => {
  switch (action.type) {
    case ActionType.SET_CONNECTION_STATE: {
      return { ...state, connectionStatus: action.payload };
    }

    case ActionType.LOGOUT_CHAT: {
      return chatInitialValues;
    }

    case ActionType.ADD_CHAT: {
      const chats = sortChats([...state.chats, action.payload]);
      return { ...state, chats };
    }

    case ActionType.UPDATE_CHAT: {
      const { chatSid, paginator } = action.payload;
      const index = state.chats.findIndex((c) => c.sid === chatSid);
      if (index < 0) return { ...state };

      const chats = [...state.chats];
      const chat = chats[index];
      chat.messages = [...chat.messages, ...paginator.items];
      chat.paginator = paginator;

      chats[index] = chat;

      return { ...state, chats: sortChats(chats) };
    }

    case ActionType.SET_ACTIVE_CHAT: {
      const updatedState = { ...state, activeChatSid: action.payload };

      const index = updatedState.chats.findIndex(
        (c) => c.sid === action.payload,
      );
      if (index < 0) return updatedState;

      updatedState.chats[index].unreadMessagesCount = 0;

      updatedState.chats[index].conversation
        .updateLastReadMessageIndex(
          updatedState.chats[index].conversation.lastMessage?.index || 0,
        )
        .then(() => {})
        .catch(() => {});
      return updatedState;
    }

    case ActionType.ADD_MESSAGE: {
      const { message, sendNotification } = action.payload;

      const chatSid = message?.conversation?.sid;
      const chatIndex = state.chats.findIndex((c) => c.sid === chatSid);

      if (chatIndex < 0) throw customError('Unable to save payment method!'); // return state;

      const messages = [...(state.chats[chatIndex].messages ?? []), message];

      const lastMessage: LastMessage = {
        index: message.index,
        dateCreated: message.dateCreated || undefined,
      };

      const chatsCopy = [...state.chats];
      const unreadMessagesCount =
        chatSid !== state.activeChatSid
          ? chatsCopy[chatIndex].unreadMessagesCount + 1
          : chatsCopy[chatIndex].unreadMessagesCount;

      if (
        chatSid !== state.activeChatSid &&
        window.location.pathname !== IRoutes.Dashboard_Message_Center
      )
        if (sendNotification) sendChatMessage(message);

      chatsCopy[chatIndex] = {
        ...chatsCopy[chatIndex],
        messages,
        lastMessage,
        unreadMessagesCount,
      };

      return { ...state, chats: sortChats(chatsCopy) };
    }

    default:
      return state;
  }
};
