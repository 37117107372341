import { gql } from '@apollo/client';
import { ISettingDataType } from 'types';

export const qTalentTypeById = gql`
  query talentTypeById($id: ID!) {
    talentTypeById(id: $id) {
      uuid
      name
      short_description
      long_description
      sort_order
    }
  }
`;

export interface qTalentTypeByIdRes {
  talentTypeById?: ISettingDataType;
}
