import { gql } from '@apollo/client';
import { IRegionType } from 'types';
import { zipValidationRequired } from 'types/Zip-validation.type';
import * as Yup from 'yup';

export const mVendorLocationUpdate = gql`
  mutation vendorLocationUpdate(
    $id: ID!
    $companyLocation: VendorCompanyLocationDto!
  ) {
    vendorLocationUpdate(id: $id, companyLocation: $companyLocation)
  }
`;

export interface mVendorLocationUpdateRes {
  vendorLocationUpdate?: boolean;
}

export interface mVendorLocationUpdateFormVars {
  state_id: string;
  region: IRegionType | string;
  mailing_address1: string;
  mailing_address2?: string;
  city: string;
  zip: string;
}

export interface mVendorLocationUpdateVars {
  id: string;
  companyLocation: mVendorLocationUpdateFormVars;
}

export const mVendorLocationUpdateValidations = Yup.object().shape({
  state_id: Yup.string().required('State is required!'),
  region: Yup.string().required('State is required!'),
  mailing_address1: Yup.string().required('Mailing address is required!'),
  mailing_address2: Yup.string(),
  city: Yup.string().required('City is required!'),
  zip: zipValidationRequired,
});
