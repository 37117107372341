import { gql } from '@apollo/client';

export const mActiveDeactiveLanguage = gql`
  mutation activeDeactiveLanguage($id: ID!, $status: Boolean!) {
    activeDeactiveLanguage(id: $id, status: $status)
  }
`;

export interface mActiveDeactiveLanguageVars {
  id: string;
  status: boolean;
}

export interface mActiveDeactiveLanguageRes {
  activeDeactiveLanguage?: boolean;
}
