import { gql } from '@apollo/client';
import { IChatChannelType } from 'types';

export const qGetChatChannelById = gql`
  query getChatChannelById($id: ID!) {
    getChatChannelById(id: $id) {
      uuid
      name
      channel_type
      vendorRelationshiopChat {
        uuid
        invitedBy
        status
        is_send_reel
        is_reply_message
        view_only
        not_viewable
        general_market_advertising
        multicultural_advertising
        music_video
        vendorCompany {
          uuid
        }
        freelanceCompany {
          uuid
        }
        invitee_vendor_company_profile_ids
        invitedByInfo {
          uuid
          role
          vendorCompany {
            uuid
            company_type
            general_market_advertising
            multicultural_advertising
            music_video
          }
        }
      }
      vendorCompany {
        uuid
        general_market_advertising
        multicultural_advertising
        music_video
      }
      ndaRevisionChat {
        uuid
        nda {
          uuid
        }
      }
      shareFavoriteVendorChat {
        uuid
        shareTo {
          uuid
        }
        is_merged
        favoriteVendorList {
          uuid
          name
        }
      }
      clientProjectChat {
        uuid
        project {
          uuid
        }
      }
      vendorProjectChat {
        uuid
        project {
          uuid
        }
      }
      created_at
      updated_at
    }
  }
`;

export interface qGetChatChannelByIdRes {
  getChatChannelById?: IChatChannelType;
}
