/* eslint-disable max-len */
import { ConnectionState, Message, Paginator } from '@twilio/conversations';
import { Dispatch } from 'redux';
import { ActionType, Action, IChatType } from '../types';

export const setConnectionState =
  (payload: ConnectionState) =>
  (dispatch: Dispatch<Action>): void => {
    dispatch({
      type: ActionType.SET_CONNECTION_STATE,
      payload,
    });
  };

export const addChat =
  (payload: IChatType) =>
  (dispatch: Dispatch<Action>): void => {
    dispatch({
      type: ActionType.ADD_CHAT,
      payload,
    });
  };

export const addMessage =
  (message: Message, sendNotification: boolean) =>
  (dispatch: Dispatch<Action>): void => {
    dispatch({
      type: ActionType.ADD_MESSAGE,
      payload: { message, sendNotification },
    });
  };

export const setActiveChat =
  (channelSid: string) =>
  (dispatch: Dispatch<Action>): void => {
    dispatch({
      type: ActionType.SET_ACTIVE_CHAT,
      payload: channelSid,
    });
  };

export const logoutChat =
  () =>
  (dispatch: Dispatch<Action>): void => {
    dispatch({
      type: ActionType.LOGOUT_CHAT,
    });
  };

export const loadMoreMessages =
  (chatSid: string, paginator: Paginator<Message>) =>
  (dispatch: Dispatch<Action>) => {
    dispatch({ type: ActionType.UPDATE_CHAT, payload: { chatSid, paginator } });
  };

export const startTyping =
  (channelSid: string, participant: string) =>
  (dispatch: Dispatch<Action>): void => {
    dispatch({
      type: ActionType.TYPING_STARTED,
      payload: { channelSid, participant },
    });
  };

export const endTyping =
  (channelSid: string, participant: string) =>
  (dispatch: Dispatch<Action>): void => {
    dispatch({
      type: ActionType.TYPING_ENDED,
      payload: { channelSid, participant },
    });
  };
