import { gql } from '@apollo/client';

export const mUpdateMyEmailAlert = gql`
  mutation updateMyEmailAlert($id: ID!, $duration_id: ID!) {
    updateMyEmailAlert(id: $id, duration_id: $duration_id)
  }
`;

export interface mUpdateMyEmailAlertRes {
  updateMyEmailAlert?: boolean;
}

export interface mUpdateMyEmailAlertVars {
  id: string;
  duration_id: string;
}
