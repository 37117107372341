import { gql } from '@apollo/client';
import {
  IFavoriteVendorListItemType,
  IFavoriteVendorListType,
  IPaginationVars,
} from 'types';

export const qGetFavoriteVendorListItems = gql`
  query getFavoriteVendorListItems($limit: Int!, $offset: Int!, $id: ID!) {
    getFavoriteVendorListItems(limit: $limit, offset: $offset, id: $id) {
      total
      rows {
        uuid
        vendorCompany {
          uuid
          company_name
          profiles {
            projectType {
              name
              vendor_label
              freelance_label
              client_label
              dashboard_label
            }
          }
          primaryProfile {
            projectType {
              name
              vendor_label
              freelance_label
              client_label
              dashboard_label
            }
          }
        }
      }
    }

    getFavoriteVendorListById(id: $id) {
      uuid
      name
    }
  }
`;

export interface qGetFavoriteVendorListItemsRes {
  getFavoriteVendorListItems?: {
    total: number;
    rows: IFavoriteVendorListItemType[];
  };
  getFavoriteVendorListById?: IFavoriteVendorListType;
}

export interface qGetFavoriteVendorListItemsVars extends IPaginationVars {
  id: string;
}
