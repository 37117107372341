import { gql } from '@apollo/client';
import * as Yup from 'yup';

export const mShareWithVendors = gql`
  mutation shareWithVendors($shareWithVendorsInput: ShareWithVendorsDto!) {
    shareWithVendors(shareWithVendorsInput: $shareWithVendorsInput)
  }
`;

export interface mShareWithVendorsRes {
  shareWithVendors?: boolean;
}

export interface mShareWithVendorsFormVars {
  project_id: string;
  vendors: string[];
  note: string;
}

export interface mShareWithVendorsVars {
  shareWithVendorsInput: mShareWithVendorsFormVars;
}

export const mShareWithVendorsInitVals: mShareWithVendorsFormVars = {
  project_id: '',
  vendors: [],
  note: '',
};

export const mShareWithVendorsValidations = Yup.object().shape({
  project_id: Yup.string().required('Project is required!'),
  vendors: Yup.array()
    .min(1, 'Minimum one vendor required!')
    .of(Yup.string().required('Minimum one vendor required!'))
    .required('Minimum one vendor required!'),
  note: Yup.string().required('Note is required!'),
});
