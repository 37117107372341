import dayjs from 'dayjs';
import jwtDecode from 'jwt-decode';
import { REFRESH_TOKEN, ACCESS_TOKEN } from 'config/constants';
import { apiUri } from 'config/config';
import { refreshTokenQueryString } from 'apis';

export const getRefreshToken = (): string | null =>
  localStorage.getItem(REFRESH_TOKEN);
export const setRefreshToken = (token: string): void =>
  localStorage.setItem(REFRESH_TOKEN, token);
export const removeRefreshToken = (): void =>
  localStorage.removeItem(REFRESH_TOKEN);

export const getAccessToken = (): string | null =>
  localStorage.getItem(ACCESS_TOKEN);
export const setAccessToken = (token: string): void =>
  localStorage.setItem(ACCESS_TOKEN, token);
export const removeAccessToken = (): void =>
  localStorage.removeItem(ACCESS_TOKEN);

export const clearCache = (): void => {
  removeAccessToken();
  removeRefreshToken();
};

export const invalidToken = (exp: number): boolean => {
  const expiryDate = dayjs.unix(exp);
  const now = dayjs();

  return now > expiryDate;
};

export const verifyAndGetAccessToken = async (): Promise<string> => {
  const aToken = getAccessToken();
  const rToken = getRefreshToken();

  if (!aToken) return '';

  const { exp } = jwtDecode<{ exp: number }>(aToken);
  if (!invalidToken(exp)) return aToken;

  // make a fetch request to get new access token
  const res = await fetch(apiUri, {
    method: 'POST',
    credentials: 'include',
    body: JSON.stringify({ query: refreshTokenQueryString, variables: {} }),
    headers: {
      Authorization: rToken || '',
      'Content-Type': 'application/json; charset=utf-8',
    },
  });

  // response is valid then update tokens and send request
  if (res.status === 200) {
    const refreshToken = res.headers.get(REFRESH_TOKEN);
    const accessToken = res.headers.get(ACCESS_TOKEN);

    if (refreshToken && accessToken) {
      setRefreshToken(refreshToken);
      setAccessToken(accessToken);

      return accessToken;
    }
  }

  return aToken;
};
