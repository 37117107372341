import { gql } from '@apollo/client';
import { ISubmissionType } from 'types';

export const qGetSubmissionsByProjectIdVendor = gql`
  query getSubmissionsByProjectIdVendor($id: ID!) {
    getSubmissionsByProjectIdVendor(id: $id) {
      uuid
      reel_link
      note
      reel_password
      relevancy_ranking
      company_relevancy_ranking
      created_at
      vendorCompany {
        uuid
        company_type
      }
      submitBy {
        uuid
        name
      }
      talentProfile {
        uuid
        talentOverview {
          uuid
          talent_name
        }
      }
      styleServiceProfile {
        uuid
        talentType {
          uuid
          name
        }
        creativeStyle {
          uuid
          name
        }
        projectType {
          uuid
          vendor_label
        }
        vendorCompany {
          uuid
        }
      }
      lastUpdateBy {
        uuid
        name
      }
    }
  }
`;

export interface qGetSubmissionsByProjectIdVendorRes {
  getSubmissionsByProjectIdVendor?: ISubmissionType[];
}
