import { gql } from '@apollo/client';
import { IFavoriteVendorListType } from 'types';

export const qGetFavoriteVendorListsWithoutPagination = gql`
  {
    getFavoriteVendorListsWithoutPagination {
      uuid
      name
    }
  }
`;

export interface qGetFavoriteVendorListsWithoutPaginationRes {
  getFavoriteVendorListsWithoutPagination?: IFavoriteVendorListType[];
}
