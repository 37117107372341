import { gql } from '@apollo/client';

export const mUpdateProfile = gql`
  mutation updateProfile($profile: UserProfileInput!) {
    updateProfile(profile: $profile)
  }
`;

export interface mUpdateProfileRes {
  updateProfile?: boolean;
}

export interface mUpdateProfileFormVars {
  first_name: string;
  last_name: string;
  profile_pic?: string;
}

export interface mUpdateProfileVars {
  profile: mUpdateProfileFormVars;
}
