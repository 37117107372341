import { gql } from '@apollo/client';
import { IEmailAlertDurationType, IUserEmailAlertType } from 'types';

export const qMyEmailAlerts = gql`
  query {
    myEmailAlerts {
      uuid
      created_at
      emailAlert {
        uuid
        title
      }
      emailAlertDuration {
        uuid
        title
        duration_in_minutes
        is_unsubscribe
      }
    }

    emailAlertDurations {
      uuid
      title
      duration_in_minutes
      is_unsubscribe
    }
  }
`;
export interface qMyEmailAlertsRes {
  myEmailAlerts?: IUserEmailAlertType[];
  emailAlertDurations?: IEmailAlertDurationType[];
}
