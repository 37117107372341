import { gql } from '@apollo/client';
import { IAuthUserType } from 'types';

export const userAttributesString = `
  {
    uuid
    role
    username
    name
    emails {
      uuid
      email
      is_email_verified
      is_primary
    }
    phone_numbers {
      uuid
      phone_number
    }
    company {
      uuid
      company_name
      company_type
      billing {
        uuid
        renewal
        is_auto_pay
        downgrade_date
        is_downgrade_by_system
        is_downgrade_alert_to_user
      }
      lastPendingInvoice {
        due_date
      }
      admins {
        uuid
        name
      }
    }
    vendorCompany {
      uuid
      company_name
      company_type
      company_url
      company_logo
      is_having_freelance
      female_owned_company
      minority_owned_company
      veteran_owned_company
      profilePending
      profiles {
        uuid
        company_bio
        reel_link
        is_reel_upload
        is_primary
        is_profile_completion
        projectType {
          uuid
          name
          vendor_label
          freelance_label
          client_label
          dashboard_label
          is_more_data
          is_project_changes
        }
      }
      primaryProfile {
        uuid
        company_bio
        reel_link
        is_reel_upload
        is_profile_completion
        is_primary
        projectType {
          uuid
          name
          vendor_label
          freelance_label
          client_label
          dashboard_label
        }
      }
      locations {
        uuid
        state {
          uuid
          name
        }
        region
        mailing_address1
        mailing_address2
        city
        zip
        is_default
      }
      contacts {
        uuid
        name
        title
        email
        phone_number
      }
      talentOverviews {
        uuid
        talent_image
        talent_name
        profiles {
          uuid
          bio
          projectType {
            uuid
            name
            vendor_label
            freelance_label
            client_label
            dashboard_label
          }
          is_primary
          talentGroups {
            uuid
            name
          }
          creativeStyles {
            uuid
            name
          }
          languages {
            uuid
            name
          }
          talentType {
            uuid
            name
          }
          vendorBudgetRange {
            uuid
            name
          }
        }
      }
      primaryStyleService {
        projectType {
          uuid
          is_more_data
          freelance_label
          name
        }
        talentType {
          uuid
          name
        }
      }
      additionalStyleService {
        projectType {
          uuid
          is_more_data
          freelance_label
          name
        }
        creativeStyle {
          uuid
          name
        }
      }
    }
    is_reel_access
    is_nda_access
    is_reply_access
    is_talent_access
    is_temporary_password
  }
`;

export const refreshTokenQueryString = `
  mutation refreshToken {
    refreshToken ${userAttributesString}
  }
`;

export const mRefreshToken = gql`
  ${refreshTokenQueryString}
`;

export interface mRefreshTokenRes {
  refreshToken?: IAuthUserType;
}
