import { gql } from '@apollo/client';

export const mApproveVendorCompany = gql`
  mutation approveVendorCompany($id: ID!, $user_id: ID!) {
    approveVendorCompany(id: $id, user_id: $user_id)
  }
`;

export interface mApproveVendorCompanyRes {
  approveVendorCompany?: boolean;
}

export interface mApproveVendorCompanyVar {
  id: string;
  user_id: string;
}
