import { gql } from '@apollo/client';
import { talentOverviewString } from 'apis/talentOverview';
import { ITalentOverviewType } from 'types';

export const qGetVendorTalentOverviewsById = gql`
  query getVendorTalentOverviewsById(
    $limit: Int!
    $offset: Int!
    $vendor_company_id: ID!
    $sorts: [OrderByInput!]
  ) {
    getVendorTalentOverviewsById(
      limit: $limit
      offset: $offset
      vendor_company_id: $vendor_company_id
      sorts: $sorts
    ) {
      total
      rows {
        ${talentOverviewString}
      }
    }
  }
`;

export interface qGetVendorTalentOverviewsByIdRes {
  getVendorTalentOverviewsById: {
    total: number;
    rows: ITalentOverviewType[];
  };
}

export interface qGetVendorTalentOverviewsByIdVars {
  limit: number;
  offset: number;
  vendor_company_id: string;
}
