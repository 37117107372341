import { gql } from '@apollo/client';
import * as Yup from 'yup';

export const mAddVendorManager = gql`
  mutation addVendorManager($inviteInput: VendorManagerInviteDto!) {
    addVendorManager(inviteInput: $inviteInput)
  }
`;

export interface mAddVendorManagerRes {
  addVendorManager?: boolean;
}

export interface mAddVendorManagerFormVars {
  first_name: string;
  last_name: string;
  email: string;
  is_reel_access: boolean;
  is_nda_access: boolean;
  is_reply_access: boolean;
  is_talent_access: boolean;
  is_admin: boolean;
}

export interface mAddVendorManagerVars {
  inviteInput: mAddVendorManagerFormVars;
}

export const mAddVendorManagerValidations = Yup.object().shape({
  first_name: Yup.string().required('First name is required!'),
  last_name: Yup.string().required('Last name is required!'),
  email: Yup.string()
    .trim()
    .lowercase()
    .email('Invalid email!')
    .required('Email is required!'),
});

export const mAddVendorManagerInitVals: mAddVendorManagerFormVars = {
  first_name: '',
  last_name: '',
  email: '',
  is_reel_access: false,
  is_nda_access: false,
  is_reply_access: false,
  is_talent_access: false,
  is_admin: false,
};
