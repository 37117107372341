import { gql } from '@apollo/client';
import { IPaginationVars, IVendorCompanyType } from 'types';

export const qVendorCompanysByNdaForClient = gql`
  query vendorCompanysByNdaForClient(
    $limit: Int!
    $offset: Int!
    $id: ID!
    $searchTerm: String!
    $sorts: [OrderByInput!]
  ) {
    vendorCompanysByNdaForClient(
      limit: $limit
      offset: $offset
      id: $id
      searchTerm: $searchTerm
      sorts: $sorts
    ) {
      total
      rows {
        uuid
        company_name
        company_type
        profiles {
          is_primary
          is_profile_completion
          projectType {
            uuid
            name
            vendor_label
            freelance_label
            client_label
            dashboard_label
          }
        }
        talentOverviews {
          uuid
          profiles {
            is_primary
            is_profile_completion
            projectType {
              uuid
              name
              vendor_label
              freelance_label
              client_label
              dashboard_label
            }
          }
        }
      }
    }
  }
`;

export interface qVendorCompanysByNdaForClientRes {
  vendorCompanysByNdaForClient?: {
    total: number;
    rows: IVendorCompanyType[];
  };
}

export interface qVendorCompanysByNdaForClientVars extends IPaginationVars {
  id: string;
  searchTerm: string;
}
