import { gql } from '@apollo/client';
import * as Yup from 'yup';

export const mContactAdminCliamedUnclaimedVendors = gql`
  mutation contactAdminCliamedUnclaimedVendors(
    $vendorContactAdminInput: VendorContactAdminInput!
  ) {
    contactAdminCliamedUnclaimedVendors(
      vendorContactAdminInput: $vendorContactAdminInput
    )
  }
`;

export interface mContactAdminCliamedUnclaimedVendorsRes {
  contactAdminCliamedUnclaimedVendors?: boolean;
}

export interface mContactAdminCliamedUnclaimedVendorsFormType {
  vendorContactAdminInput: {
    name: string;
    email: string;
    company_name: string;
    company_url: string;
    is_claimed: boolean;
    phone_number: string;
    user_note: string;
  };
}

export const mContactAdminCliamedUnclaimedVendorsInitVals: mContactAdminCliamedUnclaimedVendorsFormType =
  {
    vendorContactAdminInput: {
      name: '',
      company_name: '',
      email: '',
      phone_number: '',
      user_note: '',
      company_url: '',
      is_claimed: false,
    },
  };

export const mContactAdminCliamedUnclaimedVendorsValidations =
  Yup.object().shape({
    vendorContactAdminInput: Yup.object().shape({
      name: Yup.string().required('Name is required!'),
      company_name: Yup.string().required('Company is required!'),
      company_url: Yup.string().required('Company url is required!'),
      email: Yup.string()
        .trim()
        .lowercase()
        .email('Invalid email!')
        .required('Email is required!'),
      phone_number: Yup.string().matches(
        /^\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/,
        'Invalid mobile number!',
      ),
      user_note: Yup.string().required('Note is required!'),
      is_claimed: Yup.string().required(),
    }),
  });
