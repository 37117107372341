import { TypedUseSelectorHook, useSelector } from 'react-redux';
import { legacy_createStore, applyMiddleware } from 'redux';
import { composeWithDevToolsDevelopmentOnly as compose } from '@redux-devtools/extension';
import thunk from 'redux-thunk';
import { reducers, initialState } from './reducers';
import { RootState } from './types';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const store = legacy_createStore(
  reducers,
  initialState,
  compose(applyMiddleware(thunk)),
);

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
