import { gql } from '@apollo/client';
import * as Yup from 'yup';

export const mDowngradeEnterpriseAccount = gql`
  mutation downgradeEnterpriseAccount(
    $downgradeEnterpriseAccountInput: DowngradeEnterpriseAccountInput!
  ) {
    downgradeEnterpriseAccount(
      downgradeEnterpriseAccountInput: $downgradeEnterpriseAccountInput
    )
  }
`;

export interface mDowngradeEnterpriseAccountRes {
  downgradeEnterpriseAccount?: boolean;
}

export interface mDowngradeEnterpriseAccountFormType {
  downgradeEnterpriseAccountInput: {
    user_note: string;
  };
}

export const mDowngradeEnterpriseAccountInitVals: mDowngradeEnterpriseAccountFormType =
  {
    downgradeEnterpriseAccountInput: {
      user_note: '',
    },
  };

export const mDowngradeEnterpriseAccountValidations = Yup.object().shape({
  downgradeEnterpriseAccountInput: Yup.object().shape({
    user_note: Yup.string().required('Note is required!'),
  }),
});
