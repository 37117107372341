import { gql } from '@apollo/client';
import {
  IPaginationVars,
  IVendorCompanyType,
  IVendorCompanyTypeEnum,
} from 'types';

export const qSearchVendorByNameForFreelancerInvite = gql`
  query searchVendorByNameForFreelancerInvite(
    $limit: Int!
    $offset: Int!
    $company_type: VendorCompanyType!
    $searchTerm: String!
    $sorts: [OrderByInput!]
  ) {
    searchVendorByNameForFreelancerInvite(
      limit: $limit
      offset: $offset
      company_type: $company_type
      searchTerm: $searchTerm
      sorts: $sorts
    ) {
      total
      rows {
        uuid
        company_name
        general_market_advertising
        multicultural_advertising
        music_video
        profiles {
          uuid
          is_profile_completion
          projectType {
            uuid
            name
            vendor_label
            freelance_label
            client_label
            dashboard_label
          }
        }
      }
    }
  }
`;

export interface qSearchVendorByNameForFreelancerInviteRes {
  searchVendorByNameForFreelancerInvite?: {
    total: number;
    rows: IVendorCompanyType[];
  };
}

export interface qSearchVendorByNameForFreelancerInviteVars
  extends IPaginationVars {
  company_type: IVendorCompanyTypeEnum;
  searchTerm: string;
}
