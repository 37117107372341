import { gql } from '@apollo/client';
import {
  IPaginationVars,
  ISubmissionType,
  ITalentOverviewProfileType,
  IVendorCompanyStyleServiceProfileType,
} from 'types';

export const qGetSubmissionsByProjectIdSalesRep = gql`
  query getSubmissionsByProjectIdSalesRep(
    $id: ID!
    $limit: Int!
    $offset: Int!
  ) {
    getSubmissionsByProjectIdSalesRep(id: $id, limit: $limit, offset: $offset) {
      total
      rows {
        uuid
        reel_link
        note
        reel_password
        relevancy_ranking
        company_relevancy_ranking
        created_at
        updated_at
        submitBy {
          uuid
          name
        }
        lastUpdateBy {
          uuid
          name
        }
        talentProfile {
          uuid
          talentOverview {
            uuid
            talent_name
          }
        }
        styleServiceProfile {
          uuid
          talentType {
            uuid
            name
          }
          creativeStyle {
            uuid
            name
          }
        }
        vendorCompany {
          uuid
          company_name
        }
      }
    }

    getTalentOverviewsForSubmissionsSalesrep(id: $id) {
      uuid
      relevancy_ranking
      projectType {
        uuid
        vendor_label
      }
      talentOverview {
        talent_name
        company {
          uuid
          company_name
          company_type
          talentOverviews {
            uuid
            talent_name
            primaryProfile {
              uuid
            }
          }
        }
      }
    }

    getStyleServiceProfilesSalesRep(id: $id) {
      uuid
      relevancy_ranking
      talentType {
        uuid
        name
      }
      creativeStyle {
        uuid
        name
      }
      vendorCompany {
        uuid
        company_name
        company_type
      }
      projectType {
        uuid
        vendor_label
        freelance_label
        is_more_data
      }
    }
  }
`;

export interface qGetSubmissionsByProjectIdSalesRepRes {
  getSubmissionsByProjectIdSalesRep?: {
    total: number;
    rows: ISubmissionType[];
  };
  getTalentOverviewsForSubmissionsSalesrep?: ITalentOverviewProfileType[];
  getStyleServiceProfilesSalesRep?: IVendorCompanyStyleServiceProfileType[];
}

export interface qGetSubmissionsByProjectIdSalesRepVars
  extends IPaginationVars {
  id: string;
}
