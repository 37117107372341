import { gql } from '@apollo/client';

export const qGetRelationInviteProfileIds = gql`
  query getRelationInviteProfileIds($id: ID!, $is_invited: Boolean!) {
    getRelationInviteProfileIds(id: $id, is_invited: $is_invited)
  }
`;

export interface qGetRelationInviteProfileIdsRes {
  getRelationInviteProfileIds?: string[];
}
