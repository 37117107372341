import { gql } from '@apollo/client';
import { ISettingDataType } from 'types';

export const qLanguageById = gql`
  query languageById($id: ID!) {
    languageById(id: $id) {
      uuid
      name
      short_description
      long_description
      sort_order
    }
  }
`;

export interface qLanguageByIdRes {
  languageById?: ISettingDataType;
}
