import { IClientCompanyType } from './ClientCompany.type';
import { IUploadFileType } from './File.type';
import { IUserType } from './User.type';

export enum INDAVendorStatus {
  Awaiting_Approval = 'Awaiting_Approval',
  Denied = 'Denied',
  Submitted = 'Submitted',
  NDA_Rejected = 'NDA_Rejected',
  Approved = 'Approved',
  RequestClient = 'RequestClient',
}

export interface INDAType {
  uuid: string;
  ndaFile: IUploadFileType;
  signedNda: IUploadFileType | undefined;
  company: IClientCompanyType | undefined;
  user: IUserType | undefined;
  awaitingApprovalCount: number;
  name: string;
  note: string;
  is_general: boolean;
  created_at: string;
  updated_at: string;

  // only for vendor response
  status?: INDAVendorStatus | null;
}

export interface IVendorNDAType extends INDAType {
  approved_at: Date | null;
}
