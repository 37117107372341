import { IStateType, ITalentOverviewProfileType, IUserType } from 'types';
import {
  IVendorCompanyProfileType,
  IVendorCompanyType,
} from './VendorCompany.type';

export enum IRelationshipInviteStatus {
  Pending = 'Pending',
  Rejected = 'Rejected', // reject by invitee
  Cancelled = 'Cancelled', // cancel by inviter
  Approved = 'Approved',
}

export interface IVendorRelationshipChatType {
  uuid: string;
  invitedBy: string;
  invitedByInfo: IUserType;
  inviteeCompany: IVendorCompanyType;
  invitee_vendor_company_profile_ids: string[];
  status: IRelationshipInviteStatus;
  vendorCompany?: IVendorCompanyType;
  freelanceCompany?: IVendorCompanyType;
  invitedProfiles?: IVendorRelationshipChatWithProfileDataType[];
  is_send_reel: boolean;
  is_reply_message: boolean;
  view_only?: boolean;
  not_viewable?: boolean;
  created_at: string;
  updated_at: string;
  general_market_advertising: boolean;
  multicultural_advertising: boolean;
  music_video: boolean;
}

export interface IVendorRelationshipChatWithProfileDataType {
  vendorRelationChat: IVendorRelationshipChatType;
  state: IStateType;
  vendorCompanyProfile?: IVendorCompanyProfileType;
  freelanceProfile?: ITalentOverviewProfileType;
}
