import { gql } from '@apollo/client';
import {
  IPaginationVars,
  IVendorCompanyType,
  IVendorCompanyTypeEnum,
} from 'types';

export const qSearchVendorByNameForSalesrepInvite = gql`
  query searchVendorByNameForSalesrepInvite(
    $limit: Int!
    $offset: Int!
    $company_type: VendorCompanyType!
    $searchTerm: String!
    $sorts: [OrderByInput!]
  ) {
    searchVendorByNameForSalesrepInvite(
      limit: $limit
      offset: $offset
      company_type: $company_type
      searchTerm: $searchTerm
      sorts: $sorts
    ) {
      total
      rows {
        uuid
        company_name
        company_type
        profiles {
          projectType {
            name
            vendor_label
            freelance_label
            client_label
            dashboard_label
          }
        }
        primaryProfile {
          projectType {
            name
            vendor_label
            freelance_label
            client_label
            dashboard_label
          }
        }
        talentOverviews {
          uuid
          talent_name
          talent_image
          profiles {
            uuid
            is_profile_completion
            is_primary
            bio
            projectType {
              uuid
              name
              vendor_label
              freelance_label
              client_label
              dashboard_label
            }
          }
        }
      }
    }
  }
`;

export interface qSearchVendorByNameForSalesrepInviteRes {
  searchVendorByNameForSalesrepInvite?: {
    total: number;
    rows: IVendorCompanyType[];
  };
}

export interface qSearchVendorByNameForSalesrepInviteVars
  extends IPaginationVars {
  company_type: IVendorCompanyTypeEnum;
  searchTerm: string;
}
