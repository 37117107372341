import { gql } from '@apollo/client';

export const mDeleteStyleServiceReel = gql`
  mutation deleteStyleServiceReel($id: ID!, $profile_id: ID!) {
    deleteStyleServiceReel(id: $id, profile_id: $profile_id)
  }
`;

export interface mDeleteStyleServiceReelRes {
  deleteStyleServiceReel?: boolean;
}

export interface mDeleteStyleServiceReelVars {
  id: string;
  profile_id: string;
}
