import { gql } from '@apollo/client';
import { ISettingDataType } from 'types';

export const qLanguagesForAdmin = gql`
  {
    languagesForAdmin {
      uuid
      name
      unique_name
      short_description
      long_description
      sort_order
      is_active
    }
  }
`;

export interface qLanguagesForAdminRes {
  languagesForAdmin?: ISettingDataType[];
}
