import { gql } from '@apollo/client';
import { INDAType, IPaginationVars } from 'types';

export const qClientNDAs = gql`
  query clientNDAs(
    $limit: Int!
    $offset: Int!
    $is_general: Boolean
    $sorts: [OrderByInput!]
  ) {
    clientNDAs(
      limit: $limit
      offset: $offset
      is_general: $is_general
      sorts: $sorts
    ) {
      total
      rows {
        uuid
        name
        is_general
        ndaFile {
          name
          url
        }
        created_at
        updated_at
        awaitingApprovalCount
      }
    }
  }
`;

export interface qClientNDAsRes {
  clientNDAs?: {
    total: number;
    rows: INDAType[];
  };
}
export interface qClientNDAsVar extends IPaginationVars {
  is_general?: boolean;
}
