import { gql } from '@apollo/client';
import { IEnterpriseClientRequestType } from 'types';

export const qEnterpriseClientRequestById = gql`
  query enterpriseClientRequestById($id: ID!) {
    enterpriseClientRequestById(id: $id) {
      uuid
      name
      email
      seats
      price
      company_name
      phone_number
      user_note
      request_status
      company_name
      user {
        uuid
        first_name
      }
      company {
        uuid
        company_name
      }
    }
  }
`;

export interface qEnterpriseClientRequestByIdRes {
  enterpriseClientRequestById?: IEnterpriseClientRequestType;
}
