import { gql } from '@apollo/client';

export const mUpdateSubmission = gql`
  mutation updateSubmission(
    $uuid: ID!
    $reel_link: String!
    $note: String
    $reel_password: String
  ) {
    updateSubmission(
      uuid: $uuid
      reel_link: $reel_link
      note: $note
      reel_password: $reel_password
    )
  }
`;

export interface mUpdateSubmissionRes {
  updateSubmission?: boolean;
}

export interface mUpdateSubmissionVars {
  uuid: string;
  reel_link: string;
  note?: string;
  reel_password?: string;
}
