import { gql } from '@apollo/client';
import { INameUuidType } from 'types';

export const qClientBudgetRanges = gql`
  query clientBudgetRanges($project_type_id: ID) {
    clientBudgetRanges(project_type_id: $project_type_id) {
      uuid
      name
    }
  }
`;

export interface qClientBudgetRangesRes {
  clientBudgetRanges?: INameUuidType[];
}

export interface qClientBudgetRangesVar {
  project_type_id?: string;
}
