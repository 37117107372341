import { gql } from '@apollo/client';

export const mResendTeamMemberInvite = gql`
  mutation resendTeamMemberInvite($id: ID!) {
    resendTeamMemberInvite(id: $id)
  }
`;

export interface mResendTeamMemberInviteRes {
  resendTeamMemberInvite?: boolean;
}

export interface mResendTeamMemberInviteVar {
  id: string;
}
