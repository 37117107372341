import { gql } from '@apollo/client';
import { IPaginationVars, ISubmissionCommentType } from 'types';

export const qGetSubmissionCommentString = `
  uuid
  comment
  created_at
  author {
    uuid
    name
  }
  teamMember {
    uuid
    private_user_name
  }
`;

export const qGetSubmissionComments = gql`
  query getSubmissionComments(
    $id: ID!
    $limit: Int!
    $offset: Int!
  ) {
    getSubmissionComments(
      id: $id
      limit: $limit
      offset: $offset
    ) {
      total
      rows {
        ${qGetSubmissionCommentString}
      }

    }
  }
`;

export interface qGetSubmissionCommentsRes {
  getSubmissionComments?: {
    total: number;
    rows: ISubmissionCommentType[];
  };
}

export interface qGetSubmissionCommentsVars extends IPaginationVars {
  id: string;
}
