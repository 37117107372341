import { gql } from '@apollo/client';
import * as Yup from 'yup';
import { IProjectTypeType, IVendorCompanyTypeEnum } from 'types';

export const mAdminRegisterVendorCompany = gql`
  mutation adminRegisterVendorCompany($company: AdminVendorCompanyDto!) {
    adminRegisterVendorCompany(company: $company)
  }
`;

export interface mAdminRegisterVendorCompanyRes {
  adminRegisterVendorCompany?: boolean;
}

export interface mAdminRegisterVendorCompanyFormType {
  company: {
    company_name: string;
    company_type: IVendorCompanyTypeEnum | string;
    company_url: string;
    company_primary_project_type_id: string | string[];
    company_project_type_ids: string[];
    company_primary_style_id?: string | string[] | null;
    company_additional_style_ids?: string[] | null;
    company_primary_service_id?: string | string[] | null;
    company_additional_service_ids?: string[] | null;
    unclaimed_admin_name: string;
    unclaimed_admin_email: string;
    unclaimed_emails: string[];
  };
}

export const mAdminRegisterVendorCompanyInitVals: mAdminRegisterVendorCompanyFormType =
  {
    company: {
      company_name: '',
      company_type: '',
      company_url: '',
      company_primary_project_type_id: '' || [],
      company_project_type_ids: [],
      unclaimed_admin_name: '',
      unclaimed_admin_email: '',
      unclaimed_emails: [],
    },
  };

export const mAdminRegisterVendorCompanyValidations = (
  pTypes: IProjectTypeType[],
) =>
  Yup.object().shape({
    company: Yup.object().shape({
      company_type: Yup.string().required('Company type is required!'),
      company_name: Yup.string().required('Company name is required!'),
      company_url: Yup.string()
        .required('Url is required!')
        .matches(
          /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?\/?$/gm,
          'Invalid company url!',
        ),
      company_primary_project_type_id: Yup.string().when('company_type', {
        is: IVendorCompanyTypeEnum.COMPANY || IVendorCompanyTypeEnum.FREELANCER,
        then: Yup.string().required('Primary Company type is required!'),
      }),
      company_project_type_ids: Yup.array().of(Yup.string()),
      company_primary_style_id: Yup.string().when(
        ['company_primary_project_type_id', 'company_project_type_ids'],
        {
          is: (
            company_primary_project_type_id: string,
            company_project_type_ids: string[],
          ) => {
            const usedPtypes = pTypes.filter((p) =>
              [
                company_primary_project_type_id,
                ...company_project_type_ids,
              ].includes(p.uuid),
            );

            if (
              usedPtypes.find((p) => p.freelance_label !== '' && p.is_more_data)
            )
              return true;
            return false;
          },
          then: (sch) => sch.required('Primary style is required!'),
        },
      ),
      company_additional_style_ids: Yup.array().of(Yup.string()),
      company_primary_service_id: Yup.string().when(
        ['company_primary_project_type_id', 'company_project_type_ids'],
        {
          is: (
            company_primary_project_type_id: string,
            company_project_type_ids: string[],
          ) => {
            const usedPtypes = pTypes.filter((p) =>
              [
                company_primary_project_type_id,
                ...company_project_type_ids,
              ].includes(p.uuid),
            );

            if (
              usedPtypes.find((p) => p.freelance_label === '' && p.is_more_data)
            )
              return true;
            return false;
          },
          then: (sch) => sch.required('Primary service is required!'),
        },
      ),
      company_additional_service_ids: Yup.array().of(Yup.string()),
      unclaimed_admin_name: Yup.string().required('Admin name is required!'),
      unclaimed_admin_email: Yup.string()
        .trim()
        .lowercase()
        .email('Invalid email!')
        .required('Admin email is required!'),
      unclaimed_emails: Yup.array().of(Yup.string()),
    }),
  });
