import { gql } from '@apollo/client';
import * as Yup from 'yup';

export const mUpdateSalesRepInviteRelationTerritoryByProfileId = gql`
  mutation updateSalesRepInviteRelationTerritoryByProfileId(
    $relation_id: ID!
    $profile_id: ID!
    $is_invited: Boolean!
    $relation_with_freelance: Boolean!
    $territory_ids: [ID!]!
  ) {
    updateSalesRepInviteRelationTerritoryByProfileId(
      relation_id: $relation_id
      profile_id: $profile_id
      is_invited: $is_invited
      relation_with_freelance: $relation_with_freelance
      territory_ids: $territory_ids
    )
  }
`;

export interface mUpdateSalesRepInviteRelationTerritoryByProfileIdRes {
  updateSalesRepInviteRelationTerritoryByProfileId?: boolean;
}

export interface mUpdateSalesRepInviteRelationTerritoryByProfileIdVars {
  relation_id: string;
  profile_id: string;
  is_invited: boolean;
  relation_with_freelance: boolean;
  territory_ids: string[];
}

export const mUpdateSalesRepInviteRelationTerritoryByProfileIdValidations =
  Yup.object().shape({
    relation_id: Yup.string().required('Relation id is required!'),
    profile_id: Yup.string().required('Capability type is required!'),
    is_invited: Yup.boolean().required('Is invite is required!'),
    relation_with_freelance: Yup.boolean().required(
      'Relation with is required!',
    ),
    territory_ids: Yup.array()
      .of(Yup.string().required('Territory is required!'))
      .min(1, 'At least one territory is required!'),
  });
