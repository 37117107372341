import { gql } from '@apollo/client';
import { IFavoriteVendorListType } from 'types';

export const qGetFavoriteVendorLists = gql`
  query getFavoriteVendorLists(
    $limit: Int!
    $offset: Int!
    $sorts: [OrderByInput!]
  ) {
    getFavoriteVendorLists(limit: $limit, offset: $offset, sorts: $sorts) {
      total
      rows {
        uuid
        name
        itemCount
        addedBy {
          uuid
          name
          last_name
        }
        created_at
        updated_at
      }
    }
  }
`;

export interface qGetFavoriteVendorListsRes {
  getFavoriteVendorLists?: {
    total: number;
    rows: IFavoriteVendorListType[];
  };
}
