import { gql } from '@apollo/client';
import { IUploadFileType } from 'types';

export const mDenyNdaByVendor = gql`
  mutation denyNdaByVendor($denyNdaInput: DenyNdaByVendorDto!) {
    denyNdaByVendor(denyNdaInput: $denyNdaInput)
  }
`;

export interface mDenyNdaByVendorRes {
  denyNdaByVendor?: boolean;
}

export interface mDenyNdaByVendorFormVars {
  nda_id: string;
  message: string;
  upload_file_object: IUploadFileType;
}

export interface mDenyNdaByVendorVars {
  denyNdaInput: mDenyNdaByVendorFormVars;
}
