import { createContext, ReactElement, useState } from 'react';
import { IStyleServiceProfileReelType } from 'types/StyleServiceProfile.type';

const defaultStyleServiceReelsContextValue = {
  profileReels: [],
  setProfileReels(): void {},
  previewProfile: false,
  setPreviewProfile(): void {},
};

interface StyleServiceReelContext {
  profileReels: IStyleServiceProfileReelType[];
  setProfileReels: (profileReels: IStyleServiceProfileReelType[]) => void;
  previewProfile: boolean;
  setPreviewProfile: (previewProfile: boolean) => void;
}

export const StyleServiceReelListContext =
  createContext<StyleServiceReelContext>(defaultStyleServiceReelsContextValue);

export const StyleServiceReelListProvider = ({
  children,
}: {
  children: ReactElement;
}): ReactElement => {
  const [profileReels, setProfileReels] = useState<
    IStyleServiceProfileReelType[]
  >([]);
  const [previewProfile, setPreviewProfile] = useState<boolean>(false);

  return (
    <StyleServiceReelListContext.Provider
      value={{
        profileReels,
        setProfileReels,
        previewProfile,
        setPreviewProfile,
      }}
    >
      {children}
    </StyleServiceReelListContext.Provider>
  );
};
