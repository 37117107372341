import { gql } from '@apollo/client';
import { IProfessionType } from 'types/Profession.type';

export const qGetExistingProfessions = gql`
  query getExistingProfessions {
    getExistingProfessions {
      project_type
      languages
      talent_types
      creative_styles
      talent_groups
      client_budget_ranges
      vendor_budget_ranges
    }
  }
`;

export interface qGetExistingProfessionsRes {
  getExistingProfessions?: IProfessionType[];
}
