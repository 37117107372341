import { gql } from '@apollo/client';
import * as Yup from 'yup';
import { IAuthUserType, IFreelanceTalentOverviewUpdateInput } from 'types';
import { userAttributesString } from 'apis';

export const mUpdateFreelanceTalentOverviewWithProfile = gql`
  mutation updateFreelanceTalentOverviewWithProfile(
    $id: ID!
    $talentOverviewDto: TalentOverviewDto!
  ) {
    updateFreelanceTalentOverviewWithProfile(
      id: $id
      talentOverviewDto: $talentOverviewDto
    )${userAttributesString}
  }
`;

export interface mUpdateFreelanceTalentOverviewWithProfileRes {
  updateFreelanceTalentOverviewWithProfile?: IAuthUserType;
}

export interface mUpdateFreelanceTalentOverviewWithProfileVars {
  id: string;
  talentOverviewDto: IFreelanceTalentOverviewUpdateInput;
}

export const mUpdateFreelanceTalentOverviewInitVals: IFreelanceTalentOverviewUpdateInput =
  {
    talent_name: '',
    bio: '',
    vendor_budget_range_id: '',
    talent_type_id: [],
    talent_group_ids: [],
    creative_style_ids: [],
    talent_language_ids: [],
    project_type_id: '',
    talent_image: '',
  };

export const mUpdateFreelanceTalentOverviewWithProfileValidations =
  Yup.object().shape({
    talent_name: Yup.string().required('Name is required!'),
    talent_image: Yup.string().url('Invalid talent image!'),
    bio: Yup.string().required('Bio is required!'),
    talent_type_id: Yup.string().required('Talent type is required!'),
    project_type_id: Yup.string().required('Project type is required!'),
    talent_group_ids: Yup.array().of(Yup.string()),
    creative_style_ids: Yup.array()
      .of(Yup.string())
      .min(5, 'Miminum five creative styles are required!')
      .max(5, 'You can select up to 5 creative styles only!'),
    talent_language_ids: Yup.array()
      .of(Yup.string())
      .min(1, 'Talent language is required!'),
    vendor_budget_range_id: Yup.string().required('Budget range is required!'),
  });
