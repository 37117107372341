import { gql } from '@apollo/client';
import { IProjectStatus } from 'types';

export const mUpdateProjectStatus = gql`
  mutation updateProjectStatus($uuid: ID!, $status: ProjectStatus!) {
    updateProjectStatus(uuid: $uuid, status: $status)
  }
`;

export interface mUpdateProjectStatusRes {
  updateProjectStatus?: boolean;
}

export interface mUpdateProjectStatusVar {
  uuid: string;
  status: IProjectStatus;
}
