import { gql } from '@apollo/client';
import { IVendorSalesRepRelationType } from 'types';

export const qGetSalesrepVendorsByAdmin = gql`
  query getSalesrepVendorsByAdmin(
    $limit: Int!
    $offset: Int!
    $vendor_company_id: ID!
    $sorts: [OrderByInput!]
  ) {
    getSalesrepVendorsByAdmin(
      limit: $limit
      offset: $offset
      vendor_company_id: $vendor_company_id
      sorts: $sorts
    ) {
      total
      rows {
        uuid
        vendorCompany {
          uuid
          company_name
          company_type
          created_at
          updated_at
        }
      }
    }
  }
`;

export interface qGetSalesrepVendorsByAdminRes {
  getSalesrepVendorsByAdmin: {
    total: number;
    rows: IVendorSalesRepRelationType[];
  };
}

export interface qGetSalesrepVendorsByAdminVars {
  limit: number;
  offset: number;
  vendor_company_id: string;
}
