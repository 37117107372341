import { gql } from '@apollo/client';
import { ISubmissionType } from 'types';

export const qGetAdminProjectSubmissions = gql`
  query getAdminProjectSubmissions(
    $limit: Int!
    $offset: Int!
    $project_id: ID!
  ) {
    getAdminProjectSubmissions(
      limit: $limit
      offset: $offset
      project_id: $project_id
    ) {
      total
      rows {
        uuid
        status
        created_at
        project {
          uuid
          name
        }
        submitBy {
          uuid
          name
        }
        talentProfile {
          uuid
          is_primary
          talentOverview {
            uuid
            talent_name
          }
        }
        styleServiceProfile {
          uuid
          creativeStyle {
            uuid
            name
          }
          talentType {
            uuid
            name
          }
          projectType {
            uuid
            name
            freelance_label
            is_more_data
          }
        }
        clientCompany {
          uuid
          company_name
        }
        vendorCompany {
          uuid
          company_name
        }
      }
    }
  }
`;

export interface qGetAdminProjectSubmissionsRes {
  getAdminProjectSubmissions: {
    total: number;
    rows: ISubmissionType[];
  };
}

export interface qGetAdminProjectSubmissionsVars {
  limit: number;
  offset: number;
  project_id: string;
}

export interface qGetAdminProjectSubmissionsResTableDataType {
  uuid: string;
  submitted_by: string;
  submitted_by_id: string;
  talent_name: string;
  project_name: string;
  project_id: string;
  created_at: string;
  status: string;
}
