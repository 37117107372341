import { gql } from '@apollo/client';

export const mUpdateBillingAutoPayinfo = gql`
  mutation updateBillingAutoPayinfo($id: ID!, $auto_pay: Boolean!) {
    updateBillingAutoPayinfo(id: $id, auto_pay: $auto_pay)
  }
`;

export interface mUpdateBillingAutoPayinfoVars {
  id: string;
  auto_pay: boolean;
}

export interface mUpdateBillingAutoPayinfoRes {
  updateBillingAutoPayinfo?: boolean;
}
