import { gql } from '@apollo/client';
import { IConfirmEmailType } from 'types';

export const mConfirmEmail = gql`
  mutation confirmEmail($token: String!) {
    confirmEmail(token: $token) {
      confirmEmail
      isClient
    }
  }
`;

export interface mConfirmEmailRes {
  confirmEmail?: IConfirmEmailType;
}

export interface mConfirmEmailVar {
  token: string;
}
