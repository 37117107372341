import { gql } from '@apollo/client';

export const maddVendorsByCSV = gql`
  mutation addVendorsByCSV($vendor_info: [[String!]!]) {
    addVendorsByCSV(vendor_info: $vendor_info)
  }
`;

export interface maddVendorsByCSVVars {
  vendor_info: string[][];
}

export interface maddVendorsByCSVRes {
  addVendorsByCSV?: boolean;
}
