import { gql } from '@apollo/client';

export const mUpdateTeamMemberVote = gql`
  mutation updateTeamMemberVote($id: ID!, $vote: Int!, $submission_id: ID!) {
    updateTeamMemberVote(id: $id, vote: $vote, submission_id: $submission_id)
  }
`;

export interface mUpdateTeamMemberVoteRes {
  updateTeamMemberVote?: boolean;
}

export interface mUpdateTeamMemberVoteVars {
  id: string;
  vote: number;
  submission_id: string;
}
