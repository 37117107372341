import { createContext, ReactElement, useState } from 'react';
import { IAuthUserType } from 'types';

export const defaultUser: IAuthUserType = {
  uuid: null,
  username: null,
  role: null,
  name: null,
  emails: null,
  phone_numbers: null,
  company: {
    uuid: null,
    company_name: null,
    company_type: null,
    billing: null,
    lastPendingInvoice: null,
    admins: null,
  },
  vendorCompany: {
    uuid: null,
    company_name: null,
    company_logo: null,
    company_url: null,
    company_type: null,
    is_having_freelance: null,
    female_owned_company: null,
    minority_owned_company: null,
    veteran_owned_company: null,
    profilePending: null,
    primaryProfile: null,
    profiles: null,
    locations: null,
    contacts: null,
    talentOverviews: null,
    relationalFreelancers: null,
    relationalVendors: null,
    primaryStyleService: null,
    additionalStyleService: null,
  },
  is_nda_access: null,
  is_reel_access: null,
  is_reply_access: null,
  is_talent_access: null,
  is_temporary_password: null,
};

const defaultUserContextValue = {
  user: defaultUser,
  setUser(): void {},
};

interface UserContext {
  user: IAuthUserType;
  setUser: (user: IAuthUserType) => void;
}

export const UserInfoContext = createContext<UserContext>(
  defaultUserContextValue,
);

export const UserInfoProvider = ({
  children,
}: {
  children: ReactElement;
}): ReactElement => {
  const [user, setUser] = useState<IAuthUserType>(defaultUser);

  return (
    <UserInfoContext.Provider value={{ user, setUser }}>
      {children}
    </UserInfoContext.Provider>
  );
};
