import { gql } from '@apollo/client';

export const mResendClientMangerInvitation = gql`
  mutation resendClientMangerInvitation($id: ID!) {
    resendClientMangerInvitation(id: $id)
  }
`;

export interface mResendClientMangerInvitationRes {
  resendClientMangerInvitation?: boolean;
}
