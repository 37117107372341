import { IUserType } from 'types/User.type';
import { ITalentOverviewType } from 'types/TalentOverview.type';
import { IVendorFreelanceRelationType } from 'types/VendorFreelancerRelation.type';
import { IVendorSalesRepRelationType } from 'types/VendorSalesRepRelation.type';
import { IStyleServiceProfileReelType } from 'types/StyleServiceProfile.type';
import { IRegionType } from './Region.type';
import { IStateType } from './State.type';
import { IProjectTypeType } from './Project-type.type';
import { INameUuidType } from './other.type';

export enum IVendorCompanyTypeEnum {
  COMPANY = 'COMPANY',
  FREELANCER = 'FREELANCER',
  INDEPENDENT_REP = 'INDEPENDENT_REP',
}

export enum IVendorCompanyStatusEnum {
  Claimed = 'Claimed',
  Unclaimed = 'Unclaimed',
  Deactivated = 'Deactivated',
  PendingApproval = 'Pending Approval',
}

export interface IVendorCompanyType {
  uuid: string;
  company_name: string;
  company_type: IVendorCompanyTypeEnum;
  company_url: string;
  company_logo: string;
  owner?: IUserType;
  talentOverviewsCount: number;
  talentOverviews: ITalentOverviewType[];
  rating: number;
  locations: IVendorCompanyLocationType[];
  contacts: IVendorCompanyContactType[];
  relationalFreelancers: IVendorFreelanceRelationType[];
  relationalVendors: IVendorSalesRepRelationType[];
  cliamRequestUser: IVendorCompanyClaimRequestType | null;
  members: IUserType[] | null;
  profiles: IVendorCompanyProfileType[] | null;
  primaryProfile: IVendorCompanyProfileType | null;
  primaryStyleService?: IVendorCompanyHasPrimaryStyleServiceType[] | null;
  additionalStyleService?: IVendorCompanyHasAdditionalStyleServiceType[] | null;
  companyHasBudget?: IVendorCompanyHasBudgetType[] | null;
  companyHasTalentGroup?: IVendorCompanyHasTalentGroupType[] | null;
  companyHasLanguage?: IVendorCompanyHasLanguageType[] | null;
  primaryStyleServiceProfiles?: IVendorCompanyStyleServiceProfileType[] | null;
  additionalStyleServiceProfiles?:
    | IVendorCompanyStyleServiceProfileType[]
    | null;
  managerCount: number;
  submissionCount: number;
  is_having_freelance: boolean;
  is_added_by_admin: boolean;
  is_blocked: boolean;
  female_owned_company: boolean;
  minority_owned_company: boolean;
  veteran_owned_company: boolean;
  unclaimed_admin_name: string | null;
  unclaimed_admin_email: string | null;
  unclaimed_emails: string[] | null;
  companyTalentTypes: string | null;
  territory: IStateType[] | null;
  status: IVendorCompanyStatusEnum;
  created_at: Date;
  updated_at: Date;
  general_market_advertising: boolean;
  multicultural_advertising: boolean;
  music_video: boolean;
  certified_small_business: boolean;
  lgbt_owned_business: boolean;
}

export interface IVendorCompanyClaimRequestType {
  company: IVendorCompanyType;
  user: IUserType;
  created_at: Date;
  updated_at: Date;
}

export interface IVendorCompanyHasPrimaryStyleServiceType {
  vendorCompany: IVendorCompanyType;
  talentType: INameUuidType;
  projectType: IProjectTypeType;
}

export interface IVendorCompanyStyleServiceProfileType {
  uuid: string;
  vendorCompany: IVendorCompanyType;
  talentType?: INameUuidType;
  creativeStyle?: INameUuidType;
  projectType: IProjectTypeType;
  reels?: IStyleServiceProfileReelType[];
  relevancy_ranking?: number;
  is_hidden: boolean;
}

export interface IVendorCompanyHasAdditionalStyleServiceType {
  vendorCompany: IVendorCompanyType;
  creativeStyle: INameUuidType;
  projectType: IProjectTypeType;
}

export interface IVendorCompanyHasBudgetType {
  vendorCompany: IVendorCompanyType;
  budgetRange: INameUuidType;
  projectType: IProjectTypeType;
}

export interface IVendorCompanyHasTalentGroupType {
  vendorCompany: IVendorCompanyType;
  talentGroup: INameUuidType;
  projectType: IProjectTypeType;
}

export interface IVendorCompanyHasLanguageType {
  vendorCompany: IVendorCompanyType;
  language: INameUuidType;
  projectType: IProjectTypeType;
}
export interface IVendorCompanyProfileHasSalesrepRelationType {
  states: IStateType[];
  vendorProfile: IVendorCompanyProfileType;
  vendorRelation: IVendorSalesRepRelationType;
}

export interface IVendorCompanyProfileType {
  uuid: string;
  company_bio: string;
  projectType: IProjectTypeType | null;
  reel_link: string;
  is_reel_upload: boolean;
  is_profile_completion: boolean;
  is_primary: boolean;
  profileRelationWithSalesrep?: IVendorCompanyProfileHasSalesrepRelationType[];
  created_at: Date;
  updated_at: Date;
}

export interface IVendorCompanyLocationType {
  uuid: string;
  state: IStateType;
  company: IVendorCompanyType;
  region: IRegionType;
  mailing_address1: string;
  mailing_address2: string;
  city: string;
  zip: number;
  is_default: boolean;
  created_at: Date;
  updated_at: Date;
}

export interface IVendorCompanyContactType {
  uuid: string;
  company: IVendorCompanyType;
  name: string;
  title: string;
  email: string;
  phone_number?: string;
  created_at: Date;
  updated_at: Date;
}

export interface IUpdateVendorCompanyFormType {
  vendor_company_id: string;
  company_name: string;
  company_project_type: string;
  company_url: string;
  company_bio: string;
  company_logo: string;
  unclaimed_emails: string[];
}
