import { gql } from '@apollo/client';

export const mUpdateClientBudgetRange = gql`
  mutation updateClientBudgetRange(
    $id: ID!
    $name: String!
    $short_description: String
    $long_description: String
    $sort_order: Int!
    $vendor_budget_range_ids: [ID!]!
  ) {
    updateClientBudgetRange(
      id: $id
      name: $name
      short_description: $short_description
      long_description: $long_description
      sort_order: $sort_order
      vendor_budget_range_ids: $vendor_budget_range_ids
    )
  }
`;

export interface mUpdateClientBudgetRangeVars {
  id: string;
  name: string;
  short_description: string;
  long_description: string;
  sort_order: number;
  vendor_budget_range_ids: string[];
}

export interface mUpdateClientBudgetRangeRes {
  updateClientBudgetRange?: boolean;
}
