import { gql } from '@apollo/client';
import { IClientCompanyTypeEnum, IClientCompanyType } from 'types';

export const qGetAdminClients = gql`
  query getAdminClients(
    $limit: Int!
    $offset: Int!
    $company_type: ClientCompanyType!
    $company_name: String
    $sorts: [OrderByInput!]
  ) {
    getAdminClients(
      limit: $limit
      offset: $offset
      company_type: $company_type
      company_name: $company_name
      sorts: $sorts
    ) {
      total
      rows {
        uuid
        company_name
        created_at
        author {
          uuid
          name
          email
        }
        billing {
          base_seats
          add_seats
          renewal
          autorenew
          base_annual
          base_monthly
          add_monthly
        }
        projectCount
        ndaCount
      }
    }
  }
`;

export interface qGetAdminClientsRes {
  getAdminClients: {
    total: number;
    rows: IClientCompanyType[];
  };
}

export interface qGetAdminClientsVars {
  limit: number;
  offset: number;
  company_type: IClientCompanyTypeEnum;
  company_name?: string;
}

export interface qGetAdminClientsResTableDataType {
  uuid: string;
  company_name: string;
  seats: number | undefined;
  renewal: string;
}
