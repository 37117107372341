import { gql } from '@apollo/client';
import { IUploadFileType } from 'types';

export const mCreateFile = gql`
  mutation createFile($input: GetCreateFileInput!) {
    createFile(input: $input)
  }
`;

export interface mCreateFileRes {
  createFile?: string;
}

export interface mCreateFileVar {
  input: IUploadFileType;
}
