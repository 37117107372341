import { Suspense, lazy, StrictMode } from 'react';
import { render } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { ApolloProvider, setLogVerbosity } from '@apollo/client';
import { Provider } from 'react-redux';
import {
  ReelListProvider,
  ThemeProvider,
  UserInfoProvider,
  StyleServiceReelListProvider,
} from 'context';
import { client } from './graphql';
import { store } from './store';

setLogVerbosity('log');

const App = lazy(() => import('./App'));

render(
  <ApolloProvider client={client}>
    <BrowserRouter>
      <Suspense fallback={<div />}>
        <Provider store={store}>
          <StrictMode>
            <ThemeProvider>
              <UserInfoProvider>
                <StyleServiceReelListProvider>
                  <ReelListProvider>
                    <App />
                  </ReelListProvider>
                </StyleServiceReelListProvider>
              </UserInfoProvider>
            </ThemeProvider>
          </StrictMode>
        </Provider>
      </Suspense>
    </BrowserRouter>
  </ApolloProvider>,
  document.getElementById('root'),
);
