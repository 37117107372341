import * as Yup from 'yup';

export const zipValidationRequired = Yup.number()
  .max(99999, 'Invalid zip code!')
  .min(1, 'Invalid zip code!')
  .typeError('Invalid zip code!')
  .required('Zip is required!');

export const zipValidationOptional = Yup.number()
  .max(99999, 'Invalid zip code!')
  .min(1, 'Invalid zip code!')
  .typeError('Invalid zip code!');
