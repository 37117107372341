import { gql } from '@apollo/client';
import * as Yup from 'yup';
import {
  IAuthUserType,
  IProjectType,
  IProjectTypeType,
  IVendorCompanyTypeEnum,
} from 'types';
import { userAttributesString } from 'apis/auth';
import { projectErrors } from 'errors';

export const mCompleteVendorProfileBios = gql`
  mutation completeVendorProfileBios (
    $company: CompleteVendorCompanyDto!
  ) {
    completeVendorProfileBios(
      company: $company
    ) ${userAttributesString}
  }
`;

export interface mCompleteVendorProfileBiosRes {
  completeVendorProfileBios?: IAuthUserType;
}

export interface mCompleteVendorProfileBiosFormType {
  company: {
    key?: string;
    company_name: string;
    company_url: string;
    company_project_type_id: IProjectType | string;
    company_talent_type_id?: string;
    company_creative_style_ids?: string[];
    company_budget_range_id?: string;
    company_talent_group_ids?: string[];
    company_language_ids?: string[];
    company_bio?: string;
    reel_link?: string;
    is_reel_upload?: boolean;
  };
}

export const mCompleteVendorProfileBiosValidations = (
  pTypes: IProjectTypeType[],
) =>
  Yup.object().shape({
    company: Yup.object().shape({
      company_project_type_id: Yup.string().required(
        'Company type is required!',
      ),
      company_talent_type_id: Yup.string().when(
        ['company_project_type_id', 'company_type'],
        {
          is: (
            company_project_type_id: string,
            company_type: IVendorCompanyTypeEnum,
          ) => {
            const usedPtype = pTypes.find(
              (p) => company_project_type_id === p.uuid,
            );

            if (
              company_type === IVendorCompanyTypeEnum.COMPANY &&
              ((usedPtype?.is_more_data && usedPtype.freelance_label === '') ||
                (usedPtype?.is_more_data && usedPtype.freelance_label !== ''))
            )
              return true;
            return false;
          },
          then: (sch) => sch.required('Primary id is required!'),
        },
      ),
      company_creative_style_ids: Yup.array()
        .of(Yup.string())
        .when(['company_project_type_id', 'company_type'], {
          is: (
            company_project_type_id: string,
            company_type: IVendorCompanyTypeEnum,
          ) => {
            const usedPtype = pTypes.find(
              (p) => company_project_type_id === p.uuid,
            );

            if (
              company_type === IVendorCompanyTypeEnum.COMPANY &&
              ((usedPtype?.is_more_data && usedPtype.freelance_label === '') ||
                (usedPtype?.is_more_data && usedPtype.freelance_label !== ''))
            )
              return true;
            return false;
          },
          then: (sch) => sch.required(projectErrors.CREATIVE_STYLES_REQUIRED),
        }),
      company_budget_range_id: Yup.string().when(
        ['company_project_type_id', 'company_type'],
        {
          is: (
            company_project_type_id: string,
            company_type: IVendorCompanyTypeEnum,
          ) => {
            const usedPtype = pTypes.find(
              (p) => company_project_type_id === p.uuid,
            );

            if (
              company_type === IVendorCompanyTypeEnum.COMPANY &&
              ((usedPtype?.is_more_data && usedPtype.freelance_label === '') ||
                (usedPtype?.is_more_data && usedPtype.freelance_label !== ''))
            )
              return true;
            return false;
          },
          then: (sch) => sch.required('Budget range is required!'),
        },
      ),
      company_talent_group_ids: Yup.array()
        .of(Yup.string())
        .when(['company_project_type_id', 'company_type'], {
          is: (
            company_project_type_id: string,
            company_type: IVendorCompanyTypeEnum,
          ) => {
            const usedPtype = pTypes.find(
              (p) => company_project_type_id === p.uuid,
            );

            if (
              company_type === IVendorCompanyTypeEnum.COMPANY &&
              ((usedPtype?.is_more_data && usedPtype.freelance_label === '') ||
                (usedPtype?.is_more_data && usedPtype.freelance_label !== ''))
            )
              return true;
            return false;
          },
          then: (sch) =>
            sch
              .min(1, projectErrors.TALENT_GROUP_REQUIRED)
              .required(projectErrors.TALENT_GROUP_REQUIRED),
        }),
      company_language_ids: Yup.array()
        .of(Yup.string())
        .when(['company_project_type_id', 'company_type'], {
          is: (
            company_project_type_id: string,
            company_type: IVendorCompanyTypeEnum,
          ) => {
            const usedPtype = pTypes.find(
              (p) => company_project_type_id === p.uuid,
            );

            if (
              company_type === IVendorCompanyTypeEnum.COMPANY &&
              ((usedPtype?.is_more_data && usedPtype.freelance_label === '') ||
                (usedPtype?.is_more_data && usedPtype.freelance_label !== ''))
            )
              return true;
            return false;
          },
          then: (sch) =>
            sch
              .min(1, projectErrors.LANGUAGE_REQUIRED)
              .required(projectErrors.LANGUAGE_REQUIRED),
        }),
      company_bio: Yup.string(),
      company_name: Yup.string(),
      company_url: Yup.string(),
      reel_link: Yup.string(),
      is_reel_upload: Yup.boolean(),
    }),
  });
