import { gql } from '@apollo/client';
import * as Yup from 'yup';
import { IProjectTypeType, IRegionType, IVendorCompanyTypeEnum } from 'types';
import { zipValidationRequired } from 'types/Zip-validation.type';
import { passwordErrors } from 'errors';

export const mRegisterVendor = gql`
  mutation registerVendor(
    $user: VendorUserInputDto!
    $company: VendorCompanyDto!
    $companyLoc: VendorCompanyLocationDto!
  ) {
    registerVendor(user: $user, company: $company, companyLoc: $companyLoc)
  }
`;

export interface mRegisterVendorRes {
  registerVendor?: boolean;
}

export interface mRegisterVendorFormType {
  user: {
    username: string;
    email: string;
    phone_number: string;
    first_name: string;
    last_name: string;
    job_title: string;
    password: string;
    rpassword?: string;
  };
  companyLoc: {
    state_id: string;
    region: IRegionType | string;
    mailing_address1: string;
    mailing_address2?: string;
    city: string;
    zip: string | number;
  };
  company: {
    company_type: IVendorCompanyTypeEnum | string;
    company_primary_project_type_id: string | string[];
    company_project_type_ids: string[];
    company_primary_style_id?: string | string[] | null;
    company_additional_style_ids?: string[] | null;
    company_primary_service_id?: string | string[] | null;
    company_additional_service_ids?: string[] | null;
    company_name: string;
    company_url: string;
    company_territory?: string[];
    is_having_freelance: boolean;
    female_owned_company: boolean;
    minority_owned_company: boolean;
    veteran_owned_company: boolean;
    certified_small_business: boolean;
    lgbt_owned_business: boolean;
    independent_flag: {
      general_market_advertising: boolean;
      multicultural_advertising: boolean;
      music_video: boolean;
    };
  };
}

export const mRegisterVendorInitVals: mRegisterVendorFormType = {
  user: {
    username: '',
    password: '',
    rpassword: '',
    first_name: '',
    last_name: '',
    email: '',
    phone_number: '',
    job_title: '',
  },
  company: {
    company_name: '',
    company_type: '',
    company_url: '',
    company_primary_project_type_id: '',
    company_project_type_ids: [],
    is_having_freelance: false,
    female_owned_company: false,
    minority_owned_company: false,
    veteran_owned_company: false,
    certified_small_business: false,
    lgbt_owned_business: false,
    independent_flag: {
      general_market_advertising: false,
      multicultural_advertising: false,
      music_video: false,
    },
  },
  companyLoc: {
    mailing_address1: '',
    mailing_address2: '',
    state_id: '',
    region: '',
    zip: '',
    city: '',
  },
};

export const mRegisterVendorValidations = (pTypes: IProjectTypeType[]) =>
  Yup.object().shape({
    user: Yup.object().shape({
      username: Yup.string()
        .trim()
        .lowercase()
        .matches(
          /^(?=[a-zA-Z0-9._]{6,20}$)(?!.*[_.]{2})[^_.].*[^_.]$/,
          'Username not allowed spaces and should be minimum 6 character!',
        )
        .required('Username is required!'),
      password: Yup.string()
        .matches(
          /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/,
          passwordErrors.PASSWORD_VALIDATION,
        )
        .required(passwordErrors.PASSWORD_REQUIRED),
      rpassword: Yup.string()
        .oneOf([Yup.ref('password')], 'Password do not match!')
        .required('Password confirmation is required!'),
      first_name: Yup.string().required('First name is required!'),
      last_name: Yup.string().required('Last name is required!'),
      email: Yup.string()
        .trim()
        .lowercase()
        .email('Invalid email!')
        .required('Email is required!'),
      phone_number: Yup.string().matches(
        /^\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/,
        'Invalid mobile number!',
      ),
      job_title: Yup.string().required('Job title is required!'),
    }),
    company: Yup.object().shape({
      company_type: Yup.string()
        .oneOf([
          IVendorCompanyTypeEnum.COMPANY,
          IVendorCompanyTypeEnum.FREELANCER,
          IVendorCompanyTypeEnum.INDEPENDENT_REP,
        ])
        .required('Company type is required!'),
      company_name: Yup.string().required('Company name is required!'),
      company_url: Yup.string()
        .required('Url is required!')
        .matches(
          /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?\/?$/gm,
          'Invalid company url!',
        ),
      company_primary_project_type_id: Yup.string().when('company_type', {
        is: IVendorCompanyTypeEnum.COMPANY || IVendorCompanyTypeEnum.FREELANCER,
        then: Yup.string().required('Primary Capability is required!'),
      }),
      company_territory: Yup.array().of(Yup.string()),
      company_project_type_ids: Yup.array().of(Yup.string()),
      company_primary_style_id: Yup.string().when(
        [
          'company_primary_project_type_id',
          'company_project_type_ids',
          'company_type',
        ],
        {
          is: (
            company_primary_project_type_id: string,
            company_project_type_ids: string[],
            company_type: IVendorCompanyTypeEnum,
          ) => {
            const usedPtypes = pTypes.filter((p) =>
              [
                company_primary_project_type_id,
                ...company_project_type_ids,
              ].includes(p.uuid),
            );

            if (
              company_type === IVendorCompanyTypeEnum.COMPANY &&
              usedPtypes.find((p) => p.freelance_label !== '' && p.is_more_data)
            )
              return true;
            return false;
          },
          then: (sch) => sch.required('Primary style is required!'),
        },
      ),
      company_additional_style_ids: Yup.array().of(Yup.string()),
      company_primary_service_id: Yup.string().when(
        [
          'company_primary_project_type_id',
          'company_project_type_ids',
          'company_type',
        ],
        {
          is: (
            company_primary_project_type_id: string,
            company_project_type_ids: string[],
            company_type: IVendorCompanyTypeEnum,
          ) => {
            const usedPtypes = pTypes.filter((p) =>
              [
                company_primary_project_type_id,
                ...company_project_type_ids,
              ].includes(p.uuid),
            );

            if (
              company_type === IVendorCompanyTypeEnum.COMPANY &&
              usedPtypes.find((p) => p.freelance_label === '' && p.is_more_data)
            )
              return true;
            return false;
          },
          then: (sch) => sch.required('Primary service is required!'),
        },
      ),
      company_additional_service_ids: Yup.array().of(Yup.string()),
      is_having_freelance: Yup.boolean(),
      female_owned_company: Yup.boolean(),
      minority_owned_company: Yup.boolean(),
      veteran_owned_company: Yup.boolean(),
      certified_small_business: Yup.boolean(),
      lgbt_owned_business: Yup.boolean(),
      independent_flag: Yup.object().when('company_type', {
        is: IVendorCompanyTypeEnum.INDEPENDENT_REP,
        then: Yup.object()
          .shape({
            general_market_advertising: Yup.boolean(),
            multicultural_advertising: Yup.boolean(),
            music_video: Yup.boolean(),
          })
          .test(
            'at-least-one',
            'At least one checkbox must be selected',
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            (values) => {
              return (
                values.general_market_advertising ||
                values.multicultural_advertising ||
                values.music_video
              );
            },
          ),
        otherwise: Yup.object(),
      }),
    }),
    companyLoc: Yup.object().shape({
      state_id: Yup.string().required('State is required!'),
      region: Yup.string().required('Region is required!'),
      mailing_address1: Yup.string().required('Mailing address is required!'),
      mailing_address2: Yup.string(),
      city: Yup.string().required('City is required!'),
      zip: zipValidationRequired,
      // .matches(/(^\d{5}$)|(^\d{5}-\d{4}$)/, 'format not matching'),
    }),
  });
