import { gql } from '@apollo/client';

export const mUpdateBillingAlertInfo = gql`
  mutation updateBillingAlertInfo($id: ID!) {
    updateBillingAlertInfo(id: $id)
  }
`;

export interface mUpdateBillingAlertInfoVars {
  id: string;
}

export interface mUpdateBillingAlertInfoRes {
  updateBillingAlertInfo?: boolean;
}
