import { gql } from '@apollo/client';
import * as Yup from 'yup';

export const mForgotPassword = gql`
  mutation forgotPassword($email: String!) {
    forgotPassword(email: $email)
  }
`;

export interface mForgotPasswordRes {
  forgotPassword?: boolean;
}

export interface mForgotPasswordVar {
  email: string;
}

export const mForgotPasswordValidations = Yup.object().shape({
  email: Yup.string()
    .trim()
    .lowercase()
    .email('Invalid email!')
    .required('Email is required!'),
});
