import { gql } from '@apollo/client';
import {
  IVendorFreelanceRelationType,
  IVendorRelationshipChatType,
} from 'types';

export const qRelationWithVendor = gql`
  {
    relationWithVendor {
      relations {
        uuid
        relationProfileIds
        vendorCompany {
          uuid
          company_name
          company_logo
          primaryProfile {
            projectType {
              name
              vendor_label
              freelance_label
              client_label
              dashboard_label
            }
          }
          profiles {
            uuid
            projectType {
              uuid
              name
              vendor_label
              freelance_label
              client_label
              dashboard_label
            }
          }
          owner {
            name
          }
        }
      }
      invitees {
        uuid
        invitee_vendor_company_profile_ids
        inviteeCompany {
          company_name
          primaryProfile {
            projectType {
              name
              vendor_label
              freelance_label
              client_label
              dashboard_label
            }
          }
          profiles {
            uuid
            projectType {
              uuid
              name
              vendor_label
              freelance_label
              client_label
              dashboard_label
            }
          }
        }
      }
    }
  }
`;

export interface qRelationWithVendorRes {
  relationWithVendor?: {
    relations: IVendorFreelanceRelationType[];
    invitees: IVendorRelationshipChatType[];
  };
}
