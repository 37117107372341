import { gql } from '@apollo/client';
import { ITalentOverviewType } from 'types';
import { talentOverviewString } from './getFreelancerTalentOverview.api';

export const qGetFreelancerTalentOverviewById = gql`
  query getFreelancerTalentOverviewById($id: ID!) {
    getFreelancerTalentOverviewById(id: $id) {
      ${talentOverviewString}
    }
  }
`;

export interface qGetFreelancerTalentOverviewByIdRes {
  getFreelancerTalentOverviewById?: ITalentOverviewType;
}
