import { gql } from '@apollo/client';
import { INameUuidType } from 'types';

export const qTalentTypes = gql`
  query talentTypes($project_type_id: ID) {
    talentTypes(project_type_id: $project_type_id) {
      uuid
      name
    }
  }
`;

export interface qTalentTypesRes {
  talentTypes?: INameUuidType[];
}

export interface qTalentTypesVar {
  project_type_id?: string;
}
