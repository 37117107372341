import { gql } from '@apollo/client';
import {
  IUpdateVendorTalentOverviewFormType,
  IUpdateVendorTalentOverviewsFormType,
} from 'types';
import * as Yup from 'yup';

export const mUpdateVendorTalentSecondaryProfileBio = gql`
  mutation updateVendorTalentSecondaryProfileBio(
    $id: ID!
    $updateTalentBioDto: UpdateTalentOverviewDto!
  ) {
    updateVendorTalentSecondaryProfileBio(
      id: $id
      updateTalentBioDto: $updateTalentBioDto
    )
  }
`;

export interface mUpdateVendorTalentSecondaryProfileBioRes {
  updateVendorTalentSecondaryProfileBio?: boolean;
}

export interface mUpdateVendorTalentSecondaryProfileBioVars {
  id: string;
  updateTalentBioDto: IUpdateVendorTalentOverviewsFormType;
}

export const mUpdateVendorTalentSecondaryProfileBioInitVals: IUpdateVendorTalentOverviewFormType =
  {
    talent_name: '',
    bio: '',
    vendor_budget_range_id: '',
    talent_type_id: [],
    talent_group_ids: [],
    creative_style_ids: [],
    talent_language_ids: [],
    project_type_id: '',
  };

export const mUpdateVendorTalentSecondaryProfileBioValidations = () =>
  Yup.object().shape({
    talent_name: Yup.string(),
    talent_image: Yup.string(),
    bio: Yup.string(),
    project_type_id: Yup.string().required('Project type is required!'),
    creative_style_ids: Yup.array().of(Yup.string()),
  });
