import { gql } from '@apollo/client';

export const mAddSubmissionCommentPrivateLink = gql`
  mutation addSubmissionCommentPrivateLink(
    $id: ID!
    $comment: String!
    $team_project_id: ID!
  ) {
    addSubmissionCommentPrivateLink(
      id: $id
      comment: $comment
      team_project_id: $team_project_id
    )
  }
`;

export interface mAddSubmissionCommentPrivateLinkRes {
  addSubmissionCommentPrivateLink?: boolean;
}

export interface mAddSubmissionCommentPrivateLinkVars {
  id: string;
  comment: string;
  team_project_id: string;
}
