import { gql } from '@apollo/client';

export const mRemoveInvoice = gql`
  mutation removeInvoice($id: ID!) {
    removeInvoice(id: $id)
  }
`;

export interface mRemoveInvoiceRes {
  removeInvoice?: boolean;
}
