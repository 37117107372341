import { gql } from '@apollo/client';
import { IVendorCompanyType } from 'types';

export const qGetVendorCompanyyId = gql`
  query vendorCompanyByCompanyId($id: ID!) {
    vendorCompanyByCompanyId(id: $id) {
      uuid
      company_name
      company_logo
      company_url
      company_type
      rating
      is_having_freelance
      female_owned_company
      minority_owned_company
      veteran_owned_company
      relationalFreelancers {
        freelanceCompany {
          uuid
          company_name
          talentOverviews {
            uuid
            profiles {
              projectType {
                uuid
              }
            }
          }
        }
      }
      profiles {
        uuid
        company_bio
        reel_link
        is_reel_upload
        is_primary
        projectType {
          uuid
          name
          vendor_label
          freelance_label
          client_label
          dashboard_label
        }
        profileRelationWithSalesrep {
          states {
            uuid
            territory
            abbreviation
            name
          }
          vendorRelation {
            salesrepCompany {
              uuid
              company_name
            }
          }
        }
      }
      primaryProfile {
        uuid
        reel_link
        is_reel_upload
        projectType {
          uuid
          name
          vendor_label
          freelance_label
          client_label
          dashboard_label
        }
      }
      locations {
        uuid
        city
        region
        mailing_address1
        mailing_address2
        zip
        is_default
        state {
          uuid
          name
        }
      }
      contacts {
        uuid
        email
        name
        phone_number
      }
      talentOverviews {
        uuid
        talent_name
        talent_image
        company {
          uuid
          company_name
          owner {
            uuid
            name
          }
        }
        profiles {
          uuid
          is_primary
          bio
          projectType {
            uuid
            name
            vendor_label
            freelance_label
            client_label
            dashboard_label
          }
          profileRelationWithSalesrep {
            states {
              uuid
              territory
              abbreviation
              name
            }
            vendorRelation {
              salesrepCompany {
                uuid
                company_name
              }
            }
          }
          reels {
            uuid
            name
            video_code
            sort_order
          }
          talentGroups {
            uuid
            name
          }
          creativeStyles {
            uuid
            name
          }
          languages {
            uuid
            name
          }
          talentType {
            uuid
            name
          }
          vendorBudgetRange {
            uuid
            name
          }
        }
      }
      primaryStyleService {
        projectType {
          uuid
        }
        talentType {
          uuid
          name
        }
      }
      additionalStyleService {
        projectType {
          uuid
        }
        creativeStyle {
          uuid
          name
        }
      }
      companyHasBudget {
        projectType {
          uuid
        }
        budgetRange {
          uuid
          name
        }
      }
      companyHasTalentGroup {
        projectType {
          uuid
        }
        talentGroup {
          uuid
          name
        }
      }
      companyHasLanguage {
        projectType {
          uuid
        }
        language {
          uuid
          name
        }
      }
      primaryStyleServiceProfiles {
        uuid
        projectType {
          uuid
          freelance_label
          is_more_data
        }
        talentType {
          uuid
          name
        }
        vendorCompany {
          uuid
          company_name
        }
      }
      additionalStyleServiceProfiles {
        uuid
        projectType {
          uuid
          freelance_label
          is_more_data
        }
        vendorCompany {
          uuid
          company_name
        }
        creativeStyle {
          uuid
          name
        }
      }
    }
  }
`;

export interface qGetVendorCompanyByIdRes {
  vendorCompanyByCompanyId?: IVendorCompanyType;
}
