import { gql } from '@apollo/client';

export const mBlockUnblockClientCompany = gql`
  mutation blockUnblockClientCompany($id: ID!, $status: Boolean!) {
    blockUnblockClientCompany(id: $id, status: $status)
  }
`;

export interface mBlockUnblockClientCompanyVars {
  id: string;
  status: boolean;
}

export interface mBlockUnblockClientCompanyRes {
  blockUnblockClientCompany?: boolean;
}
