import { gql } from '@apollo/client';
import * as Yup from 'yup';
import { ITalentReelFormType } from 'types';

export const mTalentReel = gql`
  mutation talentReel($talentReelDto: TalentReelDto!) {
    talentReel(talentReelDto: $talentReelDto)
  }
`;

export interface mTalentReelRes {
  talentReel?: boolean;
}

export interface mTalentReelVars {
  talentReelDto: ITalentReelFormType;
}

export const mTalentReelInitVals: ITalentReelFormType = {
  name: '',
  video_code: '',
  talent_id: '',
};

export const mTalentReelValidations = Yup.object().shape({
  name: Yup.string().required('Talent reel name is required!'),
  video_code: Yup.string().required('Video code is required!'),
  talent_id: Yup.string().required('Talent is required!'),
});
