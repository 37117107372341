import { gql } from '@apollo/client';
import { IUploadFileType } from 'types';

export const mAddInternalChat = gql`
  mutation addInternalChat(
    $user_ids: [ID!]!
    $message: String!
    $attachments: [GetCreateFileInput!]
  ) {
    addInternalChat(
      user_ids: $user_ids
      message: $message
      attachments: $attachments
    )
  }
`;

export interface mAddInternalChatRes {
  addInternalChat?: boolean;
}

export interface mAddInternalChatVars {
  user_ids: string[];
  message: string;
  attachments?: IUploadFileType[];
}
