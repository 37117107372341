import { gql } from '@apollo/client';

export const mAddClientProjectChat = gql`
  mutation addClientProjectChat($id: ID!, $message: String!) {
    addClientProjectChat(id: $id, message: $message)
  }
`;

export interface mAddClientProjectChatRes {
  addClientProjectChat?: boolean;
}

export interface mAddClientProjectChatVars {
  id: string;
  message: string;
}
