import { gql } from '@apollo/client';

export const mRejectVendorCompany = gql`
  mutation rejectVendorCompany($id: ID!, $user_id: ID!) {
    rejectVendorCompany(id: $id, user_id: $user_id)
  }
`;

export interface mRejectVendorCompanyRes {
  rejectVendorCompany?: boolean;
}

export interface mRejectVendorCompanyVar {
  id: string;
  user_id: string;
}
