import { gql } from '@apollo/client';
import * as Yup from 'yup';

export const mSalesRepCompanyUpdate = gql`
  mutation salesRepUpdate($updateSalesRep: UpdateSalesRepCompanyDto!) {
    salesRepUpdate(updateSalesRep: $updateSalesRep)
  }
`;

export interface mSalesRepCompanyUpdateRes {
  salesRepUpdate?: boolean;
}

export interface mSalesRepCompanyUpdateFormVars {
  company_name: string;
  company_url?: string;
  company_bio?: string;
  company_logo?: string;
  independent_flag?: {
    general_market_advertising?: boolean;
    multicultural_advertising?: boolean;
    music_video?: boolean;
  };
}

export interface mSalesRepCompanyUpdateVars {
  updateSalesRep: mSalesRepCompanyUpdateFormVars;
}

export const mSalesRepCompanyUpdateValidations = Yup.object().shape({
  company_name: Yup.string().trim().required('Company name is required!'),
  company_url: Yup.string().matches(
    /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?\/?$/gm,
    'Invalid company url!',
  ),
  company_bio: Yup.string().trim(),
  company_logo: Yup.string().url('Invalid logo!'),
  independent_flag: Yup.object()
    .shape({
      general_market_advertising: Yup.boolean(),
      multicultural_advertising: Yup.boolean(),
      music_video: Yup.boolean(),
    })
    .test(
      'at-least-one',
      'At least one checkbox must be selected',
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      (values) => {
        return (
          values.general_market_advertising ||
          values.multicultural_advertising ||
          values.music_video
        );
      },
    ),
});
