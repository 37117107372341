import { gql } from '@apollo/client';
import { userAttributesString } from 'apis/auth';
import { IAuthUserType } from 'types';
import * as Yup from 'yup';

export const mAddStylesOrServices = gql`
  mutation addStylesOrServices(
  $project_type_id: ID!,
  $creative_style_ids: [ID!],
  $talent_type_id: ID!,
  $budget_range_id: ID!,
  $talent_group_ids: [ID!],
  $language_ids: [ID!]!,
  $is_style: Boolean!) {
    addStylesOrServices(
    project_type_id: $project_type_id,
    creative_style_ids: $creative_style_ids,
    talent_type_id: $talent_type_id,
    budget_range_id: $budget_range_id,
    talent_group_ids: $talent_group_ids,
    language_ids: $language_ids,
    is_style: $is_style
    ) ${userAttributesString}
  }
`;

export interface mAddStylesOrServicesRes {
  addStylesOrServices?: IAuthUserType;
}

export interface mAddStylesOrServicesVars {
  project_type_id: string;
  creative_style_ids?: string[];
  talent_type_id: string;
  budget_range_id: string;
  talent_group_ids?: string[];
  language_ids: string[];
  is_style: boolean;
}

export const mAddStylesOrServicesValidations = Yup.object().shape({
  project_type_id: Yup.string().required('Project type is required!'),
  talent_type_id: Yup.string().required('Talent type is required!'),
  creative_style_ids: Yup.array().of(Yup.string()),
  budget_range_id: Yup.string().required('Budget is required!'),
  talent_group_ids: Yup.array().of(Yup.string()),
  language_ids: Yup.array()
    .of(Yup.string().required('At least one language is required!'))
    .required('Language is required!'),
  is_style: Yup.bool(),
});
