import { gql } from '@apollo/client';
import { IInvoiceType } from 'types';

export const qInvoicesList = gql`
  query invoicesList(
    $client_id: ID
    $limit: Int!
    $ending_before: ID
    $starting_after: ID
    $show_paid: Boolean
  ) {
    invoicesList(
      client_id: $client_id
      limit: $limit
      ending_before: $ending_before
      starting_after: $starting_after
      show_paid: $show_paid
    ) {
      has_more
      rows {
        id
        status
        description
        amount_due
        amount_paid
        amount_remaining
        auto_advance
        metadata {
          request_id
          attachment
          title
        }
        due_date
        created_at
      }
    }
  }
`;

export interface qInvoicesListRes {
  invoicesList?: {
    has_more: boolean;
    rows: IInvoiceType[];
  };
}

export interface qInvoicesListVars {
  limit: number;
  ending_before?: string;
  starting_after?: string;
  client_id?: string;
  show_paid?: boolean;
}
