import { gql } from '@apollo/client';
import * as Yup from 'yup';

export const mShareNDAWithMangers = gql`
  mutation shareNDAWithMangers($shareNdaInput: ShareNDAWithMangerInput!) {
    shareNDAWithMangers(shareNdaInput: $shareNdaInput)
  }
`;

export interface mShareNDAWithMangersRes {
  shareNDAWithMangers?: boolean;
}

export interface mShareNDAWithMangersFormType {
  nda_id: string;
  managers: string[];
}

export interface mShareNDAWithMangersVar {
  shareNdaInput: mShareNDAWithMangersFormType;
}

export const mShareNDAWithMangersInitVals: mShareNDAWithMangersFormType = {
  nda_id: '',
  managers: [],
};

export const mShareNDAWithMangersInitValidations = Yup.object().shape({
  nda_id: Yup.string().required('Nda is required!'),
  managers: Yup.array()
    .of(Yup.string().required('Minimum one manager is required!'))
    .required('Managers are required!'),
});
