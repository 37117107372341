import { gql } from '@apollo/client';

export const mAddTeamMemberVotePrivateLink = gql`
  mutation addTeamMemberVotePrivateLink(
    $id: ID!
    $vote: Int!
    $team_project_id: ID!
  ) {
    addTeamMemberVotePrivateLink(
      id: $id
      vote: $vote
      team_project_id: $team_project_id
    )
  }
`;

export interface mAddTeamMemberVotePrivateLinkRes {
  addTeamMemberVotePrivateLink?: boolean;
}

export interface mAddTeamMemberVotePrivateLinkVars {
  id: string;
  vote: number;
  team_project_id: string;
}
