import { gql } from '@apollo/client';
import { IUpdateTalentOverviewFormType } from 'types';

export const mAdminUpdateTalentOverview = gql`
  mutation adminUpdateTalentOverview(
    $id: ID!
    $talentOverviewDto: AdminTalentOverviewDto!
  ) {
    adminUpdateTalentOverview(id: $id, talentOverviewDto: $talentOverviewDto)
  }
`;

export interface mAdminUpdateTalentOverviewRes {
  adminUpdateTalentOverview?: boolean;
}

export interface mAdminUpdateTalentOverviewVars {
  id: string;
  talentOverviewDto: IUpdateTalentOverviewFormType;
}
