import { gql } from '@apollo/client';
import { ITeamMemberHasProjectType } from 'types';

export const qGetProjectsListByTeamMember = gql`
  query getProjectsListByTeamMemberID($id: ID!) {
    getProjectsListByTeamMemberID(id: $id) {
      uuid
      project {
        uuid
        name
        created_at
      }
      teamMember {
        uuid
      }
    }
  }
`;

export interface qGetProjectsListByTeamMemberRes {
  getProjectsListByTeamMemberID?: ITeamMemberHasProjectType[];
}
