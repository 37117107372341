import { gql } from '@apollo/client';
import { IUserRole, IUserType } from 'types';

export const qGetAdminUsers = gql`
  query getAdminUsers(
    $limit: Int!
    $offset: Int!
    $search: String
    $role: String
    $sorts: [OrderByInput!]
  ) {
    getAdminUsers(
      limit: $limit
      offset: $offset
      search: $search
      role: $role
      sorts: $sorts
    ) {
      total
      rows {
        uuid
        first_name
        last_name
        name
        email
        role
        created_at
        phone_number
        username
        is_active
        company {
          uuid
          company_name
          company_type
        }
        vendorCompany {
          uuid
          company_name
          company_type
        }
      }
    }
  }
`;

export interface qGetAdminUsersRes {
  getAdminUsers: {
    total: number;
    rows: IUserType[];
  };
}

export interface qGetAdminUsersVars {
  limit: number;
  offset: number;
  search?: string;
  role?: IUserRole;
}

export interface qGetAdminUsersResTableDataType {
  uuid: string;
  email: string;
  username: string;
  name: string;
  phone_number: string;
  company_name: string;
  role: IUserRole;
  is_active: false;
}
