import { gql } from '@apollo/client';
import { ISettingDataType } from 'types';

export const qProjectTypesForAdmin = gql`
  {
    projectTypesForAdmin {
      uuid
      name
      unique_name
      short_description
      long_description
      sort_order
      is_active
      vendor_label
      freelance_label
      client_label
      dashboard_label
    }
  }
`;

export interface qProjectTypesForAdminRes {
  projectTypesForAdmin?: ISettingDataType[];
}
