import { gql } from '@apollo/client';
import { ITalentRatingFormType } from 'types';

export const mTalentRating = gql`
  mutation talentRating($ratingInput: TalentRatingDto!) {
    talentRating(ratingInput: $ratingInput)
  }
`;

export interface mTalentRatingRes {
  talentRating?: boolean;
}

export interface mTalentRatingVar {
  ratingInput: ITalentRatingFormType;
}
