import { gql } from '@apollo/client';

export const mCreateSubmissionSalesrep = gql`
  mutation createSubmissionSalesrep(
    $project_id: ID!
    $talents: [SubmissionTalentSalesrepDto!]!
  ) {
    createSubmissionSalesrep(project_id: $project_id, talents: $talents)
  }
`;

export interface mCreateSubmissionSalesrepRes {
  createSubmissionSalesrep?: boolean;
}

export interface mCreateSubmissionSalesrepVars {
  project_id: string;
  talents: {
    id?: string;
    uuid: string;
    company_id: string;
    note: string;
    reel_password: string;
    reel_link: string;
    is_talent?: boolean;
  }[];
}
