import { gql } from '@apollo/client';
import { IClientCompanyBillingType } from 'types';

export const qGetBillingInfo = gql`
  {
    getBillingInfo {
      uuid
      stripe_product_id
      renewal
      autorenew
      drop_count
      seats
      is_auto_pay
    }
  }
`;

export interface qGetBillingInfoRes {
  getBillingInfo?: IClientCompanyBillingType;
}
