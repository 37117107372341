import { ConnectionState, Message, Paginator } from '@twilio/conversations';
import { IChatType } from './store.type';

export enum ActionType {
  SET_CONNECTION_STATE = 'SET_CONNECTION_STATE',
  ADD_CHAT = 'ADD_CHAT',
  ADD_MESSAGE = 'ADD_MESSAGE',
  SET_ACTIVE_CHAT = 'SET_ACTIVE_CHAT',
  LOGOUT_CHAT = 'LOGOUT_CHAT',
  UPDATE_CHAT = 'UPDATE_CHAT',

  TYPING_STARTED = 'TYPING_STARTED',
  TYPING_ENDED = 'TYPING_ENDED',
}

interface AddChat {
  type: ActionType.ADD_CHAT;
  payload: IChatType;
}

interface AddMessage {
  type: ActionType.ADD_MESSAGE;
  payload: { message: Message; sendNotification: boolean };
}

interface SetActiveChat {
  type: ActionType.SET_ACTIVE_CHAT;
  payload: string;
}

interface SetConnectionState {
  type: ActionType.SET_CONNECTION_STATE;
  payload: ConnectionState;
}

interface LogOutAction {
  type: ActionType.LOGOUT_CHAT;
}

interface UpdateChat {
  type: ActionType.UPDATE_CHAT;
  payload: {
    chatSid: string;
    paginator: Paginator<Message>;
  };
}

interface TypingStarted {
  type: ActionType.TYPING_STARTED;
  payload: { channelSid: string; participant: string };
}

interface TypingEnded {
  type: ActionType.TYPING_ENDED;
  payload: { channelSid: string; participant: string };
}

export type Action =
  | SetConnectionState
  | AddChat
  | AddMessage
  | SetActiveChat
  | LogOutAction
  | UpdateChat
  | TypingStarted
  | TypingEnded;
