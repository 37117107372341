import { gql } from '@apollo/client';

export const mUpdateInvoice = gql`
  mutation updateInvoice(
    $id: String!
    $updateInvoiceInput: AdminCreateInvoiceClientRequestDto!
  ) {
    updateInvoice(id: $id, updateInvoiceInput: $updateInvoiceInput)
  }
`;

export interface IUpdateInvoiceFormType {
  client_company_id: string;
  price: number | string;
  due_date?: string;
  attachment: string;
  auto_charge: boolean;
}

export interface mUpdateInvoiceVars {
  id: string;
  updateInvoiceInput: IUpdateInvoiceFormType;
}

export interface mUpdateInvoiceRes {
  updateInvoice?: boolean;
}
