import {
  IClientCompanyBillingType,
  IInvoiceType,
  IUserEmailType,
  IUserPhoneType,
  IVendorFreelanceRelationType,
  IVendorSalesRepRelationType,
} from 'types';
import {
  IClientCompanyType,
  IClientCompanyTypeEnum,
} from './ClientCompany.type';
import { ITalentOverviewType } from './TalentOverview.type';
import {
  IVendorCompanyContactType,
  IVendorCompanyHasAdditionalStyleServiceType,
  IVendorCompanyHasPrimaryStyleServiceType,
  IVendorCompanyLocationType,
  IVendorCompanyProfileType,
  IVendorCompanyType,
  IVendorCompanyTypeEnum,
} from './VendorCompany.type';

export enum IUserRole {
  SUPER_ADMIN = 'SUPER_ADMIN',

  CLIENT_ADMIN = 'CLIENT_ADMIN',
  CLIENT_MANAGER = 'CLIENT_MANAGER',
  CLIENT_MEMBER = 'CLIENT_MEMBER',

  VENDOR_ADMIN = 'VENDOR_ADMIN',
  VENDOR_MANAGER = 'VENDOR_MANAGER',

  SALESREP_ADMIN = 'SALESREP_ADMIN',
  SALESREP_MANAGER = 'SALESREP_MANAGER',

  VENDOR_FREELANCER = 'VENDOR_FREELANCER',

  UNVERIFIED_VENDOR = 'UNVERIFIED_VENDOR',
}

export const vendorRoles = [
  IUserRole.VENDOR_ADMIN,
  IUserRole.VENDOR_MANAGER,
  IUserRole.SALESREP_ADMIN,
  IUserRole.SALESREP_MANAGER,
  IUserRole.VENDOR_FREELANCER,
];

export const clientRoles = [IUserRole.CLIENT_ADMIN, IUserRole.CLIENT_MANAGER];

export const adminRoles = [
  IUserRole.CLIENT_ADMIN,
  IUserRole.SALESREP_ADMIN,
  IUserRole.VENDOR_ADMIN,
];

export const clientRolesWithTeamM = [...clientRoles, IUserRole.CLIENT_MEMBER];

export const allRoles = [
  ...clientRolesWithTeamM,
  ...vendorRoles,
  IUserRole.SUPER_ADMIN,
];
export interface IAuthUserType {
  uuid: string | null;
  username: string | null;
  emails: [IUserEmailType] | null;
  phone_numbers: [IUserPhoneType] | null;
  role: IUserRole | null;
  name: string | null;
  company: {
    uuid: string | null;
    company_name: string | null;
    company_type: IClientCompanyTypeEnum | null;
    billing: IClientCompanyBillingType | null;
    lastPendingInvoice: IInvoiceType | null;
    admins: [IUserType] | null;
  };
  vendorCompany: {
    uuid: string | null;
    company_name: string | null;
    company_url: string | null;
    company_logo: string | null;
    company_type: IVendorCompanyTypeEnum | null;
    is_having_freelance: boolean | null;
    female_owned_company: boolean | null;
    minority_owned_company: boolean | null;
    veteran_owned_company: boolean | null;
    profilePending: boolean | null;
    primaryProfile: IVendorCompanyProfileType | null;
    profiles: IVendorCompanyProfileType[] | null;
    locations: IVendorCompanyLocationType[] | null;
    contacts: IVendorCompanyContactType[] | null;
    talentOverviews: ITalentOverviewType[] | null;
    relationalFreelancers: IVendorFreelanceRelationType[] | null;
    relationalVendors: IVendorSalesRepRelationType[] | null;
    primaryStyleService: IVendorCompanyHasPrimaryStyleServiceType[] | null;
    additionalStyleService:
      | IVendorCompanyHasAdditionalStyleServiceType[]
      | null;
  };
  is_reel_access: boolean | null;
  is_nda_access: boolean | null;
  is_reply_access: boolean | null;
  is_talent_access: boolean | null;
  is_temporary_password: boolean | null;
}

export interface IConfirmEmailType {
  confirmEmail: boolean;
  isClient: boolean;
}

export interface IApproveVendorEmailType {
  approveVendorEmail: boolean;
  isClient: boolean;
}
export interface IUserType {
  uuid: string;
  role: IUserRole;
  name: string;
  company?: IClientCompanyType;
  vendorCompany?: IVendorCompanyType;
  username: string;
  email: string;
  phone_number: string;
  emails: IUserEmailType[];
  phone_numbers: IUserPhoneType[];
  first_name: string;
  last_name: string;
  new_email?: string;
  is_deleted: boolean;
  is_active: boolean;
  is_reel_access: boolean;
  is_nda_access: boolean;
  is_reply_access: boolean;
  is_talent_access: boolean;
  is_temporary_password: boolean;
  is_newsletter_subscribe: boolean;
  created_at: Date;
  updated_at: Date;
}

export interface IUpdateEnterpriseAdminFormType {
  user_id: string;
  email: string;
  phone_number: string;
  first_name: string;
  last_name: string;
  role: IUserRole;
}

export interface IAllAdminUserType {
  uuid: string;
  name: string;
  email: string;
  phone_number: string;
  username: string;
  company_name: string;
  role: IUserRole;
  is_active: boolean;
  company?: IClientCompanyType | IVendorCompanyType | null;
}
