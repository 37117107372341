import { gql } from '@apollo/client';

export const mAddTeamMemberVote = gql`
  mutation addTeamMemberVote($id: ID!, $vote: Int!) {
    addTeamMemberVote(id: $id, vote: $vote)
  }
`;

export interface mAddTeamMemberVoteRes {
  addTeamMemberVote?: boolean;
}

export interface mAddTeamMemberVoteVars {
  id: string;
  vote: number;
}
