export const MaxWidthIframeParent = (width: number): number => {
  let dMaxWidth = 1150;

  switch (true) {
    case width >= 1240 && width < 1300:
      dMaxWidth = 1300;
      break;
    case width >= 1300 && width < 1400:
      dMaxWidth = 1350;
      break;
    case width >= 1400 && width < 1500:
      dMaxWidth = 1400;
      break;
    case width >= 1500 && width < 1600:
      dMaxWidth = 1500;
      break;
    case width >= 1600 && width < 1700:
      dMaxWidth = 1550;
      break;
    case width >= 1700 && width < 1800:
      dMaxWidth = 1650;
      break;
    case width >= 1800 && width < 1900:
      dMaxWidth = 1800;
      break;
    case width >= 1900 && width < 2000:
      dMaxWidth = 1850;
      break;
    case width >= 2000 && width < 2100:
      dMaxWidth = 1950;
      break;
    case width >= 2100 && width < 2200:
      dMaxWidth = 2050;
      break;
    case width >= 2200 && width < 2300:
      dMaxWidth = 2150;
      break;
    case width >= 2300 && width < 2400:
      dMaxWidth = 2250;
      break;
    case width >= 2400 && width < 2500:
      dMaxWidth = 2350;
      break;
    case width >= 2500 && width < 2600:
      dMaxWidth = 2450;
      break;
    case width >= 2600 && width < 2700:
      dMaxWidth = 2550;
      break;
    case width >= 2700 && width < 2800:
      dMaxWidth = 2650;
      break;
    case width >= 2800 && width < 2900:
      dMaxWidth = 2750;
      break;
    case width >= 2900 && width < 3000:
      dMaxWidth = 2850;
      break;
    case width >= 3000 && width < 3100:
      dMaxWidth = 2950;
      break;
    case width >= 3100 && width < 3200:
      dMaxWidth = 3050;
      break;
    case width >= 3200 && width < 3300:
      dMaxWidth = 3150;
      break;
    case width >= 3300 && width < 3400:
      dMaxWidth = 3250;
      break;
    case width >= 3400 && width < 3500:
      dMaxWidth = 3350;
      break;
    case width >= 3500 && width < 3600:
      dMaxWidth = 3450;
      break;
    case width >= 3600 && width < 3700:
      dMaxWidth = 3550;
      break;
    case width >= 3700 && width < 3800:
      dMaxWidth = 3650;
      break;
    default:
      dMaxWidth = 3700;
      break;
  }
  return dMaxWidth;
};
