import { gql } from '@apollo/client';
import { IInvoiceType } from 'types';

export const qGetInvoiceById = gql`
  query getInvoiceById($id: ID!) {
    getInvoiceById(id: $id) {
      uuid
      status
      description
      amount_due
      amount_paid
      amount_remaining
      metedata
      due_date
      created_at
    }
  }
`;

export interface mGetInvoiceByIdRes {
  getInvoiceById?: IInvoiceType;
}
