import { gql } from '@apollo/client';

export const mDeleteTalentReel = gql`
  mutation deleteTalentReel($id: ID!, $talent_id: ID!) {
    deleteTalentReel(id: $id, talent_id: $talent_id)
  }
`;

export interface mDeleteTalentReelRes {
  deleteTalentReel?: boolean;
}

export interface mDeleteTalentReelVars {
  id: string;
  talent_id: string;
}
