import { gql } from '@apollo/client';
import { IApproveVendorEmailType } from 'types';

export const mApproveVendorEmail = gql`
  mutation approveVendorEmail($token: String!) {
    approveVendorEmail(token: $token) {
      approveVendorEmail
      isClient
    }
  }
`;

export interface mApproveVendorEmailRes {
  approveVendorEmail?: IApproveVendorEmailType;
}

export interface mApproveVendorEmailVar {
  token: string;
}
