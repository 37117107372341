import { gql } from '@apollo/client';

export const mUpdateClientProfile = gql`
  mutation updateClientProfile($profile: UserClientProfileInput!) {
    updateClientProfile(profile: $profile)
  }
`;

export interface mUpdateClientProfileRes {
  updateClientProfile?: boolean;
}

export interface mUpdateClientProfileFormVars {
  first_name: string;
  last_name: string;
  profile_pic?: string;
  company_id?: string;
  company_name?: string;
  mailing_address1?: string;
  mailing_address2?: string;
  city?: string;
  state_id?: string;
  state_name?: string;
  zip?: number | string;
}

export interface mUpdateClientProfileVars {
  profile: mUpdateClientProfileFormVars;
}
