import { gql } from '@apollo/client';
import { IVendorCompanyStyleServiceProfileType } from 'types';

export const qGetStyleServiceProfileById = gql`
  query getStyleServiceProfileById($id: ID!) {
    getStyleServiceProfileById(id: $id) {
      uuid
      is_hidden
      reels {
        uuid
        name
        video_code
        sort_order
      }
      projectType {
        uuid
        name
        vendor_label
        freelance_label
        is_more_data
      }
      talentType {
        uuid
        name
      }
      creativeStyle {
        uuid
        name
      }
      vendorCompany {
        uuid
        company_name
        company_logo
        company_url
        company_type
        primaryStyleService {
          projectType {
            uuid
          }
          talentType {
            uuid
            name
          }
        }
        additionalStyleService {
          projectType {
            uuid
          }
          creativeStyle {
            uuid
            name
          }
        }
        companyHasBudget {
          projectType {
            uuid
          }
          budgetRange {
            uuid
            name
          }
        }
        companyHasTalentGroup {
          projectType {
            uuid
          }
          talentGroup {
            uuid
            name
          }
        }
        companyHasLanguage {
          projectType {
            uuid
          }
          language {
            uuid
            name
          }
        }
        profiles {
          uuid
          company_bio
          reel_link
          is_reel_upload
          is_primary
          profileRelationWithSalesrep {
            states {
              uuid
              territory
              abbreviation
              name
            }
            vendorRelation {
              salesrepCompany {
                uuid
                company_name
              }
            }
          }
          projectType {
            uuid
            name
            vendor_label
            freelance_label
            client_label
            dashboard_label
          }
        }
        talentOverviews {
          uuid
          talent_name
          talent_image
          company {
            uuid
            owner {
              uuid
              name
            }
          }
          profiles {
            uuid
            is_primary
            bio
            projectType {
              uuid
              name
              vendor_label
              freelance_label
              client_label
              dashboard_label
            }
            reels {
              uuid
              name
              video_code
              sort_order
            }
            talentGroups {
              uuid
              name
            }
            creativeStyles {
              uuid
              name
            }
            languages {
              uuid
              name
            }
            talentType {
              uuid
              name
            }
            vendorBudgetRange {
              uuid
              name
            }
          }
        }
      }
    }
  }
`;

export interface qGetStyleServiceProfileByIdRes {
  getStyleServiceProfileById?: IVendorCompanyStyleServiceProfileType;
}
