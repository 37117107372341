import { gql } from '@apollo/client';

export const mCreatePrivateLinkAccount = gql`
  mutation createPrivateLinkAccount($username: String!, $id: ID) {
    createPrivateLinkAccount(username: $username, id: $id)
  }
`;

export interface mCreatePrivateLinkAccountRes {
  createPrivateLinkAccount?: string;
}

export interface mCreatePrivateLinkAccountVar {
  username: string;
  id?: string;
}
