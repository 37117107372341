import { gql } from '@apollo/client';

export const mCreateSubmissionExternal = gql`
  mutation createSubmissionExteranl($submissionDto: SubmissionExternalDto!) {
    createSubmissionExteranl(submissionDto: $submissionDto)
  }
`;

export interface mCreateSubmissionExternalRes {
  createSubmissionExteranl?: boolean;
}

export interface mCreateSubmissionExternalVars {
  submissionDto: {
    project_id: string;
    talent_name: string;
    company_name: string;
    contact_email: string;
    reel_link: string;
    reel_password: string;
    note: string;
    talent_file_info?: string[][];
  };
}
