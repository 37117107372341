import { gql } from '@apollo/client';
import { ITeamMemberType } from 'types';

export const qGetTeamMembers = gql`
  query getTeamMembers($id: String!) {
    getTeamMembers {
      uuid
      private_user_name
      invite_email
      user {
        name
      }
    }

    getProjectExistingTeamMembers(id: $id)
  }
`;

export interface qGetTeamMembersRes {
  getTeamMembers?: ITeamMemberType[];
  getProjectExistingTeamMembers: string[];
}

export interface qGetTeamMembersVars {
  id: string;
}
