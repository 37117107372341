import { IClientCompanyBillingType } from 'types/ClientCompanyBilling.type';
import { IUserType } from 'types/User.type';
import { IStateType } from './State.type';

export enum IClientCompanyTypeEnum {
  PAYASGO = 'PAYASGO',
  ANNUAL = 'ANNUAL',
  ENTERPRISE = 'ENTERPRISE',
}

export enum IEnterpriseClientCompanyStatusTypeEnum {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export interface IClientCompanyType {
  uuid: string;
  state: IStateType;
  company_name: string;
  company_type: IClientCompanyTypeEnum;
  mailing_address1: string;
  mailing_address2: string;
  city: string;
  zip: number;
  is_approve: boolean;
  author: IUserType;
  members: IUserType[];
  admins: IUserType[];
  projectCount: number;
  anonymousCount: number;
  ndaCount: number;
  billing: IClientCompanyBillingType;
  is_blocked: boolean;
  is_registered: boolean;
  created_at: Date;
  updated_at: Date;
}

export interface IUpdateClientCompanyFormType {
  client_company_id: string;
  company_name: string;
  mailing_address1: string;
  mailing_address2?: string;
  state_id: string;
  city: string;
  zip: string | number;
}

export interface IUpdateEnterpriseClientCompanyFormType {
  client_company_id: string;
  billing_id: string;
  base_seats: number;
  base_monthly: number;
  add_seats: number;
  add_monthly: number;
  base_annual: number;
  start_date: string;
  renewal: string;
}
