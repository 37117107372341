import { gql } from '@apollo/client';
import { IUpdateEnterpriseClientCompanyFormType } from 'types';

export const mAdminUpdateEnterpriseClientAccount = gql`
  mutation adminUpdateEnterpriseClientAccount(
    $updateClientAccount: AdminUpdateEnterpriseClientAccountDto!
  ) {
    adminUpdateEnterpriseClientAccount(
      updateClientAccount: $updateClientAccount
    )
  }
`;

export interface mAdminUpdateEnterpriseClientAccountVars {
  updateClientAccount: IUpdateEnterpriseClientCompanyFormType;
}

export interface mAdminUpdateEnterpriseClientAccountRes {
  adminUpdateEnterpriseClientAccount?: boolean;
}
