import { gql } from '@apollo/client';
import * as Yup from 'yup';

export const mVendorStyleServiceProfileWithReel = gql`
  mutation vendorStyleServiceProfileWithReel(
    $project_type_id: ID!
    $talent_type_id: ID
    $creative_style_id: ID
    $profileReel: [VendorCompanyStyleServiceProfileWithReelDto!]!
  ) {
    vendorStyleServiceProfileWithReel(
      project_type_id: $project_type_id
      talent_type_id: $talent_type_id
      creative_style_id: $creative_style_id
      profileReel: $profileReel
    )
  }
`;

export interface mVendorStyleServiceProfileWithReelRes {
  vendorStyleServiceProfileWithReel?: boolean;
}

export interface mVendorStyleServiceProfileWithReelFormType {
  project_type_id: string;
  talent_type_id?: string;
  creative_style_id?: string;
  profileReel: {
    uuid?: string;
    name: string;
    video_code: string;
    sort_order?: number;
  }[];
}

export const mVendorStyleServiceProfileWithReelInitVals: mVendorStyleServiceProfileWithReelFormType =
  {
    project_type_id: '',
    profileReel: [],
  };

export const mVendorStyleServiceProfileWithReelValidations = () =>
  Yup.object().shape({
    project_type_id: Yup.string().required('Project type is required!'),
    talent_type_id: Yup.string(),
    creative_style_id: Yup.string(),
    profileReel: Yup.array()
      .of(
        Yup.object().shape({
          uuid: Yup.string(),
          name: Yup.string().required('Name is required!'),
          video_code: Yup.string().required('Video is required!'),
          sort_order: Yup.number(),
        }),
      )
      .min(1, 'Atleast one reel is required!'),
  });
