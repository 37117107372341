import { gql } from '@apollo/client';

export const mLogout = gql`
  mutation logout {
    logout
  }
`;

export interface mLogoutRes {
  logout?: boolean;
}
