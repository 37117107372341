import { gql } from '@apollo/client';
import { passwordErrors } from 'errors';
import * as Yup from 'yup';

export const mRegisterVendorUser = gql`
  mutation registerVendorUser(
    $user: VendorUserInputDto!
    $company: VendorCompanyHavingFreelanceDto!
    $vendor_company_id: ID!
  ) {
    registerVendorUser(
      user: $user
      company: $company
      vendor_company_id: $vendor_company_id
    )
  }
`;

export interface mRegisterVendorUserRes {
  registerVendorUser?: boolean;
}

export interface mRegisterVendorUserFormType {
  user: {
    username: string;
    email: string;
    phone_number: string;
    first_name: string;
    last_name: string;
    job_title: string;
    password: string;
    rpassword?: string;
  };
  company: {
    is_having_freelance: boolean;
    company_territory?: string[];
    female_owned_company: boolean;
    minority_owned_company: boolean;
    veteran_owned_company: boolean;
    general_market_advertising: boolean;
    multicultural_advertising: boolean;
    music_video: boolean;
  };
  vendor_company_id: string;
}

export const mRegisterVendorUserInitVals: mRegisterVendorUserFormType = {
  user: {
    username: '',
    password: '',
    rpassword: '',
    first_name: '',
    last_name: '',
    email: '',
    phone_number: '',
    job_title: '',
  },
  company: {
    is_having_freelance: false,
    female_owned_company: false,
    minority_owned_company: false,
    veteran_owned_company: false,
    general_market_advertising: false,
    multicultural_advertising: false,
    music_video: false,
  },
  vendor_company_id: '',
};

export const mRegisterVendorUserValidations = (type: string) =>
  Yup.object().shape({
    user: Yup.object().shape({
      username: Yup.string()
        .trim()
        .lowercase()
        .matches(
          /^(?=[a-zA-Z0-9._]{6,20}$)(?!.*[_.]{2})[^_.].*[^_.]$/,
          'Username not allowed spaces and should be minimum 6 character!',
        )
        .required('Username is required!'),
      password: Yup.string()
        .matches(
          /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/,
          passwordErrors.PASSWORD_VALIDATION,
        )
        .required(passwordErrors.PASSWORD_REQUIRED),
      rpassword: Yup.string()
        .oneOf([Yup.ref('password')], 'Password do not match!')
        .required('Password confirmation is required!'),
      first_name: Yup.string().required('First name is required!'),
      last_name: Yup.string().required('Last name is required!'),
      email: Yup.string()
        .trim()
        .lowercase()
        .email('Invalid email!')
        .required('Email is required!'),
      phone_number: Yup.string().matches(
        /^\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/,
        'Invalid mobile number!',
      ),
      job_title: Yup.string().required('Job title is required!'),
    }),
    company: Yup.object()
      .shape({
        is_having_freelance: Yup.boolean(),
        company_territory: Yup.array().of(Yup.string()),
        female_owned_company: Yup.boolean(),
        minority_owned_company: Yup.boolean(),
        veteran_owned_company: Yup.boolean(),
        general_market_advertising: Yup.boolean(),
        multicultural_advertising: Yup.boolean(),
        music_video: Yup.boolean(),
      })
      .test(
        'at-least-one-required',
        'At least one rep market type is required!',
        // eslint-disable-next-line func-names, @typescript-eslint/ban-ts-comment
        // @ts-ignore
        function (company) {
          if (type !== 'Rep') return true;
          return (
            company?.general_market_advertising ||
            company?.multicultural_advertising ||
            company?.music_video
          );
        },
      ),
    vendor_company_id: Yup.string().required('Vendor company is required!'),
  });
