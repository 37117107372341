import { gql } from '@apollo/client';

export const maddProfessions = gql`
  mutation addProfessions($profession_info: [[String!]!]) {
    addProfessions(profession_info: $profession_info)
  }
`;

export interface maddProfessionsVars {
  profession_info: string[][];
}

export interface maddProfessionsRes {
  addProfessions?: boolean;
}
