import { gql } from '@apollo/client';
import { INameUuidType } from 'types';

export const qTalentGroups = gql`
  query talentGroups($project_type_id: ID) {
    talentGroups(project_type_id: $project_type_id) {
      uuid
      name
      is_company_visible
    }
  }
`;

export interface qTalentGroupsRes {
  talentGroups?: INameUuidType[];
}

export interface qTalentGroupsVar {
  project_type_id?: string;
}
