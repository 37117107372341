import { gql } from '@apollo/client';

export const mUpdateProjectTypeHasLanguages = gql`
  mutation updateProjectTypeHasLanguages(
    $project_type_id: ID!
    $language_ids: [ID!]!
  ) {
    updateProjectTypeHasLanguages(
      project_type_id: $project_type_id
      language_ids: $language_ids
    )
  }
`;

export interface mUpdateProjectTypeHasLanguagesVars {
  project_type_id: string;
  language_ids: string[];
}

export interface mUpdateProjectTypeHasLanguagesRes {
  updateProjectTypeHasLanguages?: boolean;
}
