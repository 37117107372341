import {
  createContext,
  ReactElement,
  ReactNode,
  useContext,
  useEffect,
} from 'react';
import { Client as ConversationsClient, Message } from '@twilio/conversations';
import { IAttributesType } from 'types';
import { useMutation } from '@apollo/client';
import {
  qGetTwilioAccessToken,
  qGetTwilioAccessTokenRes,
  qGetTwilioAccessTokenVars,
} from 'apis';
import { customError, sendError } from 'helpers';
import { useDispatch } from 'react-redux';
import {
  addMessage,
  setConnectionState,
  addChat,
} from 'store/actions/chat.action';
import { IChatType } from 'store/types';
import { useAppSelector } from 'store';
import { UserInfoContext } from './UserInfoContext';

type MessageCenterListContext = Record<string, string>;

const defaultContextValue: MessageCenterListContext = {};

export const MessageCenterContext =
  createContext<MessageCenterListContext>(defaultContextValue);

export const MessageCenterProvider = ({
  children,
}: {
  children: ReactNode;
}): ReactElement => {
  const {
    user: { uuid },
  } = useContext(UserInfoContext);

  const dispatch = useDispatch();

  const [fetchAssessToken] = useMutation<
    qGetTwilioAccessTokenRes,
    qGetTwilioAccessTokenVars
  >(qGetTwilioAccessToken);
  const { connectionStatus } = useAppSelector((state) => state.chat);

  const connectTwilio = async () => {
    if (!uuid) return;

    const { data } = await fetchAssessToken({ variables: { identity: uuid } });
    if (!data?.getTwilioAccessToken) throw customError();

    const token = data.getTwilioAccessToken;
    const client = new ConversationsClient(token);

    client.on('connectionStateChanged', (state) => {
      dispatch(setConnectionState(state));
    });

    client.on('conversationJoined', async (conversation) => {
      const participants = await conversation.getParticipants();

      // const users = await Promise.all(
      //   participants.map((participant) => participant.getUser()),
      // );

      let setIndex = false;
      const users = await Promise.all(
        participants.map((participant) => {
          participant
            .getUser()
            .then((currentUser) => {
              if (
                currentUser.identity === uuid &&
                (participant.lastReadMessageIndex === null ||
                  participant.lastReadMessageIndex === -1)
              ) {
                setIndex = true;
                conversation
                  .updateLastReadMessageIndex(-1)
                  .then(() => {})
                  .catch(() => {});
              }
            })
            .catch(() => {});
          return participant.getUser();
        }),
      );

      const paginator = await conversation.getMessages();
      let counter = 0;
      paginator.items.forEach((msg) => {
        if (msg.author !== uuid) counter += 1;
      });
      const totalMessagesCount = await conversation.getMessagesCount();
      const unreadMessagesCount =
        (await conversation.getUnreadMessagesCount()) ||
        (setIndex ? counter : 0);

      const chat: IChatType = {
        conversation,
        users,
        paginator,
        messages: paginator.items,
        sid: conversation.sid,
        totalMessagesCount,
        unreadMessagesCount,
        lastMessage: conversation.lastMessage,
        attributes: conversation.attributes as unknown as IAttributesType,
      };
      dispatch(addChat(chat));
    });

    client.on('messageAdded', (message) => {
      // eslint-disable-next-line func-names, @typescript-eslint/no-loop-func
      setTimeout(async function () {
        let counter = 0;
        let isSent = false;
        do {
          // eslint-disable-next-line no-promise-executor-return
          const timer = (ms: number | undefined) =>
            // eslint-disable-next-line no-promise-executor-return
            new Promise((res) => setTimeout(res, ms));
          // eslint-disable-next-line no-await-in-loop
          await timer(500);
          // eslint-disable-next-line @typescript-eslint/no-use-before-define
          isSent = checkForMessage(message);
          counter += 1;
        } while (counter < 5 && isSent === false);
      }, 50);
    });
  };

  function checkForMessage(message: Message) {
    let sent = false;
    try {
      dispatch(addMessage(message, message.author !== uuid));
      sent = true;
    } catch (ex) {
      sent = false;
    }
    return sent;
  }

  useEffect(() => {
    if (
      connectionStatus === 'unknown' ||
      connectionStatus === 'disconnecting' ||
      connectionStatus === 'disconnected'
    )
      connectTwilio().catch(sendError);
  }, [uuid, connectionStatus]); // eslint-disable-line

  return (
    <MessageCenterContext.Provider value={{}}>
      {children}
    </MessageCenterContext.Provider>
  );
};
