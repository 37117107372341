import { gql } from '@apollo/client';
import { SalesRepVendorRelationTerritoryFormType } from 'components/VendorSendInviteToSalesRep/VendorSendInviteToSalesRep.component';
import { IRelationshipInviteStatus } from 'types';

export const mUpdateVendorRelationshipMessage = gql`
  mutation updateVendorRelationshipMessage(
    $id: ID!
    $channel_id: ID!
    $status: RelationshipInviteStatus!
    $is_send_reel: Boolean
    $is_reply_message: Boolean
    $view_only: Boolean
    $not_viewable: Boolean
    $profile_ids: [ID!]
    $territory_info: [VendorSalesrepRalationshipChatTerritoryInput!]
    $general_market_advertising: Boolean
    $multicultural_advertising: Boolean
    $music_video: Boolean
  ) {
    updateVendorRelationshipMessage(
      id: $id
      channel_id: $channel_id
      status: $status
      is_send_reel: $is_send_reel
      is_reply_message: $is_reply_message
      view_only: $view_only
      not_viewable: $not_viewable
      profile_ids: $profile_ids
      territory_info: $territory_info
      general_market_advertising: $general_market_advertising
      multicultural_advertising: $multicultural_advertising
      music_video: $music_video
    )
  }
`;

export interface mUpdateVendorRelationshipMessageRes {
  updateVendorRelationshipMessage?: boolean;
}

export interface mUpdateVendorRelationshipMessageVars {
  id: string;
  channel_id: string;
  status: IRelationshipInviteStatus;
  is_send_reel?: boolean;
  is_reply_message?: boolean;
  view_only?: boolean;
  not_viewable?: boolean;
  profile_ids?: string[];
  territory_info?: SalesRepVendorRelationTerritoryFormType[];
  general_market_advertising?: boolean;
  multicultural_advertising?: boolean;
  music_video?: boolean;
}
