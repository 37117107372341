import { gql } from '@apollo/client';
import { projectErrors } from 'errors';
import {
  IFreelanceBioUpdateInput,
  IProjectTypeType,
  IUpdateVendorTalentOverviewsFormType,
} from 'types';
import * as Yup from 'yup';

export const mUpdateTalentSecondaryProfileBio = gql`
  mutation updateTalentSecondaryProfileBio(
    $id: ID!
    $updateBioDto: UpdateFreelanceTalentOverviewDto!
  ) {
    updateTalentSecondaryProfileBio(id: $id, updateBioDto: $updateBioDto)
  }
`;

export interface mUpdateTalentSecondaryProfileBioRes {
  updateTalentSecondaryProfileBio?: boolean;
}

export interface mUpdateTalentSecondaryProfileBioVars {
  id: string;
  updateBioDto: IUpdateVendorTalentOverviewsFormType;
}

export const mUpdateTalentSecondaryProfileBioInitVals: IFreelanceBioUpdateInput =
  {
    talent_name: '',
    bio: '',
    vendor_budget_range_id: '',
    talent_type_id: [],
    talent_group_ids: [],
    creative_style_ids: [],
    talent_language_ids: [],
    project_type_id: '',
  };

export const mUpdateTalentSecondaryProfileBioValidations = (
  pTypes: IProjectTypeType[],
) =>
  Yup.object().shape({
    talent_name: Yup.string().required('Name is required!'),
    talent_image: Yup.string(),
    bio: Yup.string().required('Bio is required!'),
    talent_type_id: Yup.string().required(projectErrors.TALENT_TYPE_REQUIRED),
    project_type_id: Yup.string().required('Project type is required!'),
    creative_style_ids: Yup.array()
      .of(Yup.string())
      .when(['project_type_id'], {
        is: (project_type_id: string) => {
          const usedPtype = pTypes.find((p) => project_type_id === p.uuid);

          if (!usedPtype?.is_more_data) return true;
          return false;
        },
        then: (sch) =>
          sch
            .min(1, 'Creative Styles are required!')
            .max(5, 'You can select up to 5 creative styles only!')
            .required(projectErrors.CREATIVE_STYLES_REQUIRED),
      }),
  });
