import { gql } from '@apollo/client';

export const mShareFavoriteVendorList = gql`
  mutation addShareVendorListChat($username: String!, $list_id: ID!) {
    addShareVendorListChat(username: $username, list_id: $list_id)
  }
`;

export interface mShareFavoriteVendorListRes {
  addShareVendorListChat?: boolean;
}

export interface mShareFavoriteVendorListVar {
  username: string;
  list_id: string;
}
