import { gql } from '@apollo/client';
import { IVendorRatingType } from 'types';

export const qMyRatingToVendor = gql`
  query myRatingToVendor($id: ID!) {
    myRatingToVendor(id: $id) {
      uuid
      rating
    }
  }
`;

export interface qMyRatingToVendorRes {
  myRatingToVendor?: IVendorRatingType;
}
