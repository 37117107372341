import { gql } from '@apollo/client';
import { ITalentReelUpdateFormType } from 'types';

export const mUpdateTalentReel = gql`
  mutation updateTalentReel($talentReelDto: TalentReelDto!, $id: ID!) {
    updateTalentReel(talentReelDto: $talentReelDto, id: $id)
  }
`;

export interface mUpdateTalentReelRes {
  updateTalentReel?: boolean;
}

export interface mUpdateTalentReelVars {
  talentReelDto: ITalentReelUpdateFormType;
  id: string;
}
