import { IClientProjectQueryChatType } from './ClientProjectQueryChat.type';
import { INdaRevisionChatType } from './NdaRevisionChat.type';
import { IShareFavoriteVendorChatType } from './ShareFavoriteVendorChat.type';
import { IUserType } from './User.type';
import { IVendorCompanyType } from './VendorCompany.type';
import { IVendorProjectChatType } from './VendorProjectChat.type';
import { IVendorRelationshipChatType } from './VendorRelationshipChat.type';

export enum IChatChannelTypeEnum {
  NDA_REVISION = 'NDA_REVISION',
  CLIENT_PROJECT_QUERY = 'CLIENT_PROJECT_QUERY',
  ENTERPRISE_REQUEST = 'ENTERPRISE_REQUEST',
  VENDOR_CONTACT = 'VENDOR_CONTACT',
  VENDOR_PROJECT_DISCUSSION = 'VENDOR_PROJECT_DISCUSSION',
  CLIENT_SHARE_LIST = 'CLIENT_SHARE_LIST',
  INTERNAL_CLIENT = 'INTERNAL_CLIENT',
  INTERNAL_VENDOR = 'INTERNAL_VENDOR',
  UPGRADE_REQUEST = 'UPGRADE_REQUEST',
  DOWNGRADE_REQUEST = 'DOWNGRADE_REQUEST',
  VENDOR_RELATIONSHIP = 'VENDOR_RELATIONSHIP',
  VENDOR_REGISTER = 'VENDOR_REGISTER',
  UNCLAIMED_VENDOR_REGISTER = 'UNCLAIMED_VENDOR_REGISTER',
}

export enum IChatFilterTypeEnum {
  NDA_REVISION = 'NDA_REVISION',
  SORT_BY_NAME = 'SORT_BY_NAME',
  SORT_BY_DATE = 'SORT_BY_DATE',
  SEARCH_NAME = 'SEARCH_NAME',
}

export interface IChatChannelType {
  uuid: string;
  name: string;
  members: IUserType[];
  vendorCompany: IVendorCompanyType;
  channel_type: IChatChannelTypeEnum;
  ndaRevisionChat: INdaRevisionChatType | null;
  vendorRelationshiopChat: IVendorRelationshipChatType | null;
  shareFavoriteVendorChat: IShareFavoriteVendorChatType | null;
  clientProjectChat: IClientProjectQueryChatType | null;
  vendorProjectChat: IVendorProjectChatType | null;
  created_at: string;
  updated_at: string;
}

export interface IAttributesType {
  channel_type: IChatChannelTypeEnum;
  chatId: string;
  createdBy: string;
}
