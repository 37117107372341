import { gql } from '@apollo/client';

export const mCreateClientBudgetRange = gql`
  mutation addClientBudgetRange(
    $clientBudgetRangeInput: ClientBudgetRangeInput!
  ) {
    addClientBudgetRange(clientBudgetRangeInput: $clientBudgetRangeInput)
  }
`;

export interface mCreateClientBudgetRangeRes {
  addClientBudgetRange?: boolean;
}

export interface mCreateClientBudgetRangeFormType {
  name: string;
  short_description?: string;
  long_description?: string;
  sort_order: number;
  vendor_budget_range_ids: string[];
}

export interface mCreateClientBudgetRangeVar {
  clientBudgetRangeInput: mCreateClientBudgetRangeFormType;
}
