import { gql } from '@apollo/client';
import * as Yup from 'yup';
import { zipValidationRequired } from 'types/Zip-validation.type';
import { IRegionType, IVendorCompanyTypeEnum } from 'types';

export const mAdminRegisterSalesRep = gql`
  mutation adminRegisterSalesRep(
    $company: VendorCompanyDto!
    $company_logo: String
    $companyLoc: [VendorCompanyLocationDto!]!
    $companyContact: [VendorCompanyContactDto!]!
  ) {
    adminRegisterSalesRep(
      company: $company
      company_logo: $company_logo
      companyLoc: $companyLoc
      companyContact: $companyContact
    )
  }
`;

export interface mAdminRegisterSalesRepRes {
  adminRegisterSalesRep?: boolean;
}

export interface mAdminRegisterSalesRepFormType {
  company_logo?: string;
  company: {
    company_name: string;
    company_type: IVendorCompanyTypeEnum | string;
    company_url: string;
    company_primary_project_type_id: string | string[];
    company_project_type_ids: string[];
    is_having_freelance: boolean;
    female_owned_company: boolean;
    minority_owned_company: boolean;
    veteran_owned_company: boolean;
  };
  companyLoc: {
    key?: string;
    state_id: string;
    region: IRegionType | string;
    mailing_address1: string;
    mailing_address2?: string;
    city: string;
    zip: string | number;
  }[];
  companyContact: {
    key?: string;
    name: string;
    title: string;
    email: string;
    phone_number?: string;
  }[];
}

export const mAdminRegisterSalesRepInitVals: mAdminRegisterSalesRepFormType = {
  company_logo: '',
  company: {
    company_name: '',
    company_type: '',
    company_url: '',
    company_primary_project_type_id: '' || [],
    company_project_type_ids: [],
    is_having_freelance: false,
    female_owned_company: false,
    minority_owned_company: false,
    veteran_owned_company: false,
  },
  companyLoc: [],
  companyContact: [],
};

export const mAdminRegisterSalesRepValidations = Yup.object().shape({
  company_logo: Yup.string(),
  company: Yup.object().shape({
    company_type: Yup.string()
      .oneOf([
        IVendorCompanyTypeEnum.COMPANY,
        IVendorCompanyTypeEnum.INDEPENDENT_REP,
      ])
      .required('Company type is required!'),
    company_name: Yup.string().required('Company name is required!'),
    company_url: Yup.string()
      .required('Url is required!')
      .matches(
        /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?\/?$/gm,
        'Invalid company url!',
      ),
    company_primary_project_type_id: Yup.string().when('company_type', {
      is: IVendorCompanyTypeEnum.COMPANY || IVendorCompanyTypeEnum.FREELANCER,
      then: Yup.string().required('Primary Company type is required!'),
    }),
    company_project_type_ids: Yup.array().of(Yup.string()),
    is_having_freelance: Yup.boolean(),
    female_owned_company: Yup.boolean(),
    minority_owned_company: Yup.boolean(),
    veteran_owned_company: Yup.boolean(),
  }),
  companyLoc: Yup.array()
    .of(
      Yup.object().shape({
        state_id: Yup.string().required('State is required!'),
        region: Yup.string().required('Region is required!'),
        mailing_address1: Yup.string().required('Mailing address is required!'),
        mailing_address2: Yup.string(),
        city: Yup.string().required('City is required!'),
        zip: zipValidationRequired,
      }),
    )
    .min(1, 'Atleast one location is required!'),
  companyContact: Yup.array().of(
    Yup.object().shape({
      name: Yup.string().required('Contact name is required!'),
      title: Yup.string().required('Contact title is required!'),
      email: Yup.string()
        .trim()
        .lowercase()
        .email('Invalid email!')
        .required('Contact email is required!'),
      phone_number: Yup.string(),
    }),
  ),
});
