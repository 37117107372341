import { IClientBudgetRangeType } from './ClientBudgetRange.type';
import { IClientCompanyType } from './ClientCompany.type';
import { IFavoriteVendorListType } from './FavoriteVendorList.type';
import { IUploadFileType } from './File.type';
import { INDAType, INDAVendorStatus } from './NDA.type';
import { INameUuidType } from './other.type';
import { IProjectTypeType } from './Project-type.type';
import { IStateType } from './State.type';
import { IUserType } from './User.type';

export enum IProjectStatus {
  Requested = 'Requested',
  Reviewing = 'Reviewing',
  Shortlisted = 'Shortlisted',
  FinalList = 'FinalList',
  Closed = 'Closed',
  Archived = 'Archived',
}

export enum IUpdateProjectStatus {
  Reviewing = 'Reviewing',
  Shortlisted = 'Shortlisted',
  FinalList = 'FinalList',
  Closed = 'Closed',
  Archived = 'Archived',
}

export enum RepType {
  GENERAL_MARKET_ADVERTISING = 'GENERAL_MARKET_ADVERTISING',
  MULTICULTURAL_ADVERTISING = 'MULTICULTURAL_ADVERTISING',
  MUSIC_VIDEO = 'MUSIC_VIDEO',
}

export interface IProjectFormType {
  client_budget_range_id?: string;
  project_type: string;
  rep_type: string;
  talent_type: string;
  languages: string[];
  talent_group: string[];
  creative_styles: string[];
  is_include_post: boolean;
  is_union_talent: boolean;
  is_include_insurance: boolean;
  is_include_audio: boolean;
  is_include_color: boolean;
  is_include_logo: boolean;
  is_shoot_firm: boolean;
  is_location_firm: boolean;
  is_anonymous: boolean;
  name: string;
  agency_state_id: string;
  agency_name: string;
  brand_name: string;
  location: string;
  budget: string;
  shoot_date: string;
  end_shoot_date: string;
  shoot_day: string;
  spots_length: string;
  due_date: string;
  description: string;
  creative_url: string;

  vs_is_all: boolean;
  vs_is_minority: boolean;
  vs_is_female: boolean;
  vs_is_east_coast: boolean;
  vs_is_west_coast: boolean;
  vs_is_veteran: boolean;
  vs_is_midwest: boolean;
  vs_is_freelance_talent: boolean;
  vs_is_vendor_rep: boolean;
  vs_is_sales_rep: boolean;
  vs_states: string[];
  vs_favorite_vendor_lists: string[];
}

export interface IUpdateProjectFormType {
  project_type: string;
  client_budget_range_id?: string;
  talent_type: string;
  languages: string[];
  talent_group: string[];
  creative_styles: string[];
  agency_state_id: string;
  is_include_post: boolean;
  is_union_talent: boolean;
  is_include_insurance: boolean;
  is_include_audio: boolean;
  is_include_color: boolean;
  is_include_logo: boolean;
  is_shoot_firm: boolean;
  is_location_firm: boolean;
  location: string;
  budget: string;
  agency_name: string;
  brand_name: string;
  shoot_date: string;
  end_shoot_date: string;
  shoot_day: string;
  spots_length: string;
  due_date: string;
  description: string;
  creative_url: string;

  vs_is_all: boolean;
  vs_is_minority: boolean;
  vs_is_female: boolean;
  vs_is_east_coast: boolean;
  vs_is_west_coast: boolean;
  vs_is_veteran: boolean;
  vs_is_midwest: boolean;
  vs_is_freelance_talent: boolean;
  vs_is_vendor_rep: boolean;
  vs_is_sales_rep: boolean;
  vs_states: string[];
  vs_favorite_vendor_lists: string[];
}

export interface IProjectType {
  uuid: string;
  clientBudgetRange: IClientBudgetRangeType;
  projectType: IProjectTypeType;
  talentType: INameUuidType;
  languages: INameUuidType[];
  talentGroups: INameUuidType[];
  creativeStyles: INameUuidType[];
  nda: INDAType | null;
  creativeScript: IUploadFileType | null;
  author: IUserType;
  company: IClientCompanyType;
  project_status: IProjectStatus;
  name: string;
  agency_name: string;
  agencyLocation: IStateType;
  brand_name: string;
  location: string;
  budget: string;
  shoot_date: Date | string;
  end_shoot_date: Date | string;
  shoot_day: string;
  spots_length: string;
  due_date: string;
  description: string;
  creative_url: string;
  submissionCount: number;
  reelCount: number;
  vs_is_all: boolean;
  vs_is_minority: boolean;
  vs_is_female: boolean;
  vs_is_east_coast: boolean;
  vs_is_west_coast: boolean;
  vs_is_veteran: boolean;
  vs_is_midwest: boolean;
  vs_is_freelance_talent: boolean;
  vs_is_vendor_rep: boolean;
  vs_is_sales_rep: boolean;
  vs_states: string[];
  vs_favorite_vendor_lists: string[];

  vendorLists: IFavoriteVendorListType[];
  stateLists: IStateType[];
  is_include_insurance: boolean;
  is_include_post: boolean;
  is_union_talent: boolean;
  is_include_audio: boolean;
  is_include_color: boolean;
  is_include_logo: boolean;
  is_shoot_firm: boolean;
  is_location_firm: boolean;
  is_anonymous: boolean;
  created_at: Date | string;
  updated_at: Date | string;
  rep_type: RepType;
}

export interface IVendorProjectType extends IProjectType {
  vendor_nda_status: INDAVendorStatus;
  is_new: boolean;
  is_opened: boolean;
  submission_date: Date | null;
  submission_by: string | null;
}

export interface IAllAdminProjectType {
  uuid: string;
  name: string;
  shoot_date: string;
  submissionCount: number;
  company_name: string;
  company?: IClientCompanyType | null;
}
