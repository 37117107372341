import { gql } from '@apollo/client';

export const mTransferOwnershipVendorCompany = gql`
  mutation transferOwnershipVendorCompany(
    $vendor_id: ID!
    $unclaimed_admin_name: String!
    $unclaimed_admin_email: String!
  ) {
    transferOwnershipVendorCompany(
      vendor_id: $vendor_id
      unclaimed_admin_name: $unclaimed_admin_name
      unclaimed_admin_email: $unclaimed_admin_email
    )
  }
`;

export interface mTransferOwnershipVendorCompanyRes {
  transferOwnershipVendorCompany?: boolean;
}

export interface mTransferOwnershipVendorCompanyVar {
  vendor_id: string;
  unclaimed_admin_name: string;
  unclaimed_admin_email: string;
}
