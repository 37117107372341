import { gql } from '@apollo/client';
import { IUserType } from 'types';

export const qGetVendorMembersById = gql`
  query getVendorMembersById(
    $limit: Int!
    $offset: Int!
    $vendor_company_id: ID!
    $sorts: [OrderByInput!]
  ) {
    getVendorMembersById(
      limit: $limit
      offset: $offset
      vendor_company_id: $vendor_company_id
      sorts: $sorts
    ) {
      total
      rows {
        uuid
        name
        role
        created_at
        vendorCompany {
          uuid
          company_type
          company_name
        }
      }
    }
  }
`;

export interface qGetVendorMembersByIdRes {
  getVendorMembersById: {
    total: number;
    rows: IUserType[];
  };
}

export interface qGetVendorMembersByIdVars {
  limit: number;
  offset: number;
  vendor_company_id: string;
}
