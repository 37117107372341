import { gql } from '@apollo/client';
import { SalesRepVendorRelationTerritoryFormType } from 'components/VendorSendInviteToSalesRep/VendorSendInviteToSalesRep.component';

export const mSendVendorRelationInvite = gql`
  mutation sendVendorRelationInvite(
    $id: ID!
    $message: String!
    $profile_ids: [ID!]
    $territory_info: [VendorSalesrepRalationshipChatTerritoryInput!]
    $is_reply_message: Boolean
    $is_send_reel: Boolean
    $view_only: Boolean
    $not_viewable: Boolean
    $general_market_advertising: Boolean
    $multicultural_advertising: Boolean
    $music_video: Boolean
  ) {
    sendVendorRelationInvite(
      id: $id
      message: $message
      profile_ids: $profile_ids
      territory_info: $territory_info
      is_reply_message: $is_reply_message
      is_send_reel: $is_send_reel
      view_only: $view_only
      not_viewable: $not_viewable
      general_market_advertising: $general_market_advertising
      multicultural_advertising: $multicultural_advertising
      music_video: $music_video
    )
  }
`;

export interface mSendVendorRelationInviteRes {
  sendVendorRelationInvite?: boolean;
}

export interface mSendVendorRelationInviteVars {
  id: string;
  message: string;
  profile_ids?: string[];
  territory_info?: SalesRepVendorRelationTerritoryFormType[];
  is_reply_message?: boolean;
  is_send_reel?: boolean;
  view_only?: boolean;
  not_viewable?: boolean;
  general_market_advertising?: boolean;
  multicultural_advertising?: boolean;
  music_video?: boolean;
}
