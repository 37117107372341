import { gql } from '@apollo/client';

export const mActiveDeactiveVendorBudgetRange = gql`
  mutation activeDeactiveVendorBudgetRange($id: ID!, $status: Boolean!) {
    activeDeactiveVendorBudgetRange(id: $id, status: $status)
  }
`;

export interface mActiveDeactiveVendorBudgetRangeVars {
  id: string;
  status: boolean;
}

export interface mActiveDeactiveVendorBudgetRangeRes {
  activeDeactiveVendorBudgetRange?: boolean;
}
