import { gql } from '@apollo/client';
import * as Yup from 'yup';
import { IAuthUserType } from 'types';
import { userAttributesString } from './refreshToken.api';

export const mLogin = gql`
  mutation login(
    $username: String!
    $password: String!
  ) {
    login(
      username: $username
      password: $password
    ) ${userAttributesString}
  }
`;

export interface mLoginRes {
  login?: IAuthUserType;
}

export interface mLoginFormType {
  username: string;
  password: string;
}

export const mLoginValidations = Yup.object().shape({
  username: Yup.string().required('Username is required!'),
  password: Yup.string().required('Password is required!'),
});
