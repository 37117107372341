import { gql } from '@apollo/client';

export const mAddFavoriteVendorList = gql`
  mutation addFavoriteVendorList($name: String!) {
    addFavoriteVendorList(name: $name)
  }
`;

export interface mAddFavoriteVendorListRes {
  addFavoriteVendorList?: boolean;
}

export interface mAddFavoriteVendorListVar {
  name: string;
}
