import { gql } from '@apollo/client';

export const mDeleteVendorUserByID = gql`
  mutation deleteVendorUserByID($isInvited: Boolean!, $id: ID!) {
    deleteVendorUserByID(isInvited: $isInvited, id: $id)
  }
`;

export interface mDeleteVendorUserByIDRes {
  deleteVendorUserByID?: boolean;
}

export interface mDeleteVendorUserByIDVars {
  id: string;
  isInvited: boolean;
}
