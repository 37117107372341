import { gql } from '@apollo/client';

export const mCancelRelationByFreelancer = gql`
  mutation cancelRelationByFreelancer($isInvited: Boolean!, $relation_id: ID!) {
    cancelRelationByFreelancer(isInvited: $isInvited, relation_id: $relation_id)
  }
`;

export interface mCancelRelationByFreelancerRes {
  cancelRelationByFreelancer?: boolean;
}

export interface mCancelRelationByFreelancerVars {
  isInvited: boolean;
  relation_id: string;
}
