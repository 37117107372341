import { gql } from '@apollo/client';
import { IPaginationVars, ISubmissionType } from 'types';

export const qGetSubmissionsByTalentId = gql`
  query getSubmissionsByTalentId(
    $id: ID!
    $limit: Int!
    $offset: Int!
    $status: String
    $profile_id: String
  ) {
    getSubmissionsByTalentId(
      id: $id
      limit: $limit
      offset: $offset
      status: $status
      profile_id: $profile_id
    ) {
      total
      rows {
        uuid
        created_at
        status
        vendorCompany {
          uuid
          company_name
          talentOverviewsCount
          owner {
            name
          }
        }
        project {
          uuid
          name
          projectType {
            uuid
            name
            freelance_label
          }
        }
      }
    }
  }
`;

export interface qGetSubmissionsByTalentIdRes {
  getSubmissionsByTalentId?: {
    total: number;
    rows: ISubmissionType[];
  };
}

export interface qGetSubmissionsByTalentIdVar extends IPaginationVars {
  id: string;
  status?: string;
  profile_id?: string;
}
