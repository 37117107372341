import { gql } from '@apollo/client';
import * as Yup from 'yup';
import { IStyleServiceProfileReelFormType } from 'types/StyleServiceProfile.type';

export const mStyleServiceReel = gql`
  mutation styleServiceReel(
    $profileReelDto: VendorCompanyStyleServiceProfileReelDto!
  ) {
    styleServiceReel(profileReelDto: $profileReelDto)
  }
`;

export interface mStyleServiceReelRes {
  styleServiceReel?: boolean;
}

export interface mStyleServiceReelVars {
  profileReelDto: IStyleServiceProfileReelFormType;
}

export const mStyleServiceReelInitVals: IStyleServiceProfileReelFormType = {
  name: '',
  video_code: '',
  vendor_style_service_profile_id: '',
};

export const mStyleServiceReelValidations = Yup.object().shape({
  name: Yup.string().required('Reel name is required!'),
  video_code: Yup.string().required('Video code is required!'),
  vendor_style_service_profile_id: Yup.string().required(
    'Profile is required!',
  ),
});
