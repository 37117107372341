import { gql } from '@apollo/client';
import { ITalentOverviewType } from 'types';

export const qTalentOverviews = gql`
  query talentOverviews($limit: Int!, $offset: Int!, $sorts: [OrderByInput!]) {
    talentOverviews(limit: $limit, offset: $offset, sorts: $sorts) {
      total
      rows {
        uuid
        talent_name
        allCurrentProjectCount
        allShortListProjectCount
        allTotalProjectCount
        primaryProfile {
          uuid
          reels {
            uuid
            name
            video_code
            sort_order
          }
          projectType {
            uuid
            name
            vendor_label
            freelance_label
            client_label
            dashboard_label
          }
          shortlistedProjectCount
          pastProjectCount
          currentProjectCount
          finalListProjectCount
          totalProjectCount
        }
        profiles {
          uuid
          reels {
            uuid
            name
            video_code
            sort_order
          }
          projectType {
            uuid
            name
            vendor_label
            freelance_label
            client_label
            dashboard_label
          }
          shortlistedProjectCount
          pastProjectCount
          currentProjectCount
          finalListProjectCount
          totalProjectCount
        }
      }
    }
  }
`;

export interface qTalentOverviewsRes {
  talentOverviews?: {
    total: number;
    rows: ITalentOverviewType[];
  };
}
