import { gql } from '@apollo/client';
import { mVendorLocationUpdateFormVars } from './vendorLocationUpdate.api';

export const mVendorLocationAdd = gql`
  mutation vendorLocationAdd($companyLocation: VendorCompanyLocationDto!) {
    vendorLocationAdd(companyLocation: $companyLocation)
  }
`;

export interface mVendorLocationAddRes {
  vendorLocationAdd?: boolean;
}

export interface mVendorLocationAddVars {
  companyLocation: mVendorLocationUpdateFormVars;
}

export const mVendorLocationAddInitVals: mVendorLocationUpdateFormVars = {
  city: '',
  mailing_address1: '',
  mailing_address2: '',
  zip: '',
  state_id: '',
  region: '',
};
