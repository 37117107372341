import { gql } from '@apollo/client';
import * as Yup from 'yup';
import { zipValidationRequired } from 'types/Zip-validation.type';
import {
  IAuthUserType,
  IProjectType,
  IProjectTypeType,
  IRegionType,
  IVendorCompanyTypeEnum,
} from 'types';
import { userAttributesString } from 'apis';
import { projectErrors } from 'errors';

export const mCompleteVendorProfile = gql`
  mutation completeVendorProfile (
    $company: CompleteVendorCompanyDto!
    $company_logo: String
    $companyLoc: [VendorCompanyLocationDto!]!
    $companyContact: [VendorCompanyContactDto!]!
  ) {
    completeVendorProfile(
      company: $company
      company_logo: $company_logo
      companyLoc: $companyLoc
      companyContact: $companyContact
    ) ${userAttributesString}
  }
`;

export interface mCompleteVendorProfileRes {
  completeVendorProfile?: IAuthUserType;
}

export interface mCompleteVendorProfileFormType {
  company_logo?: string;
  company: {
    company_name: string;
    company_url: string;
    company_project_type_id: IProjectType | string;
    company_talent_type_id?: string;
    company_creative_style_ids?: string[];
    company_budget_range_id?: string;
    company_talent_group_ids?: string[];
    company_language_ids?: string[];
    company_bio?: string;
    company_logo?: string;
    reel_link?: string;
    is_reel_upload?: boolean;
    company_type: string;
  };
  companyLoc: {
    key?: string;
    uuid?: string;
    state_id: string;
    region: IRegionType | string;
    mailing_address1: string;
    mailing_address2?: string;
    city: string;
    zip: string | number;
  }[];
  companyContact: {
    key?: string;
    uuid?: string;
    name: string;
    title: string;
    email: string;
    phone_number?: string;
  }[];
}

export const mCompleteVendorProfileInitVals: mCompleteVendorProfileFormType = {
  company_logo: '',
  company: {
    company_name: '',
    company_url: '',
    company_project_type_id: '',
    company_bio: '',
    reel_link: '',
    is_reel_upload: false,
    company_type: '',
  },
  companyLoc: [],
  companyContact: [],
};

export const mCompleteVendorProfileValidations = (pTypes: IProjectTypeType[]) =>
  Yup.object().shape({
    company_logo: Yup.string(),
    company: Yup.object().shape({
      company_name: Yup.string().required('Company name is required!'),
      company_type: Yup.string()
        .oneOf([
          IVendorCompanyTypeEnum.COMPANY,
          IVendorCompanyTypeEnum.FREELANCER,
          IVendorCompanyTypeEnum.INDEPENDENT_REP,
        ])
        .required('Company type is required!'),
      company_url: Yup.string()
        .required('Url is required!')
        .matches(
          /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?\/?$/gm,
          'Invalid company url!',
        ),
      company_project_type_id: Yup.string().when('company_type', {
        is: IVendorCompanyTypeEnum.COMPANY,
        then: Yup.string().required('Company type is required!'),
      }),
      company_talent_type_id: Yup.string().when(
        ['company_project_type_id', 'company_type'],
        {
          is: (
            company_project_type_id: string,
            company_type: IVendorCompanyTypeEnum,
          ) => {
            const usedPtype = pTypes.find(
              (p) => company_project_type_id === p.uuid,
            );

            if (
              company_type === IVendorCompanyTypeEnum.COMPANY &&
              ((usedPtype?.is_more_data && usedPtype.freelance_label === '') ||
                (usedPtype?.is_more_data && usedPtype.freelance_label !== ''))
            )
              return true;
            return false;
          },
          then: (sch) => sch.required('Primary id is required!'),
        },
      ),
      company_creative_style_ids: Yup.array()
        .of(Yup.string())
        .when(['company_project_type_id', 'company_type'], {
          is: (
            company_project_type_id: string,
            company_type: IVendorCompanyTypeEnum,
          ) => {
            const usedPtype = pTypes.find(
              (p) => company_project_type_id === p.uuid,
            );

            if (
              company_type === IVendorCompanyTypeEnum.COMPANY &&
              ((usedPtype?.is_more_data && usedPtype.freelance_label === '') ||
                (usedPtype?.is_more_data && usedPtype.freelance_label !== ''))
            )
              return true;
            return false;
          },
          then: (sch) => sch.required(projectErrors.CREATIVE_STYLES_REQUIRED),
        }),
      company_budget_range_id: Yup.string().when(
        ['company_project_type_id', 'company_type'],
        {
          is: (
            company_project_type_id: string,
            company_type: IVendorCompanyTypeEnum,
          ) => {
            const usedPtype = pTypes.find(
              (p) => company_project_type_id === p.uuid,
            );

            if (
              company_type === IVendorCompanyTypeEnum.COMPANY &&
              ((usedPtype?.is_more_data && usedPtype.freelance_label === '') ||
                (usedPtype?.is_more_data && usedPtype.freelance_label !== ''))
            )
              return true;
            return false;
          },
          then: (sch) => sch.required('Budget range is required!'),
        },
      ),
      company_talent_group_ids: Yup.array()
        .of(Yup.string())
        .when(['company_project_type_id', 'company_type'], {
          is: (
            company_project_type_id: string,
            company_type: IVendorCompanyTypeEnum,
          ) => {
            const usedPtype = pTypes.find(
              (p) => company_project_type_id === p.uuid,
            );

            if (
              company_type === IVendorCompanyTypeEnum.COMPANY &&
              ((usedPtype?.is_more_data && usedPtype.freelance_label === '') ||
                (usedPtype?.is_more_data && usedPtype.freelance_label !== ''))
            )
              return true;
            return false;
          },
          then: (sch) => sch.required(projectErrors.TALENT_GROUP_REQUIRED),
        }),
      company_language_ids: Yup.array()
        .of(Yup.string())
        .when(['company_project_type_id', 'company_type'], {
          is: (
            company_project_type_id: string,
            company_type: IVendorCompanyTypeEnum,
          ) => {
            const usedPtype = pTypes.find(
              (p) => company_project_type_id === p.uuid,
            );

            if (
              company_type === IVendorCompanyTypeEnum.COMPANY &&
              ((usedPtype?.is_more_data && usedPtype.freelance_label === '') ||
                (usedPtype?.is_more_data && usedPtype.freelance_label !== ''))
            )
              return true;
            return false;
          },
          then: (sch) => sch.required(projectErrors.LANGUAGE_REQUIRED),
        }),
      company_bio: Yup.string(),
      reel_link: Yup.string(),
      is_reel_upload: Yup.boolean(),
    }),
    companyLoc: Yup.array()
      .of(
        Yup.object().shape({
          uuid: Yup.string(),
          state_id: Yup.string().required('State is required!'),
          region: Yup.string().required('Region is required!'),
          mailing_address1: Yup.string().required(
            'Mailing address is required!',
          ),
          mailing_address2: Yup.string(),
          city: Yup.string().required('City is required!'),
          zip: zipValidationRequired,
        }),
      )
      .min(1, 'Atleast one location is required!'),
    companyContact: Yup.array().of(
      Yup.object().shape({
        uuid: Yup.string(),
        name: Yup.string().required('Contact name is required!'),
        title: Yup.string().required('Contact title is required!'),
        email: Yup.string()
          .trim()
          .lowercase()
          .email('Invalid email!')
          .required('Contact email is required!'),
        phone_number: Yup.string(),
      }),
    ),
  });

export const mCompleteVendorProfileFirstStepValidations = Yup.object().shape({
  company_logo: Yup.string(),
  company: Yup.object().shape({
    company_name: Yup.string().required('Company name is required!'),
    company_type: Yup.string()
      .oneOf([
        IVendorCompanyTypeEnum.COMPANY,
        IVendorCompanyTypeEnum.FREELANCER,
        IVendorCompanyTypeEnum.INDEPENDENT_REP,
      ])
      .required('Company type is required!'),
    company_url: Yup.string()
      .required('Url is required!')
      .matches(
        /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?\/?$/gm,
        'Invalid company url!',
      ),
    company_project_type_id: Yup.string().when('company_type', {
      is: IVendorCompanyTypeEnum.COMPANY,
      then: Yup.string().required('Company type is required!'),
    }),
    company_bio: Yup.string(),
    reel_link: Yup.string(),
    is_reel_upload: Yup.boolean(),
  }),
  companyLoc: Yup.array()
    .of(
      Yup.object().shape({
        uuid: Yup.string(),
        state_id: Yup.string().required('State is required!'),
        region: Yup.string().required('Region is required!'),
        mailing_address1: Yup.string().required('Mailing address is required!'),
        mailing_address2: Yup.string(),
        city: Yup.string().required('City is required!'),
        zip: zipValidationRequired,
      }),
    )
    .min(1, 'At least one location is required!'),
  companyContact: Yup.array().of(
    Yup.object().shape({
      uuid: Yup.string(),
      name: Yup.string().required('Contact name is required!'),
      title: Yup.string().required('Contact title is required!'),
      email: Yup.string()
        .trim()
        .lowercase()
        .email('Invalid email!')
        .required('Contact email is required!'),
      phone_number: Yup.string(),
    }),
  ),
});

export const mCompleteVendorProfileSecondStepValidations = (
  pTypes: IProjectTypeType[],
) =>
  Yup.object().shape({
    company: Yup.object().shape({
      company_talent_type_id: Yup.string().when(
        ['company_project_type_id', 'company_type'],
        {
          is: (
            company_project_type_id: string,
            company_type: IVendorCompanyTypeEnum,
          ) => {
            const usedPtype = pTypes.find(
              (p) => company_project_type_id === p.uuid,
            );

            if (
              company_type === IVendorCompanyTypeEnum.COMPANY &&
              ((usedPtype?.is_more_data && usedPtype.freelance_label === '') ||
                (usedPtype?.is_more_data && usedPtype.freelance_label !== ''))
            )
              return true;
            return false;
          },
          then: (sch) => sch.required('Primary id is required!'),
        },
      ),
      company_creative_style_ids: Yup.array()
        .of(Yup.string())
        .when(['company_project_type_id', 'company_type'], {
          is: (
            company_project_type_id: string,
            company_type: IVendorCompanyTypeEnum,
          ) => {
            const usedPtype = pTypes.find(
              (p) => company_project_type_id === p.uuid,
            );

            if (
              company_type === IVendorCompanyTypeEnum.COMPANY &&
              ((usedPtype?.is_more_data && usedPtype.freelance_label === '') ||
                (usedPtype?.is_more_data && usedPtype.freelance_label !== ''))
            )
              return true;
            return false;
          },
          then: (sch) => sch.required(projectErrors.CREATIVE_STYLES_REQUIRED),
        }),
      company_budget_range_id: Yup.string().when(
        ['company_project_type_id', 'company_type'],
        {
          is: (
            company_project_type_id: string,
            company_type: IVendorCompanyTypeEnum,
          ) => {
            const usedPtype = pTypes.find(
              (p) => company_project_type_id === p.uuid,
            );

            if (
              company_type === IVendorCompanyTypeEnum.COMPANY &&
              ((usedPtype?.is_more_data && usedPtype.freelance_label === '') ||
                (usedPtype?.is_more_data && usedPtype.freelance_label !== ''))
            )
              return true;
            return false;
          },
          then: (sch) => sch.required('Budget range is required!'),
        },
      ),
      company_talent_group_ids: Yup.array()
        .of(Yup.string())
        .when(['company_project_type_id', 'company_type'], {
          is: (
            company_project_type_id: string,
            company_type: IVendorCompanyTypeEnum,
          ) => {
            const usedPtype = pTypes.find(
              (p) => company_project_type_id === p.uuid,
            );

            if (
              company_type === IVendorCompanyTypeEnum.COMPANY &&
              ((usedPtype?.is_more_data && usedPtype.freelance_label === '') ||
                (usedPtype?.is_more_data && usedPtype.freelance_label !== ''))
            )
              return true;
            return false;
          },
          then: (sch) => sch.required(projectErrors.TALENT_GROUP_REQUIRED),
        }),
      company_language_ids: Yup.array()
        .of(Yup.string())
        .when(['company_project_type_id', 'company_type'], {
          is: (
            company_project_type_id: string,
            company_type: IVendorCompanyTypeEnum,
          ) => {
            const usedPtype = pTypes.find(
              (p) => company_project_type_id === p.uuid,
            );

            if (
              company_type === IVendorCompanyTypeEnum.COMPANY &&
              ((usedPtype?.is_more_data && usedPtype.freelance_label === '') ||
                (usedPtype?.is_more_data && usedPtype.freelance_label !== ''))
            )
              return true;
            return false;
          },
          then: (sch) => sch.required(projectErrors.LANGUAGE_REQUIRED),
        }),
    }),
  });
