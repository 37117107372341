import { gql } from '@apollo/client';
import * as Yup from 'yup';

export const mRegisterEnterpriseClient = gql`
  mutation registerEnterpriseClient(
    $registerEnterpriseClientInput: RegisterEnterpriseClientInput!
  ) {
    registerEnterpriseClient(
      registerEnterpriseClientInput: $registerEnterpriseClientInput
    )
  }
`;

export interface mRegisterEnterpriseClientRes {
  registerEnterpriseClient?: boolean;
}

export interface mRegisterEnterpriseClientFormType {
  registerEnterpriseClientInput: {
    name: string;
    email: string;
    company_name: string;
    phone_number: string;
    user_note: string;
  };
}

export const mRegisterEnterpriseClientInitVals: mRegisterEnterpriseClientFormType =
  {
    registerEnterpriseClientInput: {
      name: '',
      company_name: '',
      email: '',
      phone_number: '',
      user_note: '',
    },
  };

export const mRegisterEnterpriseClientValidations = Yup.object().shape({
  registerEnterpriseClientInput: Yup.object().shape({
    name: Yup.string().required('Name is required!'),
    company_name: Yup.string().required('Company is required!'),
    email: Yup.string()
      .trim()
      .lowercase()
      .email('Invalid email!')
      .required('Email is required!'),
    phone_number: Yup.string().matches(
      /^\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/,
      'Invalid mobile number!',
    ),
    user_note: Yup.string().required('Note is required!'),
  }),
});
