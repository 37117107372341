import { gql } from '@apollo/client';

export const mLinkAccount = gql`
  mutation linkAccount($token: String!) {
    linkAccount(token: $token)
  }
`;

export interface mLinkAccountRes {
  linkAccount?: boolean;
}

export interface mLinkAccountVar {
  token: string;
}
