import { gql } from '@apollo/client';
import { ISettingDataType } from 'types';

export const qCreativeStyleById = gql`
  query creativeStyleById($id: ID!) {
    creativeStyleById(id: $id) {
      uuid
      name
      short_description
      long_description
      sort_order
    }
  }
`;

export interface qCreativeStyleByIdRes {
  creativeStyleById?: ISettingDataType;
}
