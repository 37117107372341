import { gql } from '@apollo/client';

export const mUpdateProjectTypeHasClientBudgetRanges = gql`
  mutation updateProjectTypeHasClientBudgetRanges(
    $project_type_id: ID!
    $client_budget_range_ids: [ID!]!
  ) {
    updateProjectTypeHasClientBudgetRanges(
      project_type_id: $project_type_id
      client_budget_range_ids: $client_budget_range_ids
    )
  }
`;

export interface mUpdateProjectTypeHasClientBudgetRangesVars {
  project_type_id: string;
  client_budget_range_ids: string[];
}

export interface mUpdateProjectTypeHasClientBudgetRangesRes {
  updateProjectTypeHasClientBudgetRanges?: boolean;
}
