import { gql } from '@apollo/client';

export const mCreateSubmissionFreelancer = gql`
  mutation createSubmissionFreelancer(
    $id: ID!
    $reel_link: String!
    $note: String
    $reel_password: String
  ) {
    createSubmissionFreelancer(
      id: $id
      reel_link: $reel_link
      note: $note
      reel_password: $reel_password
    )
  }
`;

export interface mCreateSubmissionFreelancerRes {
  createSubmissionFreelancer?: boolean;
}

export interface mCreateSubmissionFreelancerVars {
  id: string;
  reel_link: string;
  note?: string;
  reel_password?: string;
}
