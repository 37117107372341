import { gql } from '@apollo/client';

export const mUpdateProjectType = gql`
  mutation updateProjectType(
    $id: ID!
    $name: String!
    $short_description: String
    $long_description: String
    $sort_order: Int!
  ) {
    updateProjectType(
      id: $id
      name: $name
      short_description: $short_description
      long_description: $long_description
      sort_order: $sort_order
    )
  }
`;

export interface mUpdateProjectTypeVars {
  id: string;
  name: string;
  short_description: string;
  long_description: string;
  sort_order: number;
}

export interface mUpdateProjectTypeRes {
  updateProjectType?: boolean;
}
