import { gql } from '@apollo/client';

export const mUpdateFavoriteVendorList = gql`
  mutation updateFavoriteVendorList($list_id: ID!, $name: String!) {
    updateFavoriteVendorList(list_id: $list_id, name: $name)
  }
`;

export interface mUpdateFavoriteVendorListRes {
  updateFavoriteVendorList?: boolean;
}

export interface mUpdateFavoriteVendorListVar {
  list_id: string;
  name: string;
}
