import { gql } from '@apollo/client';
import { ISettingDataType } from 'types';

export const qTalentGroupById = gql`
  query talentGroupById($id: ID!) {
    talentGroupById(id: $id) {
      uuid
      name
      short_description
      long_description
      sort_order
    }
  }
`;

export interface qTalentGroupByIdRes {
  talentGroupById?: ISettingDataType;
}
