export const addCompanyNameToFileName = (
  fileName?: string,
  companyName?: string,
): string => {
  if (!companyName || !fileName) return 'NDA';
  if (!companyName) return fileName;

  const name = fileName.replace('.pdf', '');

  return `${name} - ${companyName}.pdf`;
};
